<div class="custom-dropdown" (clickOutside)="clickedOutside()">
  <div class="custom-dropdown__input" (click)="clickInput()" [ngClass]="{'has-length': selectedOptionsCount > 0}">
    <span> {{ selectedOptions }} </span>
  </div>
  <svg-icon *ngIf="selectedOptionsCount < 2" src="assets/images/icons/select-arrows.svg"></svg-icon>

  <span *ngIf="selectedOptionsCount > 1" class="custom-dropdown__count">{{ selectedOptionsCount }}</span>
  <div *ngIf="active" class="custom-dropdown__overflow">
    <div class="custom-dropdown__search">
      <svg-icon src="assets/images/icons/search.svg"></svg-icon>
      <input type="text" [(ngModel)]="searchValue" placeholder="Zoek" />
    </div>
    <ul>
      <li
        *ngFor="let option of filteredOptions"
        [ngClass]="{'selected': option.select}"
        (click)="optionClick(option.id)">
        {{ option.name }}
      </li>
    </ul>
  </div>
</div>
