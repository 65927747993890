/**
 * Created by gert on 22/03/2018.
 */
import { PipeTransform, Pipe } from "@angular/core";
import { Instance } from "../../components/models/instance";

@Pipe({ name: "custom_description", pure: true })
export class CustomDescriptionPipe implements PipeTransform {
  transform(value: any): string {
    const obj = Object.assign(new Instance(), value);
    return obj.presentableName;
  }
}

@Pipe({ name: "custom_description_ingestor", pure: true })
export class CustomDescriptionIngestorPipe implements PipeTransform {
  transform(value: any): string {
    return value?.customerDescription ?? "-";
  }
}
