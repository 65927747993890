<div class="dialog dialog--small">
    <svg-icon src="assets/images/icons/x.svg" class="dialog__close dialog__close--absolute-right"
        (click)="closeDialog()"></svg-icon>
    <div class="activity">
        <div class="activity__flex">
            <div class="activity__icon">
                <svg-icon src="assets/images/icons/maintenance-planned.svg"></svg-icon>
            </div>
            <div class="activity__caption">
                <h2 class="activity__title">{{ data.heading }}</h2>
                <div class="activity__subtitle"> {{ data.start_timestamp | date:'d MMM' }}, {{ data.start_timestamp |
                    date:'HH:mm' }} {{ 'Global.Until' | translate }} {{
                    data.end_timestamp | date:'HH:mm'}}</div>
            </div>
        </div>
        <div class="activity__description">
            <p>{{ data.contents }}</p>
        </div>
        <div class="activity__services">
            <div class="activity__services-header">
                <div class="activity__services-col">
                    {{ 'Services.Affected' | translate }}
                </div>
                <div class="activity__services-col">
                    {{ 'Global.Impact' | translate }}
                </div>
            </div>
            <div>
                <div *ngFor="let affectedSubscription of data.affected_subscriptions" class="activity__services-row"
                    (click)="segueToService(affectedSubscription)">
                    <div class="activity__services-col">
                        <div>{{ affectedSubscription.customer_description || affectedSubscription.description}}</div>
                        <span>{{affectedSubscription.name}}</span>
                    </div>
                    <div class="activity__services-col">
                        <div>{{affectedSubscription.impact_type | translate }}</div>
                    </div>
                    <svg-icon src="assets/images/icons/arrow-small-right.svg"></svg-icon>
                </div>
            </div>
        </div>
    </div>
</div>
