import { gql } from "apollo-angular";
import { DependsOnPrefixesFragment } from "../fragments/fragments";

export const PREFIX_SUBSCRIPTIONS_QUERY = gql`
  ${DependsOnPrefixesFragment}
  query PrefixSubscriptionsQuery($id: ID!) {
    subscription(id: $id) {
      ...DependsOnPrefixes
    }
  }
`;
