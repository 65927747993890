import { gql } from "apollo-angular";

const L2VpnTopologyFragment = gql`
  fragment L2VpnTopology on SubscriptionInterface {
    ... on Sn8L2VpnSubscription {
      subscriptionId
      customerId
      vc {
        subscriptionInstanceId
        esis {
          subscriptionInstanceId
          title
          name
          saps {
            subscriptionInstanceId
            vlanrange
            ownerSubscriptionId
            port {
              subscriptionId
              description
              customerDescriptions {
                customerId
                description
              }
            }
          }
        }
      }
    }
  }
`;

export { L2VpnTopologyFragment };
