<div class="dialog dialog--small">
  <div>
    <div class="dialog__header">
      <h3 class="dialog__title" *ngIf="!editModus">
        {{'Dialog.IPP.LinkedTo' | translate}}&nbsp;<span class="dialog__title-light">{{ data['subscriptions'].description }}</span>
      </h3>
      <h3 class="dialog__title" *ngIf="editModus">
        {{ "Selfservice.LinkToService" | translate }}
      </h3>
      <svg-icon
        src="assets/images/icons/x.svg"
        class="dialog__close"
        (click)="closeDialog()"
      ></svg-icon>
    </div>
    <div class="affected-service-item__wrapper">
      <div
        *ngFor="let subscription of data['linkedSubscriptions']; let i = index"
      >
        <div
          class="affected-service-item"
          (click)="segueToService(subscription.subscriptionId)"
          *ngIf="isAvailable(subscription)"
        >
          <div
            class="affected-service-item__icon-box affected-service-item__icon-box--ip"
          >
            <svg-icon
              src="assets/images/icons/ip.svg"
              class="affected-service-item__icon"
            ></svg-icon>
          </div>
          <div class="affected-service-item__caption">
            <div class="affected-service-item__title">
              <div>{{ presentableName(subscription) }}</div>
            </div>
            <div class="affected-service-item__subtitle">
              {{ subscription.subscriptionId | shorten_guid }}
            </div>
          </div>
          <svg-icon
            class="affected-service-item__arrow"
            src="assets/images/icons/arrow-small-right.svg"
          >
          </svg-icon>
        </div>

        <!-- disabled subscription without access demo here -->
        <div
          class="affected-service-item affected-service-item--disabled"
          *ngIf="!isAvailable(subscription)"
        >
          <div
            class="affected-service-item__icon-box affected-service-item__icon-box--ip"
          >
            <svg-icon
              src="assets/images/icons/ip.svg"
              class="affected-service-item__icon"
            ></svg-icon>
          </div>
          <div class="affected-service-item__caption">
            <div class="affected-service-item__title">
              <div>{{ presentableName(subscription) }}</div>
            </div>
            <div class="affected-service-item__subtitle">
              {{'Global.NoAccessContact' | translate}} {{
              subscription.customerName }}
            </div>
          </div>
          <svg-icon
            class="affected-service-item__lock"
            src="assets/images/icons/lock.svg"
          >
          </svg-icon>
        </div>
      </div>
    </div>
  </div>
</div>
