/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { createTemplate } from "../fn/templates/create-template";
import { CreateTemplate$Params } from "../fn/templates/create-template";
import { deleteTemplate } from "../fn/templates/delete-template";
import { DeleteTemplate$Params } from "../fn/templates/delete-template";
import { readTemplate } from "../fn/templates/read-template";
import { ReadTemplate$Params } from "../fn/templates/read-template";
import { readTemplateRecords } from "../fn/templates/read-template-records";
import { ReadTemplateRecords$Params } from "../fn/templates/read-template-records";
import { readTemplates } from "../fn/templates/read-templates";
import { ReadTemplates$Params } from "../fn/templates/read-templates";
import { RecordRead } from "../models/record-read";
import { RecordsUpdateStatus } from "../models/records-update-status";
import { Status } from "../models/status";
import { TemplateRead } from "../models/template-read";
import { TemplateReadWithRecords } from "../models/template-read-with-records";
import { updateTemplate } from "../fn/templates/update-template";
import { UpdateTemplate$Params } from "../fn/templates/update-template";
import { updateTemplateRecords } from "../fn/templates/update-template-records";
import { UpdateTemplateRecords$Params } from "../fn/templates/update-template-records";

@Injectable({ providedIn: "root" })
export class TemplatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readTemplates()` */
  static readonly ReadTemplatesPath = "/surfdomeinen/organisations/{organisation_id}/templates/";

  /**
   * Read Templates.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTemplates$Response(
    params: ReadTemplates$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<TemplateRead>>> {
    return readTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Templates.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTemplates(params: ReadTemplates$Params, context?: HttpContext): Observable<Array<TemplateRead>> {
    return this.readTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TemplateRead>>): Array<TemplateRead> => r.body),
    );
  }

  /** Path part for operation `createTemplate()` */
  static readonly CreateTemplatePath = "/surfdomeinen/organisations/{organisation_id}/templates/";

  /**
   * Create Template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTemplate$Response(
    params: CreateTemplate$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TemplateRead | Status>> {
    return createTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTemplate(params: CreateTemplate$Params, context?: HttpContext): Observable<TemplateRead | Status> {
    return this.createTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateRead | Status>): TemplateRead | Status => r.body),
    );
  }

  /** Path part for operation `readTemplate()` */
  static readonly ReadTemplatePath = "/surfdomeinen/organisations/{organisation_id}/templates/{template_id}";

  /**
   * Read Template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTemplate$Response(
    params: ReadTemplate$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TemplateReadWithRecords>> {
    return readTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTemplate(params: ReadTemplate$Params, context?: HttpContext): Observable<TemplateReadWithRecords> {
    return this.readTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateReadWithRecords>): TemplateReadWithRecords => r.body),
    );
  }

  /** Path part for operation `deleteTemplate()` */
  static readonly DeleteTemplatePath = "/surfdomeinen/organisations/{organisation_id}/templates/{template_id}";

  /**
   * Delete Template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTemplate()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTemplate$Response(
    params: DeleteTemplate$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Status>> {
    return deleteTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTemplate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTemplate(params: DeleteTemplate$Params, context?: HttpContext): Observable<Status> {
    return this.deleteTemplate$Response(params, context).pipe(map((r: StrictHttpResponse<Status>): Status => r.body));
  }

  /** Path part for operation `updateTemplate()` */
  static readonly UpdateTemplatePath = "/surfdomeinen/organisations/{organisation_id}/templates/{template_id}";

  /**
   * Update Template.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTemplate$Response(
    params: UpdateTemplate$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<TemplateRead | Status>> {
    return updateTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Template.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTemplate(params: UpdateTemplate$Params, context?: HttpContext): Observable<TemplateRead | Status> {
    return this.updateTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TemplateRead | Status>): TemplateRead | Status => r.body),
    );
  }

  /** Path part for operation `readTemplateRecords()` */
  static readonly ReadTemplateRecordsPath =
    "/surfdomeinen/organisations/{organisation_id}/templates/{template_id}/records/";

  /**
   * Read Template Records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readTemplateRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTemplateRecords$Response(
    params: ReadTemplateRecords$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RecordRead>>> {
    return readTemplateRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Template Records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readTemplateRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readTemplateRecords(params: ReadTemplateRecords$Params, context?: HttpContext): Observable<Array<RecordRead>> {
    return this.readTemplateRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RecordRead>>): Array<RecordRead> => r.body),
    );
  }

  /** Path part for operation `updateTemplateRecords()` */
  static readonly UpdateTemplateRecordsPath =
    "/surfdomeinen/organisations/{organisation_id}/templates/{template_id}/records/";

  /**
   * Update Template Records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateTemplateRecords()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTemplateRecords$Response(
    params: UpdateTemplateRecords$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RecordsUpdateStatus>> {
    return updateTemplateRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Template Records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateTemplateRecords$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateTemplateRecords(params: UpdateTemplateRecords$Params, context?: HttpContext): Observable<RecordsUpdateStatus> {
    return this.updateTemplateRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecordsUpdateStatus>): RecordsUpdateStatus => r.body),
    );
  }
}
