/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

export interface GetRtypes$Params {
  token?: string | null;
}

export function getRtypes(
  http: HttpClient,
  rootUrl: string,
  params?: GetRtypes$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<string>>> {
  const rb = new RequestBuilder(rootUrl, getRtypes.PATH, "get");
  if (params) {
    rb.query("token", params.token, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<string>>;
    }),
  );
}

getRtypes.PATH = "/surfdomeinen/info/rtypes";
