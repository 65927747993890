import { gql } from "apollo-angular";
import { FirewallFragment } from "../fragments/firewall-subscription";
import {
  ptpIpamInfoFragment,
  ServiceDetailsFragment,
  Sn8SapBlockInfoFragment,
  SubscriptionDetailsFragment,
} from "../fragments/fragments";
import { IpPrefixSubscriptionOnlyFragment } from "../fragments/ip-prefix-subscription";
import { L3VpnSubscriptionConnectionsFragment } from "../fragments/l3vpn-subscription";
import { L2VpnSubscriptionFragment } from "../fragments/l2vpn-subscription";

export const TABBED_DETAILCONTAINER_QUERY = gql`
  ${ptpIpamInfoFragment}
  ${Sn8SapBlockInfoFragment}
  ${FirewallFragment}
  ${ServiceDetailsFragment}
  ${SubscriptionDetailsFragment}
  ${IpPrefixSubscriptionOnlyFragment}
  ${L3VpnSubscriptionConnectionsFragment}
  ${L2VpnSubscriptionFragment}
  query ConnectionQuery($id: ID!, $portServices: Boolean = true) {
    subscription(id: $id) {
      ...ServiceDetails
      ...SubscriptionDetails
      ...FirewallDetails
      ...L3VpnSubscriptionConnectionDetails
      ...L2VpnSubscriptionDetails
      ...IpPrefixSubscriptionOnlyDetails
      ... on Sn8IpBgpSubscription {
        vc {
          name
          serviceSpeed
          subscriptionInstanceId
          saps {
            subscriptionInstanceId
            name
            bfd
            bfdMinimumInterval
            bfdMultiplier
            bgpExportPolicy
            bgpHashAlgorithm
            bgpPassword
            bgpSessionPriority
            customerIpv4Mtu
            customerIpv6Mtu
            prefixes {
              ownerSubscriptionId
            }
            ptpIpv4Ipam {
              ...ptpIpamInfo
            }

            ptpIpv6Ipam {
              ...ptpIpamInfo
            }

            sap {
              ...Sn8SapBlockInfo
            }
          }
          settings {
            pinPrefix {
              ownerSubscriptionId
            }
            subscriptionInstanceId
            asn
            label
            multicast
            name
          }
        }
      }
      ... on Sn8IpStaticSubscription {
        vc {
          subscriptionInstanceId
          name

          serviceSpeed

          settings {
            surfcertFilterEnabled
            surfcertFilter
            subscriptionInstanceId
            name
            multicast
            pinPrefix {
              ownerSubscriptionId
            }
          }

          sap {
            subscriptionInstanceId
            name

            customerIpv4Mtu
            customerIpv6Mtu
            prefixes {
              ownerSubscriptionId
            }

            ptpIpv4Ipam {
              ...ptpIpamInfo
            }

            ptpIpv6Ipam {
              ...ptpIpamInfo
            }

            sap {
              ...Sn8SapBlockInfo
            }
          }
        }
      }
    }
  }
`;
