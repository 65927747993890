<div class="card activity-card">
  <div *ngIf="messages.length === 0">
    <div class="activity-card__header">
      <svg-icon class="" src="assets/images/icons/activity.svg"></svg-icon>
      <h2>{{'Overview.Activities.Planned' | translate }}</h2>
    </div>
    <div class="activity-card__empty-state">
      <svg-icon class="activity-card__empty-state--icon" src="assets/images/icons/icon-rocket.svg"></svg-icon>
      <span class="activity-card__empty-state--text">{{'Subscription.Message.NoError' | translate}}</span>
    </div>
  </div>
  <!-- PLANNED ACTIVITY messages -->
  <div *ngIf="messages.length > 0">
    <div class="activity-card__header">
      <svg-icon class="" src="assets/images/icons/activity.svg"></svg-icon>
      <h2>{{'Overview.Activities.Planned' | translate }}</h2>
    </div>
    <ul>
      <li *ngFor="let message of messages | slice:0:3; let i=index" class="activity-card__item"
        (click)="openDialog(message)">
        <span class="activity-card__message">
          {{ message.heading }}
        </span>
        <span class="activity-card__time">
          {{ message.startTimestamp | date:'d MMM' }}, {{ message.startTimestamp |
          date:'HH:mm' }} {{ 'Global.Until' | translate }} {{ 
          message.endTimestamp | date:'HH:mm'}}
        </span>
      </li>
    </ul>
    <div class="activity-card__footer">
      <a [routerLink]="['/storing-en-onderhoud']">
        <span>{{ 'Overview.RecentMessages.Follow' | translate }}</span>
      </a>
    </div>
  </div>
</div>
