import { Input, Component, OnChanges, SimpleChanges, EventEmitter } from "@angular/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { AuthService } from "../../../services/AuthService";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogSelfserviceComponent } from "../../dialogs/dialog-selfservice/dialog-selfservice";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { Instance } from "../../models/instance";

@Component({
  selector: "connection",
  templateUrl: "connection.html",
})
export class ConnectionComponent implements OnChanges {
  @Input() port: { port?: { subscriptionId: string; portMode: string; address?: { city: string } }; health?: any }[] =
    [];
  @Input() connectionNumber: any = {};
  @Input() service: Instance;
  @Input() circuit;
  @Input() bus: EventEmitter<string>;
  @Input() demoShowVisual = false;

  public overflowMenuActive = false;

  constructor(
    private api: ApiHelper,
    public auth: AuthService,
    public dialog: MatDialog,
  ) {}

  // setting this here to keep the template readable.
  get isSelfServiceAvailable(): boolean {
    return !this.somePortsAreUntagged && !this.service?.firewallEnabled;
  }

  get somePortsAreUntagged() {
    const mapPortModes = this.port.map((item) => ({
      portMode: item.port.portMode,
    }));

    if (mapPortModes?.some((item) => item.portMode === "Untagged")) {
      return true;
    }
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["port"]) {
      this.loadHealthForSubscriptions();
    }

    ["vlan-lightpath"].forEach((type) => {
      if (this.auth.hasPendingStrongAction(type)) {
        // We've set the connectionnumber in the saved state.
        // Check if it is there and only open the dialog for
        // the correct one.
        // On failure, open the first connection we see, to make
        // sure the user sees expected behaviour.
        try {
          const ssState = this.auth.getSelfserviceState();
          if (ssState.connectionNumber === this.connectionNumber) {
            this.startDialog(type);
          }
        } catch (e) {
          console.error("Expected to find selfservice state in auth service, but got", e);
          this.startDialog(type);
        }
      }
    });
  }

  startDialog(type) {
    this.auth.clearPendingStrongAction(type);
    this.openSelfserviceDialog(type);
  }

  async loadHealthForSubscriptions() {
    this.api.health(this.port[0].port.subscriptionId).then((data) => {
      // TODO Revise code to not randomly add health data to an object it isn't part of
      this.port[0].health = data;
    });
  }

  openSelfserviceDialog(type) {
    const roleCheckResult = this.auth.checkRoleAccess(this.service.product?.productType, "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.isAuthenticatedForSelfService()) {
      this.dialog.open(DialogAuthComponent, {
        data: {
          selfserviceState: { connectionNumber: this.connectionNumber },
          initialAction: type,
        },
      });
    } else {
      const dialogRef = this.dialog.open(DialogSelfserviceComponent, {
        data: {
          type,
          circuit: this.port,
          subscription: this.service,
          titleSuffix: this.connectionNumber,
        },
      });

      dialogRef.componentInstance.close.subscribe((event: string) => {
        this.bus.emit(event);
      });
    }
  }
}
