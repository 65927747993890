/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { changeZone } from "../fn/zones/change-zone";
import { ChangeZone$Params } from "../fn/zones/change-zone";
import { createZone } from "../fn/zones/create-zone";
import { CreateZone$Params } from "../fn/zones/create-zone";
import { deleteZone } from "../fn/zones/delete-zone";
import { DeleteZone$Params } from "../fn/zones/delete-zone";
import { exportRecords } from "../fn/zones/export-records";
import { ExportRecords$Params } from "../fn/zones/export-records";
import { readRecords } from "../fn/zones/read-records";
import { ReadRecords$Params } from "../fn/zones/read-records";
import { readZone } from "../fn/zones/read-zone";
import { ReadZone$Params } from "../fn/zones/read-zone";
import { readZones } from "../fn/zones/read-zones";
import { ReadZones$Params } from "../fn/zones/read-zones";
import { RecordRead } from "../models/record-read";
import { RecordsUpdateStatus } from "../models/records-update-status";
import { Status } from "../models/status";
import { updateRecords } from "../fn/zones/update-records";
import { UpdateRecords$Params } from "../fn/zones/update-records";
import { updateZone } from "../fn/zones/update-zone";
import { UpdateZone$Params } from "../fn/zones/update-zone";
import { ZonePrimary } from "../models/zone-primary";
import { ZoneRead } from "../models/zone-read";
import { ZoneSecondary } from "../models/zone-secondary";

@Injectable({ providedIn: "root" })
export class ZonesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `readZones()` */
  static readonly ReadZonesPath = "/surfdomeinen/organisations/{organisation_id}/zones/";

  /**
   * Read Zones.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readZones()` instead.
   *
   * This method doesn't expect any request body.
   */
  readZones$Response(params: ReadZones$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ZoneRead>>> {
    return readZones(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Zones.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readZones$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readZones(params: ReadZones$Params, context?: HttpContext): Observable<Array<ZoneRead>> {
    return this.readZones$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ZoneRead>>): Array<ZoneRead> => r.body),
    );
  }

  /** Path part for operation `createZone()` */
  static readonly CreateZonePath = "/surfdomeinen/organisations/{organisation_id}/zones/";

  /**
   * Create Zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createZone()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createZone$Response(
    params: CreateZone$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<(ZonePrimary | ZoneSecondary) | Status>> {
    return createZone(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Zone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createZone$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createZone(params: CreateZone$Params, context?: HttpContext): Observable<(ZonePrimary | ZoneSecondary) | Status> {
    return this.createZone$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<(ZonePrimary | ZoneSecondary) | Status>): (ZonePrimary | ZoneSecondary) | Status =>
          r.body,
      ),
    );
  }

  /** Path part for operation `readZone()` */
  static readonly ReadZonePath = "/surfdomeinen/organisations/{organisation_id}/zones/{zone_id}";

  /**
   * Read Zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readZone()` instead.
   *
   * This method doesn't expect any request body.
   */
  readZone$Response(
    params: ReadZone$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<ZonePrimary | ZoneSecondary>> {
    return readZone(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Zone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readZone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readZone(params: ReadZone$Params, context?: HttpContext): Observable<ZonePrimary | ZoneSecondary> {
    return this.readZone$Response(params, context).pipe(
      map((r: StrictHttpResponse<ZonePrimary | ZoneSecondary>): ZonePrimary | ZoneSecondary => r.body),
    );
  }

  /** Path part for operation `changeZone()` */
  static readonly ChangeZonePath = "/surfdomeinen/organisations/{organisation_id}/zones/{zone_id}";

  /**
   * Change Zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeZone()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeZone$Response(
    params: ChangeZone$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<(ZonePrimary | ZoneSecondary) | Status>> {
    return changeZone(this.http, this.rootUrl, params, context);
  }

  /**
   * Change Zone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeZone$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeZone(params: ChangeZone$Params, context?: HttpContext): Observable<(ZonePrimary | ZoneSecondary) | Status> {
    return this.changeZone$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<(ZonePrimary | ZoneSecondary) | Status>): (ZonePrimary | ZoneSecondary) | Status =>
          r.body,
      ),
    );
  }

  /** Path part for operation `deleteZone()` */
  static readonly DeleteZonePath = "/surfdomeinen/organisations/{organisation_id}/zones/{zone_id}";

  /**
   * Delete Zone.
   *
   * Delete the specified zone. If it is a managed zone, the corresponding records are also deleted and a RecordsUpdateStatus is returned to indicate if there might be changes or conflicts for auto reverse zones.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteZone()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteZone$Response(
    params: DeleteZone$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Status | RecordsUpdateStatus>> {
    return deleteZone(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Zone.
   *
   * Delete the specified zone. If it is a managed zone, the corresponding records are also deleted and a RecordsUpdateStatus is returned to indicate if there might be changes or conflicts for auto reverse zones.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteZone$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteZone(params: DeleteZone$Params, context?: HttpContext): Observable<Status | RecordsUpdateStatus> {
    return this.deleteZone$Response(params, context).pipe(
      map((r: StrictHttpResponse<Status | RecordsUpdateStatus>): Status | RecordsUpdateStatus => r.body),
    );
  }

  /** Path part for operation `updateZone()` */
  static readonly UpdateZonePath = "/surfdomeinen/organisations/{organisation_id}/zones/{zone_id}";

  /**
   * Update Zone.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateZone()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateZone$Response(
    params: UpdateZone$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<(ZonePrimary | ZoneSecondary) | Status>> {
    return updateZone(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Zone.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateZone$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateZone(params: UpdateZone$Params, context?: HttpContext): Observable<(ZonePrimary | ZoneSecondary) | Status> {
    return this.updateZone$Response(params, context).pipe(
      map(
        (r: StrictHttpResponse<(ZonePrimary | ZoneSecondary) | Status>): (ZonePrimary | ZoneSecondary) | Status =>
          r.body,
      ),
    );
  }

  /** Path part for operation `readRecords()` */
  static readonly ReadRecordsPath = "/surfdomeinen/organisations/{organisation_id}/zones/{zone_id}/records/";

  /**
   * Read Records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  readRecords$Response(
    params: ReadRecords$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<RecordRead>>> {
    return readRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readRecords(params: ReadRecords$Params, context?: HttpContext): Observable<Array<RecordRead>> {
    return this.readRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RecordRead>>): Array<RecordRead> => r.body),
    );
  }

  /** Path part for operation `updateRecords()` */
  static readonly UpdateRecordsPath = "/surfdomeinen/organisations/{organisation_id}/zones/{zone_id}/records/";

  /**
   * Update Records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateRecords()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecords$Response(
    params: UpdateRecords$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<RecordsUpdateStatus>> {
    return updateRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateRecords$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateRecords(params: UpdateRecords$Params, context?: HttpContext): Observable<RecordsUpdateStatus> {
    return this.updateRecords$Response(params, context).pipe(
      map((r: StrictHttpResponse<RecordsUpdateStatus>): RecordsUpdateStatus => r.body),
    );
  }

  /** Path part for operation `exportRecords()` */
  static readonly ExportRecordsPath = "/surfdomeinen/organisations/{organisation_id}/zones/{zone_id}/records/export";

  /**
   * Export Records.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exportRecords()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportRecords$Response(params: ExportRecords$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return exportRecords(this.http, this.rootUrl, params, context);
  }

  /**
   * Export Records.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exportRecords$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exportRecords(params: ExportRecords$Params, context?: HttpContext): Observable<any> {
    return this.exportRecords$Response(params, context).pipe(map((r: StrictHttpResponse<any>): any => r.body));
  }
}
