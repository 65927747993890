import { gql } from "apollo-angular";
import { ProductFragment, ServiceDetailsFragment, SubscriptionDetailsFragment } from "../fragments/fragments";

export const PORT_VISUALISER_QUERY = gql`
  ${ServiceDetailsFragment}
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  query PortVisualiser($id: ID!, $portServices: Boolean = true) {
    subscription(id: $id) {
      subscriptionId
      subscriptionType: __typename
      description
      ... on Sn8ServicePortSubscription {
        portSpeed
      }
      ...Product
      ...ServiceDetails
      customer {
        name
      }
    }
  }
`;
