<header class="header" [ngClass]="{'sticky' : isSticky}">
  <section class="topbar">
    <div class="container">
      <div class="topbar__flex">
        <a [routerLink]="['/']" class="logo"></a>
        <div class="navigation__items">
          <a
            [routerLink]="['/']"
            class="navigation__link"
            routerLinkActive="navigation__link--active"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <span class="navigation__link-inner">{{ 'Navigation.Subscriptions' | translate }}</span>
          </a>
          <a
            [routerLink]="['/storing-en-onderhoud']"
            class="navigation__link"
            routerLinkActive="navigation__link--active"
          >
            <span class="navigation__link-inner">{{ 'Navigation.MalfunctionAndMaintenance' | translate }}</span>
          </a>
          <a [routerLink]="['/support']" class="navigation__link" routerLinkActive="navigation__link--active">
            <span class="navigation__link-inner">{{ 'Navigation.Support' | translate }}</span>
          </a>
        </div>
        <div class="topbar__items">
          <div class="topbar__item__wrapper">
            <span class="topbar__language" *ngFor="let lang of translate.getLangs()">
              <span (click)="switchLanguage(lang)" [class.topbar__language--active]="translate.currentLang === lang">
                {{ lang }}
              </span>
            </span>
          </div>
          <div class="topbar__item__wrapper" (clickOutside)="clickedOutside()">
            <div
              class="topbar__item topbar__item--light topbar__item--dropdown"
              [ngClass]="{'clicked': toggleProfile}"
              (click)="togglePro()"
            >
              <svg-icon class="topbar__icon" src="assets/images/icons/user.svg"></svg-icon>
            </div>
            <div *ngIf="toggleProfile" class="topbar__options">
              <div class="topbar__profile">
                <div class="topbar__avatar" style="background-image: url(/assets/images/avatar.jpg)"></div>
                <div class="topbar__name">{{ auth.state.currentUser.displayName }}</div>
                <div class="topbar__organisation">{{ auth.state.viewingCustomer.name }}</div>
              </div>
              <a [routerLink]="['/notification-settings']" class="topbar__button topbar__button&#45;&#45;switch">
                <span>{{ 'Navigation.NotificationSettings' | translate }}</span>
              </a>
              <span
                *ngIf="auth.state.organisations.length > 1"
                (click)="openChooserDialog();"
                class="topbar__button topbar__button--switch"
                style="cursor: pointer"
              >
                <svg-icon src="assets/images/icons/refresh.svg" class="topbar__button__icon"></svg-icon>
                <span>{{ 'Navigation.Choose.Organisation' | translate }}</span>
              </span>
              <span (click)="openRolesDialog();" class="topbar__button topbar__button--switch" style="cursor: pointer">
                <span>{{ 'Navigation.Roles' | translate }}</span>
              </span>
              <a (click)="logOut()" class="topbar__button">
                <svg-icon src="assets/images/icons/logout.svg" class="topbar__button__icon"></svg-icon>
                <span>{{ 'Navigation.Logout' | translate }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div class="navigation" [class.fixed]="navIsFixed" *ngIf="router.url !== '/error'">
    <div class="container">
      <div class="navigation__flex" [class.terminated]="terminated">
        <div class="navigation__text--wrapper">
          <span *ngIf="productType" class="navigation__icon navigation__icon__{{productType?.toLowerCase()}}">
            <svg-icon
              src="assets/images/icons/{{productType?.toLowerCase()}}.svg"
              class="service-tile__icon"
            ></svg-icon>
            <span
              *ngIf="firewallIconVisible && productType.toLowerCase() !== 'fw'"
              class="navigation-icon__overlap-square"
            >
              <svg-icon src="assets/images/icons/fw.svg" class="service-tile__overlap-icon"></svg-icon>
            </span>
          </span>
          <span *ngIf="IPPrefixIconVisible" class="navigation__icon navigation__icon__ip_prefix">
            <svg-icon src="assets/images/icons/ip_prefix.svg" class="service-tile__icon"></svg-icon>
          </span>
          <span *ngIf="CertIconVisible" class="navigation__icon navigation__icon__cert">
            <svg-icon src="assets/images/icons/cert.svg" class="service-tile__icon"></svg-icon>
          </span>
          <span *ngIf="DomainsIconVisible" class="navigation__icon navigation__icon__domains">
            <svg-icon src="assets/images/icons/domains.svg" class="service-tile__icon"></svg-icon>
          </span>
          <span class="navigation__text">
            <span *ngIf="routeTitle && !isChildRoute" class="navigation__text--top">{{ routeTitle }}</span>
            <button *ngIf="isChildRoute === true" (click)="goBack()" class="navigation__text--top navigation__back">
              <svg-icon src="assets/images/icons/icon-arrow-left.svg" class="back-button"> </svg-icon>
              {{ 'Global.Action.Back' | translate }}
            </button>

            <span class="navigation__text--main" *ngIf="isDetailPage === false">
              {{ forceTitle ? forceTitle : mainNavText }}</span
            >

            <span class="navigation__text--main" *ngIf="isDetailPage === true && detailData !== undefined">
              <svg-icon
                *ngIf="productType !== 'Wireless' && !terminated"
                src="assets/images/icons/status-{{ detailData.status | status_to_icon }}.svg"
                class="table__status status-icon"
              ></svg-icon>

              <svg-icon
                *ngIf="terminated"
                src="assets/images/icons/status-terminated.svg"
                class="table__status status-icon"
              ></svg-icon>
              <span class="navigation__truncate">{{ detailData.title }}</span>
            </span>
          </span>
        </div>
        <div class="navigation__right">
          <completer *ngIf="auth.isSuperUserRO() && auth.state.organisations.length > 1"></completer>
          <span *ngIf="auth.state.organisations.length === 1">{{ auth.state.viewingCustomer.name }}</span>

          <!--
            <span *ngIf="auth.state.organisations.length > 0 && isChildRoute === true" class="navigation__divider"></span>
            <button *ngIf="isChildRoute === true && isDetailPage === false" class="navigation__button button button--secondary" (click)="openDialogOrder()">
            {{ 'Order.RequestSubscription' | translate }}
            <svg-icon
            src="assets/images/icons/+.svg"
            class="button__icon"
            ></svg-icon>
          </button>-->

          <span *ngIf="isChildRoute === true && isDetailPage === true" class="navigation__divider"></span>

          <button
            *ngIf="isChildRoute === true && isDetailPage === true"
            class="navigation__button button button--secondary"
            routerLink="{{backURL}}"
          >
            {{ 'Global.Overview' | translate }}
            <svg-icon src="assets/images/icons/icon-grid.svg" class="button__icon"></svg-icon>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="subNavItems.length > 0" class="navigation__sub">
      <div class="container">
        <div class="navigation__sub-flex">
          <a *ngFor="let item of subNavItems" href="" [routerLink]="item.link" routerLinkActive="active">
            {{ item.title | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- temporary disabled maintenance banner in devMode -->
<div *ngIf="auth?.state.configuration.maintenanceBanner?.enabled && !devMode" class="maintenance-banner__wrapper">
  <div class="container">
    <div class="maintenance-banner">
      <div class="maintenance-banner__content">
        <div class="maintenance-banner__title">{{ auth.state.configuration.maintenanceBanner?.title }}</div>
        <div class="maintenance-banner__text">{{ auth.state.configuration.maintenanceBanner?.contents }}</div>
      </div>
    </div>
  </div>
</div>
