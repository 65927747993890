<div class="footer">
  <div class="footer__copyright">{{ 'Footer.Copyright' | translate }} {{ year }} </div>
  <div class="footer__navigation">
    <a
      *ngIf="enableSupportLink"
      [routerLink]="['/support']"
      class="footer__link"
      [ngClass]="{'footer__link--active': activeItem === 1}"
      >{{ 'Footer.Support' | translate }}</a
    >
    <a [routerLink]="['/over-surf']" class="footer__link" [ngClass]="{'footer__link--active': activeItem === 2}"
      >{{ 'Footer.AboutSurf' | translate }}</a
    >
    <a [routerLink]="['/disclaimer']" class="footer__link" [ngClass]="{'footer__link--active': activeItem === 3}"
      >{{ 'Footer.Disclaimer' | translate }}</a
    >
    <a
      href="https://www.surf.nl/cookieverklaring"
      class="footer__link"
      [ngClass]="{'footer__link--active': activeItem === 4}"
      target="_blank"
      >{{ 'Footer.Cookies' | translate }}</a
    >
    <a
      href="https://www.surf.nl/privacyverklaring-surf"
      class="footer__link"
      [ngClass]="{'footer__link--active': activeItem === 5}"
      target="_blank"
      >{{ 'Footer.Privacy' | translate }}</a
    >
  </div>
</div>
