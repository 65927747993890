import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { GenericSubscriptionComponent } from "../generic-subscription";
import {
  IpBgpSubscriptionDetailsGQL,
  IpBgpSubscriptionDetailsQuery,
  IpSubscriptionType,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";

@Component({
  selector: "ip-bgp-subscription",
  templateUrl: "ip-subscription.html",
})
export class IpBgpSubscriptionComponent extends GenericSubscriptionComponent<
  IpBgpSubscriptionDetailsGQL,
  IpBgpSubscriptionDetailsQuery,
  IpSubscriptionType
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";

  constructor(
    public auth: AuthService,
    protected api: ApiHelper,
    protected route: ActivatedRoute,
    protected query: IpBgpSubscriptionDetailsGQL,
  ) {
    super(api, route);
  }

  protected onQuerySuccess(result: ApolloQueryResult<IpBgpSubscriptionDetailsQuery>): IpSubscriptionType {
    return result.data.ipBgpDetails as IpSubscriptionType;
  }
}
