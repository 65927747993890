/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { RecordA } from "../../models/record-a";
import { RecordAaaa } from "../../models/record-aaaa";
import { RecordCaa } from "../../models/record-caa";
import { RecordCname } from "../../models/record-cname";
import { RecordDnskey } from "../../models/record-dnskey";
import { RecordDs } from "../../models/record-ds";
import { RecordLoc } from "../../models/record-loc";
import { RecordMx } from "../../models/record-mx";
import { RecordNaptr } from "../../models/record-naptr";
import { RecordNs } from "../../models/record-ns";
import { RecordPtr } from "../../models/record-ptr";
import { RecordSrv } from "../../models/record-srv";
import { RecordSshfp } from "../../models/record-sshfp";
import { RecordTlsa } from "../../models/record-tlsa";
import { RecordTxt } from "../../models/record-txt";
import { Status } from "../../models/status";

export interface ValidateRecord$Params {
  token?: string | null;
  body:
    | RecordA
    | RecordAaaa
    | RecordCaa
    | RecordCname
    | RecordDnskey
    | RecordDs
    | RecordLoc
    | RecordMx
    | RecordNaptr
    | RecordNs
    | RecordPtr
    | RecordSrv
    | RecordSshfp
    | RecordTlsa
    | RecordTxt;
}

export function validateRecord(
  http: HttpClient,
  rootUrl: string,
  params: ValidateRecord$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Status>> {
  const rb = new RequestBuilder(rootUrl, validateRecord.PATH, "post");
  if (params) {
    rb.query("token", params.token, {});
    rb.body(params.body, "application/json");
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Status>;
    }),
  );
}

validateRecord.PATH = "/surfdomeinen/validate/record";
