import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { FirewallSubscriptionDetailsGQL, FirewallSubscriptionDetailsQuery } from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { FirewallSubscriptionDetails } from "../../types";
import { ApolloQueryResult } from "@apollo/client";

@Component({
  selector: "firewall-subscription",
  templateUrl: "firewall-subscription.html",
})
export class FirewallSubscriptionComponent extends GenericSubscriptionComponent<
  FirewallSubscriptionDetailsGQL,
  FirewallSubscriptionDetailsQuery,
  FirewallSubscriptionDetails
> {
  public impactSetting = "Never";
  public serviceSpeed = 2; // TODO Replace with subscription.size? subscription.serviceSpeed?

  constructor(
    public auth: AuthService,
    protected api: ApiHelper,
    protected route: ActivatedRoute,
    protected query: FirewallSubscriptionDetailsGQL,
  ) {
    super(api, route);
  }

  protected onQuerySuccess(result: ApolloQueryResult<FirewallSubscriptionDetailsQuery>): FirewallSubscriptionDetails {
    return result.data.firewallDetails;
  }
}
