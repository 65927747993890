<div
  class="dialog__body dialog__body--grow dialog__body--grey dialog__body--center"
>
  <div class="selfservice-loader">
    <img *ngIf="!errorState" src="assets/images/configuration-illustration.svg" alt="" />
    <img *ngIf="errorState" src="assets/images/outofsync-illustration.svg" alt="" />
    <h3 class="selfservice-loader__title">
      <span *ngIf="!errorState">{{ 'Selfservice.Global.LoaderTitle' | translate }}</span>
      <span *ngIf="errorState">{{ 'Selfservice.Global.ErrorOccurredTitle' | translate }}</span>
    </h3>
    <p *ngIf="!errorState">{{ 'Selfservice.Global.LoaderText' | translate }}</p>
    <p *ngIf="errorState">{{ 'Selfservice.Global.ErrorOccurredText' | translate }}</p>
    <div *ngIf="!errorState" class="selfservice-loader__bar">
      <div
        class="selfservice-loader__bar-progress"
        [style.width.%]="progress"
      ></div>
    </div>
  </div>
</div>
<div class="dialog__footer dialog__footer--center dialog__footer--border">
  <div class="dialog__footer-text">
    <selfservice-authentication-box></selfservice-authentication-box>
  </div>
  <div *ngIf="errorState" class="button__flex">
    <button class="button button--secondary" (click)="notifySupport()" [ngClass]="{'loading': buttonLoading }">
      <svg-icon
        src="assets/images/icons/loader.svg"
        class="button__loader"
      ></svg-icon>
      <span>{{ 'Global.Action.NotifySupport' | translate }}</span>
    </button>
    <button
      *ngIf="!loading"
      class="button"
      (click)="close()"
    >
      <span>{{ 'Global.Action.Close' | translate }}</span>
    </button>
  </div>
</div>
