import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, EventEmitter } from "@angular/core";
import { Instance } from "../../models/instance";
import { SubscriptionService } from "../../../services/subscriptionservice";

import { AuthService } from "../../../services/AuthService";

import * as pMap from "p-map";
import { Apollo } from "apollo-angular";
import { PREFIX_SUBSCRIPTIONS_QUERY } from "../../../graphql/component/uplink-container";
@Component({
  selector: "uplink-detail-container",
  templateUrl: "uplink-detail-container.html",
})
export class UplinkDetailContainer implements OnChanges {
  @ViewChild("tabsnav", { read: ElementRef }) tabsnav: ElementRef;
  @ViewChild("iptabsnav", { read: ElementRef }) iptabsnav: ElementRef;
  @Input() subscription: Instance;
  @Input() bus: EventEmitter<string>;
  public endpoints: Instance[] = [];
  public ipPrefixes: any = {};
  public loading = false;
  private gqlPrefixes: any = {};

  constructor(
    public subscriptionService: SubscriptionService,
    private auth: AuthService,
    private apollo: Apollo,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.subscription === null || this.subscription === undefined) {
      return;
    }

    // if (this.subscription.ipGateway) {
    // this.getIpPrefix(this.subscription.ipGateway);
    this.gqlGetData();
    // }
  }

  gqlGetData() {
    this.loading = true;
    this.apollo
      .watchQuery<any>({
        query: PREFIX_SUBSCRIPTIONS_QUERY,
        errorPolicy: "all",
        variables: {
          id: this.subscription.subscriptionId,
        },
      })
      .valueChanges.subscribe(({ data }) => {
        this.loading = false;
        this.gqlPrefixes = data.subscription?.dependsOn?.filter((v) => v.__typename === "IpPrefixSubscription");

        this.ipPrefixes = this.gqlPrefixes.map((prefix) => ({
          ip_prefix: {
            prefix: prefix.ipPrefix?.ipam?.prefix,
          },
        }));
      });
  }

  async getIpPrefix(ipGateway: Instance) {
    // const mapper = async (pinSubscriptionId) => {
    //   return await this.subscriptionService.getIpPrefixes(pinSubscriptionId);
    // };
    // if (ipGateway.prefixSubscriptions !== undefined) {
    //   const result = await pMap(ipGateway.prefixSubscriptions, mapper, {
    //     concurrency: 2,
    //   });
    //   ipGateway.ip_prefixes = result;
    // }
    // if (ipGateway.internetpinnenPrefixSubscriptions !== undefined) {
    //   const result = await pMap(
    //     ipGateway.internetpinnenPrefixSubscriptions,
    //     mapper,
    //     {
    //       concurrency: 2,
    //     },
    //   );
    //   ipGateway.internetpinnen_ip_prefixes = result;
    // }
  }
}
