import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AccessRules, rulesAreAvailable } from "../../helpers/userroles";
import { AuthService } from "../../services/AuthService";

const rulesIdentifierForPath = (path: string): string | null => {
  if (path.startsWith("/ip-prefixes")) {
    return "IP_PREFIX";
  }
  if (path.startsWith("/cert")) {
    return "cert";
  }

  return null;
};

@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  constructor(
    private auth: AuthService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<true | UrlTree> {
    let productType = route.paramMap.get("productType");
    let rules: any[] = [];

    if (!productType) {
      // attempt to get the rules identifier another way
      productType = rulesIdentifierForPath(state.url);
    }

    if (productType && rulesAreAvailable(productType)) {
      rules.push(...AccessRules[productType].view, ...AccessRules[productType].edit);
    }

    // wait for the auth service to load the userdata.
    // on a page reload this guard is called too soon,
    // so it always redirects to the dashboard even though
    // the user has access.
    return new Promise<true | UrlTree>(async (resolve) => {
      // no rules present for this route
      if (!rules.length) {
        resolve(true);
        return;
      }

      // rules are present, and the auth service has loaded the userinfo
      if (this.auth.state.currentUser.roles) {
        if (this.auth.hasRole(rules)) {
          resolve(true);
        } else {
          resolve(this.router.parseUrl("/"));
        }
        return;
      }

      // rules are present, but the userinfo is not yet loaded
      await this.auth.__waaait();
      if (this.auth.hasRole(rules)) {
        resolve(true);
      }
      resolve(this.router.parseUrl("/"));
    });
  }
}
