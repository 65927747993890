/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { Status } from "../../models/status";
import { ZoneChangePrimary } from "../../models/zone-change-primary";
import { ZoneChangeSecondary } from "../../models/zone-change-secondary";
import { ZonePrimary } from "../../models/zone-primary";
import { ZoneSecondary } from "../../models/zone-secondary";

export interface ChangeZone$Params {
  organisation_id: string;
  zone_id: string;
  token?: string | null;
  check?: boolean;
  body: ZoneChangePrimary | ZoneChangeSecondary;
}

export function changeZone(
  http: HttpClient,
  rootUrl: string,
  params: ChangeZone$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<(ZonePrimary | ZoneSecondary) | Status>> {
  const rb = new RequestBuilder(rootUrl, changeZone.PATH, "put");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.path("zone_id", params.zone_id, {});
    rb.query("token", params.token, {});
    rb.query("check", params.check, {});
    rb.body(params.body, "application/json");
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<(ZonePrimary | ZoneSecondary) | Status>;
    }),
  );
}

changeZone.PATH = "/surfdomeinen/organisations/{organisation_id}/zones/{zone_id}";
