<page
  [detailData]="{title: pageTitle, status: subscription?.status}"
  [firewalled]="firewalled"
  [productType]="productType"
  [terminated]="isTerminated"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col--flex">
        <shortcut-menu-replicated
          *ngIf="!isTerminated"
          [subscription]="subscription"
          [productType]="productType"
          [messages]="activities"
        ></shortcut-menu-replicated>
        <terminated-card *ngIf="isTerminated" [date]="subscription?.endDate * 1000 | date:'y-MM-dd hh:mm'">
        </terminated-card>
      </div>
      <div class="col-lg-9">
        <div class="card">
          <div class="card__top-center">
            <h3 *ngIf="productType !== 'Port'">{{ 'ServiceDetails' | translate }}</h3>
            <div class="card__top-actions">
              <notification-button-replicated [subscription]="subscription" />
              <div
                *ngIf="auth?.isCurrentOrganisation(subscription?.customerId) && subscription && !isTerminated"
                class="button button--secondary"
                (click)="openIncidentDialog($event)"
              >
                {{ 'Services.Fulfilment.ReportProblem' | translate }}
              </div>
            </div>
          </div>

          <div class="row" *ngIf="productType === 'L2VPN' || productType === 'L3VPN'">
            <div class="col-12 col-sm-7">
              <table class="table-properties table-properties--no-margin">
                <tr>
                  <td>{{ 'ServiceElement.Owner' | translate }}</td>
                  <td>{{ subscription?.organisation }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.OwnAlias' | translate }}</td>
                  <td>
                    <span class="alias" *ngIf="!isEditable">
                      {{ subscription | custom_description_ingestor }}
                      <div
                        class="alias__icon"
                        (click)="startEditingCustomerDescription(subscription?.customerDescription)"
                      >
                        <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
                      </div>
                    </span>
                    <div *ngIf="isEditable" class="alias__form">
                      <input
                        [(ngModel)]="temporarySubscriptionName"
                        (blur)="saveCustomerDescription()"
                        class="input"
                        type="text"
                        value="{{ subscription | custom_description_ingestor }}"
                        autofocus
                      />
                      <span class="alias__form-button alias__form-button--save" (click)="saveCustomerDescription()">
                        <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
                      </span>
                      <span
                        class="alias__form-button alias__form-button--cancel"
                        (click)="cancelEditingCustomerDescription()"
                      >
                        <svg-icon src="assets/images/icons/x.svg"></svg-icon>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr
                  third-party-service-ids-row-content
                  *ngIf="subscription?.references?.length"
                  [subscription]="subscription"
                ></tr>
                <tr>
                  <td>{{ 'ServiceElement.SURFnetDescription' | translate }}</td>
                  <td>{{ subscription?.description }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.ServiceID' | translate }}</td>
                  <td>
                    <span class="clipboard__rule" *ngIf="subscription?.subscriptionId"
                      >{{ subscription?.subscriptionId | shorten_guid }}
                      <span class="clipboard__link" ngxClipboard [cbContent]="subscription?.subscriptionId">
                        <div class="clipboard__icon">
                          <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
                        </div> </span
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.ProductName' | translate }}</td>
                  <td>
                    {{ (productType === 'L2VPN' ? "EVPN - Multipoint" : "L3VPN - Multipoint") }} (aka {{
                    subscription?.product?.name }})
                  </td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.Protection' | translate }}</td>
                  <td>{{ subscription?.protection }}</td>
                </tr>
              </table>
            </div>
            <div class="col-12 col-sm-5">
              <table class="table-properties table-properties--no-margin">
                <tr
                  *ngIf="subscription?.circuits && subscription?.circuits.length > 0 && subscription.circuits[0].speedPolicer !== null"
                >
                  <td>{{ 'ServiceElement.SpeedPolicer' | translate }}</td>
                  <td *ngIf="subscription">
                    <span
                      *ngIf="auth.isSelfServiceEnabled && auth?.hasRole(['Infraverantwoordelijke']) && auth?. isCurrentOrganisation(subscription?.customerId); else readOnly"
                      class="table-properties__flex"
                      [ngClass]="{'table-properties__flex--blue': subscription.circuits[0].speedPolicer }"
                    >
                      {{ subscription.circuits[0].speedPolicer | enabled_or_value }}
                      <selfservice-toggle
                        *ngIf="productType === 'L2VPN'"
                        [active]="subscription.circuits[0].speedPolicer"
                        (click)="openSelfserviceDialog('sp')"
                      ></selfservice-toggle>
                    </span>
                    <ng-template #readOnly>
                      {{ subscription.circuits[0].speedPolicer | enabled_or_value }}
                    </ng-template>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.ServiceSpeed' | translate }}</td>
                  <td>{{ subscription?.serviceSpeed * 1000000 | readable_size }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.Domain' | translate }}</td>
                  <td>{{ subscription?.domain }}</td>
                </tr>
                <tr *ngIf="isTerminated">
                  <td>{{ 'ServiceElement.Status' | translate }}</td>
                  <td>{{ subscription?.status }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.Start' | translate }}</td>
                  <td>{{ subscription?.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
                </tr>
                <tr *ngIf="isTerminated">
                  <td>{{ 'ServiceElement.End' | translate }}</td>
                  <td>{{ subscription?.endDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
                </tr>
                <tr *ngIf="subscription?.l3vpnss">
                  <td>{{ 'ServiceElement.L3VPNLocalAS' | translate }}</td>
                  <td>{{ subscription?.l3vpnss?.l3vpnAsn }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isTerminated" class="hide-everything-after-this-div"></div>

    <page-tab-replicated [productType]="productType" (activeTabEmitter)="changeActiveTab($event)">
    </page-tab-replicated>

    <div *ngIf="activeTab === 'graphic-view'">
      <networkgraph [subscriptionId]="subscription?.subscriptionId" #chart></networkgraph>
    </div>

    <div *ngIf="activeTab === 'config'">
      <div class="section" *ngIf="['IP', 'L2VPN', 'L3VPN', 'FW'].includes(productType)">
        <tabbed-detail-container-replicated
          [service]="subscription | as_instance"
          [bus]="messageBus"
          [canModify]="productType !== 'FW'"
          [selfserviceState]="undefined"
        />
      </div>
      <linechart-replicated id="chart" #chart [subscription]="subscription" />
    </div>
  </div>
</page>
