import { Component, SimpleChanges, OnChanges, Input } from "@angular/core";
import * as Highcharts from "highcharts";
import { ApiHelper } from "../../../helpers/apihelper";

@Component({
  selector: "system-stats",
  templateUrl: "system-stats.html",
  styleUrls: ["system-stats.scss"],
})
export class SystemStatsComponent implements OnChanges {
  @Input() subscription;
  public chartCpuUsageRef: Highcharts.Chart;
  public chartMemoryUsageRef: Highcharts.Chart;
  public chartSessionsRef: Highcharts.Chart;
  public chartSessionRateRef: Highcharts.Chart;
  chartOptionsCPUUsage: Highcharts.Options = this.chartInfo(true, "CPU Usage");
  chartOptionsMemoryUsage: Highcharts.Options = this.chartInfo(true, "Memory Usage");
  chartOptionsSessions: Highcharts.Options = this.chartInfo(false, "Sessions");
  chartOptionsSessionRate: Highcharts.Options = this.chartInfo(false, "Session Rate");

  public tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  public defaultTimeWindow = 1 * 24 * 3600 * 1000;
  public firstDate = new Date(Date.now() + -this.defaultTimeWindow);
  public secondDate = new Date();
  public defaultDate = true;
  highcharts: typeof Highcharts = Highcharts;
  selectedPreset = "1d";
  presets = ["1d", "1w", "1m", "1y"];
  public retrievingChartData: boolean;

  constructor(private api: ApiHelper) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"] && this.subscription !== undefined && this.subscription !== null) {
      this.getNewData();
    }
  }

  synchronize(point) {
    let chartRefs = [this.chartCpuUsageRef, this.chartMemoryUsageRef, this.chartSessionsRef, this.chartSessionRateRef];
    chartRefs.forEach((chartRef: any) => {
      if (chartRef.customCrosshair) {
        // destroy previous crosshair
        chartRef.customCrosshair.element.remove();
      }
    });

    // show tooltip on second chart
    chartRefs.forEach((chartRef: any) => {
      if (chartRef && chartRef.series[0] !== undefined) {
        let index = chartRef.series[0].xData.indexOf(point.x);
        let selectedPoint = chartRef.series[0].points[index];
        chartRef.tooltip.refresh(selectedPoint);
      }
    });

    chartRefs.forEach((chartRef: any) => {
      chartRef.customCrosshair = chartRef.renderer
        .rect(point.plotX + chartRef.plotLeft - 1, chartRef.plotTop, 1, chartRef.plotSizeY)
        .attr({
          fill: "#ACB3B9",
        })
        .add();
    });
  }

  setCpuUsageChartInstance(chart: Highcharts.Chart) {
    this.chartCpuUsageRef = chart;
  }

  setMemoryUsageChartInstance(chart: Highcharts.Chart) {
    this.chartMemoryUsageRef = chart;
  }

  setSessionsChartInstance(chart: Highcharts.Chart) {
    this.chartSessionsRef = chart;
  }

  setSessionRateChartInstance(chart: Highcharts.Chart) {
    this.chartSessionRateRef = chart;
  }

  chartError(reason?: string) {
    let chartNotification = `Geen data gevonden (${reason})`;

    let chartRefs = [this.chartCpuUsageRef, this.chartMemoryUsageRef, this.chartSessionsRef, this.chartSessionRateRef];
    chartRefs.forEach((chartRef: any) => {
      if (chartRef.customCrosshair) {
        chartRef.hideLoading();
        // hideNoData, because the string is not updated unless hidden.
        chartRef.hideNoData();
        chartRef.showNoData(chartNotification);
      }
    });
  }

  async getNewData() {
    this.retrievingChartData = true;
    this.updateChartData(this.chartCpuUsageRef, []);
    this.updateChartData(this.chartMemoryUsageRef, []);
    this.updateChartData(this.chartSessionsRef, []);
    this.updateChartData(this.chartSessionRateRef, []);

    const subscriptionId = this.subscription.subscriptionId;
    try {
      const chartData = await this.api.getNfvStats(subscriptionId, this.firstDate, this.secondDate);
      this.updateChartData(this.chartCpuUsageRef, chartData.cpuUsage);
      this.updateChartData(this.chartMemoryUsageRef, chartData.memoryUsage);
      this.updateChartData(this.chartSessionsRef, chartData.sessionCount);
      this.updateChartData(this.chartSessionRateRef, chartData.sessionRate);
    } catch (err) {
      this.chartError("API error");
      console.error("Failed retrieving firewall stats with error: ", err);
    } finally {
      this.retrievingChartData = false;
    }
  }

  updateChartData(chartRef /*: Highcharts.Chart*/, chartData: number[]) {
    if (!chartRef) {
      console.warn("Invalid reference to chart provided; aborting chart rendering.");
      return;
    }

    let indexNow = 0;
    if (chartRef.series[indexNow] === undefined) {
      chartRef.addSeries(
        {
          name: "Serie 1",
          data: chartData,
          type: "line",
          color: "#000000",
        },
        false,
        false,
      );
    } else {
      chartRef.series[indexNow].update({ name: "Serie 1", type: "line" }, false);
      chartRef.series[indexNow].setData(chartData, false, false, false);
    }

    chartRef.redraw(true);
    chartRef.hideLoading();
    chartRef.zoom(); // force zoom out
  }

  /**
   * Handle newly picked dates (chart range)
   */
  onDateChange() {
    this.selectedPreset = "";
    if (this.secondDate > new Date()) {
      this.secondDate = new Date();
    }

    if (this.firstDate > this.secondDate) {
      this.firstDate = new Date(this.secondDate.getTime() + -this.defaultTimeWindow);
    }

    this.defaultDate = false;
    this.getNewData();
  }

  resetDatePickers() {
    this.selectedPreset = "";
    this.secondDate = new Date();
    this.firstDate = new Date(this.secondDate.getTime() + -this.defaultTimeWindow);
    this.defaultDate = true;
    this.getNewData();
  }

  setDatePreset(preset: string) {
    this.selectedPreset = preset;
    this.firstDate = new Date();

    switch (preset) {
      case "1d":
        this.firstDate.setDate(this.firstDate.getDate() - 1);
        break;
      case "1w":
        this.firstDate.setDate(this.firstDate.getDate() - 7);
        break;
      case "1m":
        this.firstDate.setMonth(this.firstDate.getMonth() - 1);
        break;
      case "1y":
        this.firstDate.setDate(this.firstDate.getDate() - 365);
        break;
    }

    this.defaultDate = false;
    this.secondDate = new Date();
    this.getNewData();
  }

  chartInfo(yAxisLabelFormatPercent: boolean, labelName: string): any {
    const thisContext = this;

    let yAxisLabelFormat = "{text}";
    if (yAxisLabelFormatPercent) {
      yAxisLabelFormat = "{value} %";
    }

    return {
      title: {
        text: "",
      },

      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },

      chart: {
        backgroundColor: "#FAFBFD",
        spacingTop: 40,
        height: 376,
      },

      plotOptions: {
        line: {
          marker: {
            enabled: false,
          },
        },
      },

      credits: {
        enabled: false,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        shadow: false,
        backgroundColor: "white",
        borderColor: "#E6E9EB",
        pointFormatter() {
          let v = parseFloat(this.y).toFixed(2);
          return `<span style="color:${this.color}">\u25CF</span> ${labelName}: <b>${v}</b><br/>`;
        },
      },
      yAxis: {
        title: {
          enabled: false,
        },
        labels: {
          format: yAxisLabelFormat,
          style: {
            fontFamily: "Proxima, sans-serif",
          },
        },
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          hour: "%H:%M",
          minute: "%H:%M",
        },
        minPadding: 0,
        maxPadding: 0,
        lineColor: "transparent",
        minorTickLength: 0,
        tickLength: 0,
        labels: {
          style: {
            fontFamily: "Proxima, sans-serif",
            color: "#7f8c8d",
            font: "9px Proxima",
          },
        },
      },
      series: [
        {
          data: [],
          color: "#2CA055",
          lineWidth: 1,
          point: {
            events: {
              mouseOver() {
                thisContext.synchronize(this);
              },
            },
          },
        },
      ],
      noData: {
        style: {
          fontFamily: "Proxima, sans-serif",
          fontWeight: "600",
          fontSize: "14px",
          color: "#6D6E71",
        },
      },
    };
  }
}
