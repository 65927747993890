<div class="card card--no-padding">
  <div class="access-points__wrapper">
    <div *ngIf="accesspoints.length === 0" class="access-points__loader">
      <loader></loader>
    </div>
    <div
      *ngIf="accesspoints.length > 0"
      class="access-points"
      #scrollarea
      (scroll)="checkScroll()"
    >
      <div *ngFor="let accesspoint of accesspoints" class="access-points__item">
        <div class="access-points__item-left">
          <div class="access-points__aspect {{accesspoint.brand}}">
            <div class="access-points__model">
              Model {{ accesspoint.model }}
            </div>
          </div>
        </div>
        <div class="access-points__item-right">
          <div class="access-points__item-info">
            <span>{{ 'Wireless.Ap.Active' | translate }}</span> {{ accesspoint.active }}
          </div>
          <div class="access-points__item-info">
            <span>{{ 'Wireless.Ap.Inactive' | translate }}</span> {{ accesspoint.inactive }}
          </div>
          <div class="access-points__item-info">
            <span>{{ 'Wireless.Ap.Total' | translate }}</span> {{ accesspoint.total }}
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="!leftDisabled"
      class="access-points__control access-points__control--prev"
      (click)="scrollLeft()"
    >
      <svg-icon
        class="access-points__control-icon"
        src="assets/images/icons/chevron-down.svg"
      >
      </svg-icon>
    </div>
    <div
      *ngIf="!rightDisabled"
      class="access-points__control access-points__control--next"
      (click)="scrollRight()"
    >
      <svg-icon
        class="access-points__control-icon"
        src="assets/images/icons/chevron-down.svg"
      >
      </svg-icon>
    </div>
  </div>
</div>
