export const getFilteredLocation = (locations, subscriptions) => {
  const filteredSubscriptions = subscriptions.filter((subscription) => {
    // make search case insensitive
    locations = locations.map((loc) => loc.toLowerCase());
    let endpoints = [];
    if (subscription.meta && subscription.meta.circuits && subscription.meta.circuits[0]) {
      if (subscription.meta.product.productType === "IP") {
        endpoints = subscription.meta.saps.map(
          (e) =>
            `${e.port.address.city.toLowerCase()} (${e.port.address.street.toLowerCase()} ${e.port.address.number})`,
        );
      } else {
        endpoints = subscription.meta.circuits[0].endpoints.map(
          (e) =>
            `${e.port.address.city.toLowerCase()} (${e.port.address.street.toLowerCase()} ${e.port.address.number})`,
        );
      }
    }
    if (subscription.meta && subscription.meta.product.productType === "Port") {
      endpoints = [
        `${subscription.meta.address.city.toLowerCase()} (${subscription.meta.address.street.toLowerCase()} ${
          subscription.meta.address.number
        })`,
      ];
    }

    if (subscription.meta && subscription.meta.product.productType === "Wireless") {
      endpoints = [
        `${subscription.meta.location.city.toLowerCase()} (${subscription.meta.location.address.toLowerCase()})`,
      ];
    }

    // endpoints = subscription.endpoints.map(e => `${e.city.toLowerCase()} (${e.street.toLowerCase()} ${e.number})`);
    for (const endpoint of endpoints) {
      const locationMatches = locations.filter((l) => endpoint.includes(l));
      if (locationMatches.length > 0) {
        return true;
      }
    }

    return false;
  });
  return filteredSubscriptions;
};
