import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import {
  LightpathRedundantSubscriptionDetailsGQL,
  LightpathRedundantSubscriptionDetailsQuery,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { LightPathRedundantSubscriptionDetails } from "../../types";
import { ApolloQueryResult } from "@apollo/client";

@Component({
  selector: "lightpath-subscription",
  templateUrl: "lightpath-subscription.html",
})
export class LightpathRedundantSubscriptionComponent extends GenericSubscriptionComponent<
  LightpathRedundantSubscriptionDetailsGQL,
  LightpathRedundantSubscriptionDetailsQuery,
  LightPathRedundantSubscriptionDetails
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";

  constructor(
    public auth: AuthService,
    protected api: ApiHelper,
    protected route: ActivatedRoute,
    protected query: LightpathRedundantSubscriptionDetailsGQL,
  ) {
    super(api, route);
  }

  get allPortsAreUntagged(): boolean {
    return this.subscription?.circuits?.every((circuit) =>
      circuit.endpoints.every((endpoint) => endpoint.port.portMode === "Untagged"),
    );
  }

  get allPortsAreNotAggregated(): boolean {
    return this.subscription?.circuits?.every((circuit) =>
      circuit.endpoints.every((endpoint) => endpoint.port.product.tag !== "AGGSP"),
    );
  }

  protected onQuerySuccess(
    result: ApolloQueryResult<LightpathRedundantSubscriptionDetailsQuery>,
  ): LightPathRedundantSubscriptionDetails {
    return result.data.lrDetails;
  }
}
