import { Apollo } from "apollo-angular";
import { Component, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, EventEmitter } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import * as pMap from "p-map";
import { environment } from "../../../environments/environment";
import { AuthService } from "../../../services/AuthService";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { DialogSelfserviceComponent } from "../../dialogs/dialog-selfservice/dialog-selfservice";
import { Instance } from "../../models/instance";
import { InstanceFactory } from "../../models/base/instancefactory";
import { StorageHelper } from "../../../helpers/storage/storagehelper";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { TABBED_DETAILCONTAINER_QUERY } from "../../../graphql/component/tabbed-detail-container";
import { findAddress } from "../../../helpers/subscription/shared";
import { transformFwInterconnects } from "../../../helpers/subscription/l3vpn";

@Component({
  selector: "tabbed-detail-container",
  templateUrl: "tabbed-detail-container.html",
})
export class TabbedDetailContainer implements OnChanges {
  @ViewChild("tabsnav", { read: ElementRef }) tabsnav: ElementRef;
  @ViewChild("iptabsnav", { read: ElementRef }) iptabsnav: ElementRef;
  @Input() service: Instance;
  @Input() bus: EventEmitter<string>;
  @Input() canModify = true;
  @Input() selfserviceState: any;
  @Input() presetTabData: Array<any> = [];
  public endpoints: Instance[] = [];
  public ipPrefixes: any = {};
  public loading = false;
  public tabs: Array<any> = [];
  public activeTabIndex = 0;
  public displayLoadingBanner = false;
  public dialogRef: MatDialogRef<DialogSelfserviceComponent, any>;
  public overflowMenuIPActive: boolean;
  private subscriptionsDependingOnThisSubscription: any[] = [];
  private subscriptionsInUseByThisSubscription: any[] = [];
  private configuringTabs = false;

  constructor(
    public subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private apollo: Apollo,
    public auth: AuthService,
  ) {}

  get isDevelopment(): boolean {
    return !environment.production;
  }

  get activeTab() {
    if (this.activeTabIndex < 0 || this.activeTabIndex >= this.tabs.length) {
      return null;
    }

    return this.tabs[this.activeTabIndex] || null;
  }

  get showModifyOptions() {
    if (!this.canModify) {
      return false;
    }

    if (!this.service || !this.service.product) {
      return false;
    }

    if (this.service.firewallEnabled) {
      return false;
    }

    return ["L2VPN", "FW"].includes(this.service.product.productType);
  }

  async load() {
    if (this.loading) {
      return await new Promise((resolve) => {
        setTimeout(() => {
          resolve(this.load());
        }, 1000);
      });
    }

    this.loading = true;
    this.tabs = [];

    this.apollo
      .watchQuery({
        query: TABBED_DETAILCONTAINER_QUERY,
        errorPolicy: "all",
        variables: {
          id: this.service.subscriptionId,
        },
      })
      .valueChanges.subscribe(({ data }: any) => {
        if (!data || !this.service) {
          this.loading = false;
          return;
        }
        if (!data.loading) {
          this.tabs = [];
          this.service.gqlSaps = data.subscription.vc;

          // transform inUseBy and dependsOn subscriptions to the
          // correct instance type.
          data.subscription.inUseBy?.forEach((sub) => {
            const formattedSubscription = InstanceFactory.create(sub);
            formattedSubscription.address = findAddress(sub);
            this.subscriptionsDependingOnThisSubscription.push(formattedSubscription);
          });
          data.subscription.dependsOn?.forEach((sub) => {
            this.subscriptionsInUseByThisSubscription.push(InstanceFactory.create(sub));
          });

          switch (this.service.product.productType) {
            case "FW":
              this.service.firewall = data.subscription.firewall;
              break;
            case "L3VPN":
              this.service.firewallInterconnects = transformFwInterconnects(
                data.subscription.dependsOn,
                data.subscription.vpnvc.saps,
              );
              break;
          }
          this.setupComponent();
        }
      });
  }

  openIpPrefixDialog(): void {
    if (this.auth.isAuthenticatedForSelfService()) {
      this.dialogRef = this.dialog.open(DialogSelfserviceComponent, {
        data: {
          type: "ip-prefixes",
          loader: true,
          currentStep: 1,
        },
      });
      this.dialogRef.componentInstance.loadingData = true;
    }
  }

  findDependingSubscription(id: string) {
    return this.subscriptionsDependingOnThisSubscription.find((sub) => sub.subscriptionId === id);
  }

  async setupComponent() {
    // Sort: [firewall interconnects], [other items by segmentId]
    if (this.service.esis) {
      this.service.esis.sort((a, b) => {
        if (a.firewallInterconnect) {
          return -1;
        }

        return a.segmentId > b.segmentId ? 1 : -1;
      });
    }

    // Setup tabs
    if (this.configuringTabs) {
      return;
    }
    this.tabs = [];
    this.configuringTabs = true;

    if (this.presetTabData && this.presetTabData.length > 0) {
      for (const tabData of this.presetTabData) {
        await this.addTab(tabData, null, "Tabje subtitle");
        this.loading = false;
        return;
      }
    }

    if (this.service.product.productType === "FW") {
      await this.addTabsForFirewall();
    }

    for (const fw of this.service?.firewallInterconnects || []) {
      await this.addTab({ firewallInterconnect: fw });
    }

    if (this.service?.product.productType === "L2VPN") {
      for (const esi of this.service.esis || []) {
        await this.addTab(esi);
      }
    } else {
      await this.addTabsForSAPs(this.service?._saps || []);
    }

    await this.addTabsForSAPs(
      (this.service?.l3vpnsapss || []).map((item) => ({
        ...item,
        ...item.sap,
        l3vpn_ipv4Address: item.ipv4Address,
        l3vpn_ipv4RemoteAddress: item.ipv4RemoteAddress,
        l3vpn_ipv6Address: item.ipv6Address,
        l3vpn_ipv6RemoteAddress: item.ipv6RemoteAddress,
      })),
    );

    // Set active tab
    if (this.activeTabIndex < 0 || this.activeTabIndex >= this.tabs.length) {
      this.activeTabIndex = 0;
    }

    this.loading = false;
    this.configuringTabs = false;

    ["bfd", "bgp", "add-port", "remove-port", "vlan-l2vpn"].forEach((type) => {
      if (this.auth.hasPendingStrongAction(type)) {
        this.startDialog(type);
      }
    });
    if (this.auth.hasPendingStrongAction("ip-prefixes")) {
      this.displayLoadingBanner = true;
    }
  }

  startDialog(type) {
    this.auth.clearPendingStrongAction(type);
    this.selfserviceState = this.auth.getSelfserviceState();
    this.openSelfserviceDialog(type);
  }

  async addTabsForSAPs(saps) {
    if (!saps || saps.length === 0) {
      return;
    }

    for (const sap of saps) {
      if (typeof sap.port.customer !== "string") {
        sap.port.organisation = sap.port.customer.name;
      }
      if (sap.prefixSubscriptions !== null) {
        await this.getIpPrefix(sap);
      }
      await this.addTab(sap);
    }
    this.displayLoadingBanner = false;

    if (this.auth.hasPendingStrongAction("ip-prefixes")) {
      this.startDialog("ip-prefixes");
    }

    if (this.selfserviceState && this.selfserviceState.action === "addPrefix") {
      // only trigger if selfservice authentication is done (on the subscription-detail page for example)
      if (this.auth.isAuthenticatedForSelfService()) {
        this.openSelfserviceDialog("ip-prefixes");
      }
    }
  }

  async addTabsForFirewall() {
    for (const endpoint of [...(this.service.l2Endpoints || []), ...(this.service.l3Endpoints || [])]) {
      if (!endpoint.otherSubscriptionId || endpoint.otherSubscriptionId.length === 0) {
        continue;
      }

      let isL3VPN = false;
      if (this.service.l3Endpoints) {
        isL3VPN = this.service.l3Endpoints.includes(endpoint);
      }
      const type = isL3VPN ? "l3vpn" : "l2vpn";
      const vpnEndpointData = this.findDependingSubscription(endpoint.otherSubscriptionId);

      await this.addTab(vpnEndpointData, null, `${type.toUpperCase()} service`);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.service === null || this.service === undefined) {
      this.tabs = [];
      this.loading = false;
      return;
    }

    this.load();
  }

  descriptionForEntity(entity) {
    if (!entity) {
      return null;
    }
    const customerId = StorageHelper.currentUser;

    if (entity.customerId && entity.customerDescriptions && entity.customerDescriptions[entity.customerId]) {
      return entity.customerDescriptions[entity.customerId];
    }

    return entity.description || null;
  }

  productTypeForEntity(entity) {
    if (!entity) {
      return "";
    }

    if (entity.product) {
      return entity.product.productType || "";
    }

    if (entity.endpoints && entity.endpoints.length > 0 && entity.endpoints[0].product) {
      return entity.endpoints[0].product.productType || "";
    }

    return "";
  }

  async addTab(item, defaultTitle = "-", defaultSubtitle = "-") {
    const properties = {
      title: defaultTitle || "-",
      subtitle: defaultSubtitle || "-",
      item,
      content: {
        showTrafic: false,
        serviceSummaries: [],
        configurationDetails: {
          title: "",
          rows: [],
        },
      },
    };

    let addAsFirstTab = false;
    if (item.firewallInterconnect) {
      addAsFirstTab = true;
      properties.title = this.translate.instant("ServiceElement.FirewallInterconnect");

      properties.subtitle = this.descriptionForEntity(item) || "-";
      try {
        // get from dependsOn
        // const firewallInfo = await this.subscriptionService.getSubscriptionDetails(
        //   "fw",
        //   item.firewallInterconnect.firewallSubscriptionId,
        // );
        const firewallInfo = this.subscriptionsInUseByThisSubscription.find(
          (sub) => sub.subscriptionId === item.firewallInterconnect.firewallSubscriptionId,
        );
        if (!firewallInfo) {
          throw new Error("Unable to find firewall interconnect subscription");
        }
        properties.subtitle = this.descriptionForEntity(firewallInfo) || "-";

        if (firewallInfo.ipGateway) {
          delete firewallInfo.ipGateway;
        }

        firewallInfo.subscriptionInstanceId = item.firewallInterconnect.subscriptionInstanceId;
        properties.content.serviceSummaries.push(firewallInfo);

        // only show the trafic data on the L2VPN detail page
        if (this.service.product.productType === "L2VPN") {
          properties.content.showTrafic = true;
        }

        // only show IP data on the L3VPN detail page
        if (this.service.product.productType === "L3VPN") {
          properties.content.configurationDetails.title = this.translate.instant("ServiceElement.IPConfiguration");

          const keys = [
            { key: "availabilityZone", title: "IP.CustomerASN" },
            { key: "firewallAsn", title: "ServiceElement.FirewallASN" },
            { key: "ipv4Prefix", title: "ServiceElement.IPv4Prefix" },
            {
              key: "ipv4AddressFirewall",
              title: "ServiceElement.IPv4AddressFirewall",
            },
            { key: "ipv4Mtu", title: "ServiceElement.IPv4MTU" },
            { key: "ipv4MaxPrefix", title: "ServiceElement.IPv4MaxPrefix" },
            { key: "ipv6Prefix", title: "ServiceElement.IPv6Prefix" },
            {
              key: "ipv6AddressFirewall",
              title: "ServiceElement.IPv6AddressFirewall",
            },
            {
              key: "ipv6Mtu",
              title: "ServiceElement.IPv6MTU",
            },
            {
              key: "ipv6MaxPrefix",
              title: "ServiceElement.IPv6MaxPrefix",
            },
          ];

          properties.content.configurationDetails.rows = this.propertyKeysToRows(keys, item.firewallInterconnect);
        }
      } catch (e) {
        // Ignore error for now, don't add the tab.
        console.error(e);
      }
    } else if (item.port) {
      properties.title = this.descriptionForEntity(item.port) || "-";

      properties.subtitle = item.port.locationDescription || item.port.customer || item.locationDescription || "-";
      if (item.vlanrange && item.vlanrange.length > 0) {
        properties.subtitle = `${properties.subtitle}, ${this.translate.instant("ServiceElement.VLAN")} ${
          item.vlanrange
        }`;
      } else {
        properties.subtitle = `${properties.subtitle}, ${this.translate.instant("Subscription.UntaggedPort")}`;
      }

      /*[showTraffic]="false"
          [showTrafficButton]="true"*/

      properties.content.serviceSummaries.push(item);
      properties.content.configurationDetails.title = this.translate.instant("ServiceElement.IPConfiguration");

      const keys = [
        { key: "asn", title: "IP.CustomerASN" },
        { key: "bgpMetric", title: "IP.BGPMetric" },
        { key: "bgpSessionPriority", title: "IP.BGPSessionPriority" },
        { key: "bgpExportPolicy", title: "IP.BGPExportPolicy" },
        // { key: "bgpHashAlgorithm", title: "IP.BGPHashAlgorithm" }, this field does not represent any value
        {
          key: "bgpPassword",
          title: "IP.BGPPassword",
          if: item.bgpPassword,
        },
        {
          key: "",
          title: "IP.BFD",
          if: this.service.ipRoutingType === "BGP",
          display: "bgp-component",
        },
        {
          key: "bfd",
          title: "IP.BFD",
          if: this.service.product.productType === "L3VPN",
        },
        {
          key: "bfdMinimumInterval",
          title: "IP.BFDMinimalInterval",
          if:
            this.service.ipRoutingType === "BGP" ||
            (this.service.product.productType === "L3VPN" && item.bfd && item.bfdMinimumInterval),
        },
        {
          key: "bfdMultiplier",
          title: "IP.BFDMultiplier",
          if:
            this.service.ipRoutingType === "BGP" ||
            (this.service.product.productType === "L3VPN" && item.bfd && item.bfdMultiplier),
        },
        { key: "l3vpn_ipv4Address", title: "IP.L3VPNNeighborIPv4" },
        { key: "l3vpn_ipv4RemoteAddress", title: "IP.CustomerNeighborIPv4" },
        {
          key: "ipv4PointToPointPrefix.surfIpAddress",
          title: "IP.SurfnetNeighborIPv4",
        },
        {
          key: "ipv4PointToPointPrefix.clientIpAddress",
          title: "IP.CustomerNeighborIPv4",
        },
        { key: "ipv4Mtu", title: "IP.IPv4MTU" },
        { key: "ipv4MaxPrefix", title: "IP.IPv4MaxPrefix" },
        { key: "l3vpn_ipv6Address", title: "IP.L3VPNNeighborIPv6" },
        { key: "l3vpn_ipv6RemoteAddress", title: "IP.CustomerNeighborIPv6" },
        {
          key: "ipv6PointToPointPrefix.surfIpAddress",
          title: "IP.SurfnetNeighborIPv6",
        },
        {
          key: "ipv6PointToPointPrefix.clientIpAddress",
          title: "IP.CustomerNeighborIPv6",
        },
        {
          key: "ipv6Mtu",
          title: "IP.IPv6MTU",
        },
        {
          key: "ipv6MaxPrefix",
          title: "IP.IPv6MaxPrefix",
        },
        {
          key: "",
          title: "IP.IPPrefixes",
          display: "textbox",
          if: item.ip_prefixes && item.ip_prefixes.length > 0 ? true : false,
        },
      ];

      properties.content.configurationDetails.rows = this.propertyKeysToRows(keys, item);
    } else if (item.endpoints && item.endpoints.length > 0) {
      properties.title =
        item.endpoints.length > 1 ?
          this.translate.instant("ServiceElement.MultipleEndpoints")
        : this.descriptionForEntity(item.endpoints[0].port) || "-";
      properties.subtitle = `VLAN ${item.endpoints[0].vlanrange}`;

      for (const endpoint of item.endpoints) {
        properties.content.serviceSummaries.push(endpoint);
      }
    } else if (this.service.product.productType === "FW") {
      properties.title = this.descriptionForEntity(item) || "-";
      properties.content.serviceSummaries.push(item);

      properties.content.configurationDetails.title = this.translate.instant("ServiceElement.IPConfiguration");

      const l2Endpoint = this.service.l2Endpoints?.find((el) => el.otherSubscriptionId === item.subscriptionId);

      if (l2Endpoint !== undefined) {
        const keys = [
          {
            key: "ipv4Prefixes",
            title: "ServiceElement.IPv4PointToPoint",
            display: "textbox",
            if: l2Endpoint.ipv4Prefixes && l2Endpoint.ipv4Prefixes.length > 0 ? true : false,
          },
          {
            key: "ipv6Prefixes",
            title: "ServiceElement.IPv6PointToPoint",
            display: "textbox",
            if: l2Endpoint.ipv6Prefixes && l2Endpoint.ipv6Prefixes.length > 0 ? true : false,
          },
        ];

        properties.content.configurationDetails.rows = this.propertyKeysToRows(keys, l2Endpoint);
      }
    } else {
      properties.title = this.descriptionForEntity(item) || "-";
      properties.content.serviceSummaries.push(item);
    }

    if (addAsFirstTab) {
      this.tabs.unshift(properties);
    } else {
      this.tabs.push(properties);
    }
  }

  propertyKeysToRows(keys, item) {
    if (!item || !keys || keys.length === 0) {
      return [];
    }

    const pathValue = (obj, is) => {
      if (typeof is === "string") {
        return pathValue(obj, is.split("."));
      } else if (is.length === 0 || !obj) {
        return obj;
      }

      return pathValue(obj[is[0]], is.slice(1));
    };

    const rows = [];
    for (const key of keys) {
      if (key.if === false) {
        continue;
      }

      let value = "";
      if (!key.key || key.key.length === 0) {
        value = item;
      } else {
        value = pathValue(item, key.key);
        if (value === null || value === undefined) {
          value = "";
        }
      }
      if (key.loopKey && key.loopKey.length > 0) {
        let values = [];
        for (const loopValue of value) {
          values.push(pathValue(loopValue, key.loopKey));
        }

        value = values.join("\n");
      }

      if (value.toString().length === 0) {
        continue;
      }
      rows.push({
        title: this.translate.instant(key.title) || "-",
        value,
        display: key.display || "default",
      });
    }

    return rows;
  }

  async getIpPrefix(sap: Instance) {
    const mapper = async (pinSubscriptionId) => await this.subscriptionService.getIpPrefixes(pinSubscriptionId);

    if (sap.prefixSubscriptions !== undefined) {
      const result = await pMap(sap.prefixSubscriptions, mapper, {
        concurrency: 2,
      });

      sap.ip_prefixes = result;
    }
  }

  moveTab(moveAmount = 1) {
    this.activeTabIndex += moveAmount;
    if (this.activeTabIndex >= this.tabs.length) {
      this.activeTabIndex = this.tabs.length - 1;
    } else if (this.activeTabIndex < 0) {
      this.activeTabIndex = 0;
    }

    const scrollAmount = moveAmount * 300;
    this.tabsnav.nativeElement.scrollLeft += scrollAmount;
  }

  openSelfserviceDialog(type) {
    let selectedPrefix = "";
    let currentStep = 1;
    if (this.selfserviceState) {
      selectedPrefix = this.selfserviceState.selectedPrefix;
      currentStep = 1;
    }

    const roleCheckResult = this.auth.checkRoleAccess(this.service.product?.productType, "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.isAuthenticatedForSelfService()) {
      this.dialog.open(DialogAuthComponent, {
        data: {
          state: false,
          initialAction: type,
          selfserviceState: this.selfserviceState,
        },
      });
    } else {
      this.selfserviceState = null;

      // if dialogRef, reuse old instance
      if (this.dialogRef !== undefined && this.dialogRef.componentInstance !== null) {
        this.dialogRef.componentInstance.type = type;
        this.dialogRef.componentInstance.subscription = this.service;
        this.dialogRef.componentInstance.instance = this.activeTab.item;
        this.dialogRef.componentInstance.sapIndex = this.activeTabIndex;
        this.dialogRef.componentInstance.selectedPrefix = selectedPrefix;
        this.dialogRef.componentInstance.currentStep = currentStep;
        this.dialogRef.componentInstance.loadingData = false;
      } else {
        this.dialogRef = this.dialog.open(DialogSelfserviceComponent, {
          data: {
            type,
            subscription: this.service,
            instance: this.activeTab.item,
            sapIndex: this.activeTabIndex,
            selectedPrefix,
            currentStep,
          },
        });
      }

      this.dialogRef.componentInstance.close.subscribe((event: string) => {
        this.bus.emit(event);
      });
    }
  }
}
