import { gql } from "apollo-angular";
import { ProductFragment, SubscriptionDetailsFragment } from "../fragments/fragments";

export const WIRELESS_WITH_ACCESSPOINT_QUERY = gql`
  ${SubscriptionDetailsFragment}
  ${ProductFragment}
  query subscriptionDetails($id: ID!) {
    subscription(id: $id) {
      ... on SurfWirelessSubscription {
        ...SubscriptionDetails
        ...Product

        wifiLocation {
          apVendorName
          sensorPresent
          detail {
            address
            institute
            name
            city
            country
            accessPoints {
              model
              ciStatus
              firmwareVersion
              key
              serialNumber
              brand
              name
              supplier
            }
          }
        }
      }
    }
  }
`;
