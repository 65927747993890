import { Component, ViewChild, ElementRef, Input, SimpleChanges, OnChanges } from "@angular/core";
import { Apollo } from "apollo-angular";
import { WIRELESS_WITH_ACCESSPOINT_QUERY } from "../../../graphql/component/wireless-with-accesspoint";
import { InstanceFactory } from "../../models/base/instancefactory";

@Component({
  selector: "access-points",
  templateUrl: "access-points.html",
  styleUrls: ["access-points.scss"],
})
export class AccessPointsComponent implements OnChanges {
  @Input() subscriptionId = "";
  @ViewChild("scrollarea") scrollarea: ElementRef;
  rightDisabled = true;
  leftDisabled = true;
  accesspoints: any = [];

  constructor(private apollo: Apollo) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscriptionId"]) {
      this.getNewData();
    }
  }

  getNewData() {
    if (!this.subscriptionId) {
      return;
    }

    this.apollo
      .watchQuery<any>({
        fetchPolicy: "no-cache",
        query: WIRELESS_WITH_ACCESSPOINT_QUERY,
        variables: {
          id: this.subscriptionId,
        },
      })
      .valueChanges.subscribe(({ data }) => {
        let accesspointCounts = [];
        const wireless = InstanceFactory.create(data.subscription);
        wireless.location.access_points.forEach((accesspoint: any) => {
          let brand = accesspoint.brand;
          let model = accesspoint.model;
          let status = accesspoint.ci_status;
          if (accesspointCounts[brand] === undefined) {
            accesspointCounts[brand] = [];
          }
          if (accesspointCounts[brand][model] === undefined) {
            accesspointCounts[brand][model] = [];
            accesspointCounts[brand][model]["total"] = 0;
          }

          if (accesspointCounts[brand][model][status] === undefined) {
            accesspointCounts[brand][model][status] = 1;
          } else {
            accesspointCounts[brand][model][status] += 1;
          }
          accesspointCounts[brand][model]["total"] += 1;
        });

        for (let brand in accesspointCounts) {
          for (let model in accesspointCounts[brand]) {
            this.accesspoints.push({
              brand,
              model,
              active: accesspointCounts[brand][model]["Active"] ? accesspointCounts[brand][model]["Active"] : "-",
              inactive: accesspointCounts[brand][model]["Stopped"] ? accesspointCounts[brand][model]["Stopped"] : "-",
              total: accesspointCounts[brand][model]["total"] ? accesspointCounts[brand][model]["total"] : "-",
            });
          }
        }

        setTimeout(() => {
          this.checkScroll();
        }, 500);
      });
  }

  scrollLeft() {
    this.scrollarea.nativeElement.scrollLeft -= 150;

    setTimeout(() => {
      this.checkScroll();
    }, 500);
  }

  scrollRight() {
    this.scrollarea.nativeElement.scrollLeft += 150;
    setTimeout(() => {
      this.checkScroll();
    }, 500);
  }

  checkScroll() {
    this.leftDisabled = this.scrollarea.nativeElement.scrollLeft === 0;

    let newScrollLeft = this.scrollarea.nativeElement.scrollLeft;
    let width = this.scrollarea.nativeElement.clientWidth;
    let scrollWidth = this.scrollarea.nativeElement.scrollWidth;
    this.rightDisabled = scrollWidth - (newScrollLeft + width) === 0;
  }
}
