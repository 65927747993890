import { Component, EventEmitter, Output, Input, SimpleChanges, OnChanges } from "@angular/core";
import * as pMap from "p-map";
import { AuthService } from "../../../services/AuthService";
import { ModifyErrorDetail, ModifyResponse } from "../../../helpers/self-service/models/types";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { PortInstance } from "../../../components/models/portinstance";
import { ServicePort } from "./ServicePort";
import { ModifyL2VPNAddPort } from "../../../helpers/self-service/modify_l2vpn";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ApiHelper } from "../../../helpers/apihelper";
import { HttpErrorResponse } from "@angular/common/http";
import { isValidVlan } from "../../../helpers/self-service/vlanhelper";
import { getSortableId } from "../../../helpers/subscription/stringsort";

@Component({
  selector: "selfservice-add-port",
  templateUrl: "selfservice-add-port.html",
  styleUrls: ["selfservice-add-port.scss", "../selfservice-loader/selfservice-loader.scss"],
})
export class SelfserviceAddPortComponent implements OnChanges {
  @Input() currentStep = 1;
  @Output() dialogCloseEmitter = new EventEmitter<string>();
  @Input() subscription;
  @Input() bus: EventEmitter<any>;

  public hasErrors = false;
  public errors: ModifyErrorDetail[];
  public buttonLoading = false;
  public loadingData = false;
  public summaryItemOneExpanded = true;
  public summaryItemTwoExpanded = false;
  public availablePorts: ServicePort[] = [];
  private processId: string;

  public constructor(
    public auth: AuthService,
    public subscriptionService: SubscriptionService,
    public api: ApiHelper,
  ) {}

  get selectedPorts(): ServicePort[] {
    return this.availablePorts.filter((port) => port.selected === true);
  }

  get portsInSubscription(): string[] {
    const ids = [];
    this.subscription.esis.map((esi) => {
      if (esi.endpoints) {
        esi.endpoints.map((endpoint) => {
          ids.push(endpoint.port.subscriptionId);
        });
      }
    });
    return ids;
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    if (changes["subscription"]) {
      this.loadingData = true;

      this.subscriptionService
        .getSubscriptionsOfType("Port")
        .then((data: any[]) => this.loadPortDetails(data))
        .catch((err) => {
          console.log(err);
        });
    }
  }

  async loadPortDetails(ports: any[]) {
    const usedPorts = this.portsInSubscription;
    // Don't include ports already used by this subscription and ports that are out-of-sync
    const availablePorts = ports.filter((p) => !usedPorts.includes(p.subscriptionId) && p.insync);
    const mapper = async (port) => await this.subscriptionService.getSubscriptionDetails("Port", port.subscriptionId);
    const detailedPorts = await pMap(availablePorts, mapper, {
      concurrency: 2,
    });

    detailedPorts.map((port) => this.addPortIfValid(port));

    // this.availablePorts = detailedPorts;
    this.loadingData = false;
  }

  validVlan(port: ServicePort, vlanString: string) {
    return isValidVlan(port, vlanString);
  }

  addPortIfValid(port: PortInstance) {
    const attachedServiceTypes = port.services.map((service) => service.product.productType);
    console.log("port mode", port.tags);
    if (
      // old business logic that self service can not be done on port used by IP/FW due to portfolio discussion
      !port.tags.includes("Untagged") &&
      !port.tags.includes("Link Member") &&
      !port.tags.includes("MSC") &&
      !attachedServiceTypes.includes("IP") &&
      !attachedServiceTypes.includes("Firewall")
    ) {
      const usedVlans = port.services
        .map((service) => service.vlanrange)
        .filter((vlanrange) => vlanrange !== null)
        .sort((a: string, b: string) => getSortableId(a) - getSortableId(b));

      const extendedPort = {
        ...port,
        selected: false,
        usedVlans,
      } as ServicePort;
      this.availablePorts.push(extendedPort);
    }
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  toggleSummaryItemOne() {
    this.summaryItemOneExpanded = !this.summaryItemOneExpanded;
  }

  toggleSummaryItemTwo() {
    this.summaryItemTwoExpanded = !this.summaryItemTwoExpanded;
  }

  prev() {
    this.currentStep--;
  }

  next() {
    // reset error messages
    this.errors = [];
    this.hasErrors = false;

    this.currentStep++;
  }

  submit() {
    this.buttonLoading = true;
    const modify = new ModifyL2VPNAddPort();
    const command = new SelfServiceCommand(this.subscription.subscriptionId, SelfServiceCommandKeys.ModifyL2VpnAddPort);
    // set default values (current)
    modify.subscription = this.subscription;

    const esis_to_add = this.selectedPorts.map((port: ServicePort) => [
      {
        subscription_id: port.subscriptionId,
        vlan: port.vlan,
      },
    ]);
    // Fill in only the ESIs we want to add
    modify.setEsis(esis_to_add);
    command.payload = modify;

    // reset error div trigger
    this.hasErrors = false;

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.processId = (response as ModifyResponse).pid;
        this.bus.emit({
          processId: this.processId,
          action: SelfServiceCommandKeys.ModifyL2VpnAddPort,
        });
      })
      .catch((err) => {
        this.onError(err);
        this.buttonLoading = false;
      });
  }

  onError(err: HttpErrorResponse) {
    try {
      this.errors = err.error.detail as ModifyErrorDetail[];
      this.hasErrors = true;
    } catch (e) {
      console.log("unknown error", err);
    }
  }
}
