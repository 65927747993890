import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from "@angular/core";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { ModifyDdosFilterCommand } from "../../../helpers/self-service/modify_ddos_filter";
import { ApiHelper } from "../../../helpers/apihelper";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { Router } from "@angular/router";
import { ModifyResponse, ModifyErrorResponse, ModifyErrorDetail } from "../../../helpers/self-service/models/types";
import { transformError } from "../../../helpers/self-service/transform-error";

@Component({
  selector: "selfservice-ddos",
  templateUrl: "selfservice-ddos.html",
  styleUrls: ["selfservice-ddos.scss"],
})
export class SelfserviceDdosComponent implements OnChanges {
  @Output() dialogCloseEmitter = new EventEmitter<string>();
  @Input() subscription;
  @Input() bus: EventEmitter<any>;
  public filterStatus: boolean;
  public processId: string;
  public errorMessage = null;
  public buttonLoading = false;
  public hasErrors = false;
  public errors: ModifyErrorDetail[];

  constructor(public api: ApiHelper) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"] && this.subscription) {
      this.filterStatus = this.subscription.ipss.surfcertFilter === "enabled";
    }
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  submit() {
    this.buttonLoading = true;
    const modify = new ModifyDdosFilterCommand(!this.filterStatus);
    const command = new SelfServiceCommand(this.subscription.subscriptionId, SelfServiceCommandKeys.ModifyDdosFilter);
    command.payload = modify;
    console.log(command.json);

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.processId = (response as ModifyResponse).pid;
        this.bus.emit({
          processId: this.processId,
          action: SelfServiceCommandKeys.ModifyDdosFilter,
        });
      })
      .catch((err) => {
        this.buttonLoading = false;
        console.log(err);
        this.errors = transformError(err.error.detail);
        this.hasErrors = true;
      });
  }
}
