import { Component, Input, OnInit } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { ActivatedRoute } from "@angular/router";
import { GenericSubscriptionComponent } from "../generic-subscription";
import { L3vpnSubscriptionDetailsGQL, L3vpnSubscriptionDetailsQuery } from "../../../../gql/generated-ingestor";
import { L3vpnSubscriptionDetails } from "../../types";
import { ApolloQueryResult } from "@apollo/client";

@Component({
  selector: "l3vpn-subscription",
  templateUrl: "l3vpn-subscription.html",
})
export class L3vpnSubscriptionComponent extends GenericSubscriptionComponent<
  L3vpnSubscriptionDetailsGQL,
  L3vpnSubscriptionDetailsQuery,
  L3vpnSubscriptionDetails
> {
  @Input() isTerminated: boolean;

  // possibly move these to a superclass for these components?
  public impactSetting = "Never";

  constructor(
    public auth: AuthService,
    protected api: ApiHelper,
    protected route: ActivatedRoute,
    protected query: L3vpnSubscriptionDetailsGQL,
  ) {
    super(api, route);
  }

  protected onQuerySuccess(result: ApolloQueryResult<L3vpnSubscriptionDetailsQuery>) {
    return result.data.l3vpnDetails;
  }
}
