import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "../../../services/AuthService";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { PortInstance } from "../../models/portinstance";
import { Instance } from "../../models/instance";

@Component({
  selector: "dialog-ip-prefixes",
  templateUrl: "dialog-ip-prefixes.html",
  styleUrls: ["../dialog-affected-services/dialog-affected-services.scss"],
})
export class DialogIPPrefixesComponent {
  public editModus = false;
  private customerId = "";

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      editModus: any;
      subscriptions;
      linkedSubscriptions;
    },
    public dialogRef: MatDialogRef<DialogIPPrefixesComponent>,
    public router: Router,
    public auth: AuthService,
    public subscriptionService: SubscriptionService,
  ) {
    this.customerId = this.auth.state.viewingCustomer.customerId;
    if (data.editModus === true) {
      this.editModus = true;
    }
  }

  isAvailable(instanceData) {
    const instance = Object.assign(new Instance(), instanceData);
    return this.subscriptionService.canViewSubscription(instance);
  }

  closeDialog(): void {
    this.dialogRef.close({ forceScrollTop: false });
  }

  segueToService(subscription_id: any) {
    if (this.editModus) {
      this.router.navigate([`/subscription/IP/${subscription_id}`], {
        state: { action: "addPrefix", selectedPrefix: this.data.subscriptions },
      });
    } else {
      this.router.navigate([`/subscription/${subscription_id}`]);
    }

    this.dialogRef.close({ forceScrollTop: true });
  }

  presentableName(subscription) {
    if (subscription.customerDescriptions && subscription.customerDescriptions[this.customerId]) {
      return subscription.customerDescriptions[this.customerId];
    }

    return subscription.customerDescription || subscription.description;
  }
}
