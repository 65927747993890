import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { DialogActivityComponent } from "../../../components/dialogs/dialog-activity/dialog-activity";
import { DialogTicketsComponent } from "../../../components/dialogs/dialog-tickets/dialog-tickets";
import { AccessRules, SuperUserRoles } from "../../../helpers/userroles";
import { AuthService } from "../../../services/AuthService";
import { ENV } from "../../../app/app.runtime";

@Component({
  selector: "service-tile-replicated",
  templateUrl: "service-tile.html",
  styleUrls: ["service-tile.scss"],
})
export class ServiceTileReplicationComponent implements OnChanges {
  @Input() serviceUrl: string;
  @Input() serviceMessage: any;
  @Input() serviceMessages;
  @Input() wirelessEnabled: boolean;
  @Input() serviceTitle = "";
  @Input() serviceIcon = "";
  @Input() healthIndicators: Map<string, number>;
  @Input() isLoading: boolean;
  @Input() firewallCount = 0;
  @Input() subscriptionCount = 0;
  @Input() serviceMessageAmount = 0;
  @Input() vulnerabilitiesDetectedCount = 0;

  public hideLoader = false;

  public colors: any = {
    ok: "green",
    notfound: "orange",
    notraffic: "grey",
    pending: "orange",
    initial: "orange",
    stopped: "red",
    unknown: "grey",
    malfunction: "grey",
  };

  private rules = AccessRules;

  constructor(
    public dialog: MatDialog,
    private auth: AuthService,
    public router: Router,
  ) {}

  get requiredRoles() {
    const requirements = this.rules[this.serviceUrl];
    if (!requirements) {
      return SuperUserRoles;
    }
    return requirements.view;
  }

  get validRoles() {
    const requirements = this.rules[this.serviceUrl];
    if (!requirements) {
      return SuperUserRoles;
    }
    return [...requirements.view, ...requirements.edit];
  }

  get hasAccess(): boolean {
    return this.auth.hasRole(this.validRoles);
  }

  openDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogActivityComponent, {
      data: this.serviceMessage,
    });
    return false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["serviceMessages"]) {
      if (this.serviceUrl === "Wireless" && this.serviceMessages) {
        this.serviceMessageAmount = this.serviceMessages.length;
      }
    }
  }

  openTicketsDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogTicketsComponent, {
      data: {
        serviceMessages: this.serviceMessages,
      },
    });
    return false;
  }

  serviceRouterLink() {
    switch (this.serviceUrl) {
      case "IP_PREFIX":
        return "/ip-prefixes";
      case "cert":
        return "/cert";
      case "domains":
        return "/domains/domains-zones";
      default:
        return `/diensten/${this.serviceUrl}`;
    }
  }

  navigateTo() {
    if (this.hasAccess) {
      const destination = this.serviceRouterLink();
      this.router.navigate([this.serviceRouterLink()]);
      // .then((v) => {
      //   console.log(`Navigation result: ${v}`);
      // })
      // .catch((err) => {
      //   console.log(`There was an issue while navigating to ${destination}`, err);
      // });
    } else {
      this.auth.roleEvent.emit({
        event: "requirements-not-met",
        need: this.requiredRoles,
        productType: this.serviceUrl,
      });
    }
  }
}
