<header class="cert-header">
  <div class="row">
    <div class="col-xs-12 col-lg-6">
      <h3>{{ 'Cert.PageTitle' | translate }}</h3>
      <p>{{ 'Cert.PageDescription' | translate }}</p>
      <br>
      <h3>{{ 'Cert.PageSubTitle1' | translate }}</h3>
      <p>{{ 'Cert.PageDescription1' | translate }}</p>
      <br>
      <h3>{{ 'Cert.PageSubTitle2' | translate }}</h3>
      <p>{{ 'Cert.PageDescription2' | translate }}<a href="https://wiki.surfnet.nl/display/SURFcert/DDoS-bescherming">https://wiki.surfnet.nl/display/SURFcert/DDoS-bescherming</a></p>
    </div>
    <div class="col-xs-12 col-lg-5 offset-lg-1">
      <div class="cert-header__warning">
        <h3>{{ 'Cert.WarningTitle' | translate }}</h3>
        <p>{{ 'Cert.WarningDescription' | translate }}</p>
      </div>
    </div>
  </div>
</header>
