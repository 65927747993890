// This file informs Angular that there is a global variable called ENV
// available and that it is structured as below. The actual contents are
// provided by assets/env.js (when not inside nginx) or nginx (when served
// by nginx, see default.conf).
export const ENV: {
  DOMAINS_ENABLED: boolean;
  FILTER_ACTIVE_MESSAGES: boolean;
  NWD_API_BASE_URL: string;
  REPLICATION_ENABLED: boolean;
  WIRELESS_ENABLED: boolean;
  SELFSERVICE_ENABLED: boolean;
} = (window as any).ENV;

export const apiUrl = (path: string): string => ENV.NWD_API_BASE_URL + path;
