<div class="dialog">
  <div class="dialog__header">
    <h3 class="dialog__title">
      {{ title }}
      <span *ngIf="titleSuffix" class="dialog__title-suffix"
        >{{ titleSuffix }}</span
      >
    </h3>
    <svg-icon
      src="assets/images/icons/x.svg"
      class="dialog__close"
      (click)="closeDialog('refresh')"
    ></svg-icon>
  </div>

  <div *ngIf="loadingData" class="dialog__body dialog__body--scroll dialog__body--flex-justify dialog__body--no-padding dialog__body--grow dialog__body--grey">
    <div>
      <loader *ngIf="loadingData"></loader>
    </div>
  </div>

  <div *ngIf="!loadingData && subscription && !auth.isCurrentOrganisation(subscription.customerId); else allowed">
    No access
  </div>

  <ng-template #allowed>

  <selfservice-unavailable
    *ngIf="subscription && !subscription?.insync"
    class="dialog__grow-wrapper"
  ></selfservice-unavailable>

  <selfservice-ddos
    *ngIf="!loader && data.type === 'ddos' && subscription?.insync"
    class="dialog__grow-wrapper"
    (dialogCloseEmitter)="closeDialog($event)"
    [subscription]="subscription"
    [bus]="communication"
  ></selfservice-ddos>

  <selfservice-rps
    *ngIf="!loader && data.type === 'rps' && subscription?.insync"
    class="dialog__grow-wrapper"
    (dialogCloseEmitter)="closeDialog($event)"
    [subscription]="subscription"
    [bus]="communication"
  ></selfservice-rps>

  <selfservice-sp
    *ngIf="!loader && data.type === 'sp' && subscription?.insync"
    class="dialog__grow-wrapper"
    (dialogCloseEmitter)="closeDialog($event)"
    [subscription]="subscription"
    [bus]="communication"
  ></selfservice-sp>

  <selfservice-bfd
    *ngIf="!loader && data.type === 'bfd' && subscription?.insync"
    class="dialog__grow-wrapper"
    (dialogCloseEmitter)="closeDialog($event)"
    [subscription]="subscription"
    [instance]="instance"
    [sapIndex]="sapIndex"
    [bus]="communication"
  ></selfservice-bfd>

  <selfservice-bgp
    *ngIf="!loader && data.type === 'bgp' && subscription?.insync"
    class="dialog__grow-wrapper"
    (dialogCloseEmitter)="closeDialog($event)"
    [subscription]="subscription"
    [bus]="communication"
  ></selfservice-bgp>

  <selfservice-ip-prefixes
    *ngIf="!loader && data.type === 'ip-prefixes' && subscription?.insync"
    class="dialog__grow-wrapper"
    (dialogCloseEmitter)="closeDialog($event)"
    [subscription]="subscription"
    [sapIndex]="sapIndex"
    [bus]="communication"
    [currentStep]="data.currentStep"
    [selectedPrefix]="data.selectedPrefix"
  ></selfservice-ip-prefixes>

  <selfservice-ddos-auto-mitigation
    *ngIf="!loader && !success && data.type === 'autoMitigation' && subscription?.insync"
    class="dialog__grow-wrapper"
    (dialogCloseEmitter)="closeDialog($event)"
    [subscription]="subscription"
    [bus]="communication"
    [mitigationState]="data.mitigationState"
  ></selfservice-ddos-auto-mitigation>

  <selfservice-add-port
    *ngIf="!loader && data.type === 'add-port' && subscription?.insync"
    class="dialog__grow-wrapper"
    [subscription]="subscription"
    [bus]="communication"
  ></selfservice-add-port>

  <selfservice-remove-port
    *ngIf="!loader && data.type === 'remove-port' && subscription?.insync"
    class="dialog__grow-wrapper"
    [subscription]="subscription"
    [bus]="communication"
  ></selfservice-remove-port>

  <selfservice-vlan-lightpath
    *ngIf="!loader && data.type === 'vlan-lightpath' && subscription?.insync"
    class="dialog__grow-wrapper"
    [circuit]="circuit"
    [subscription]="subscription"
    [bus]="communication"
  ></selfservice-vlan-lightpath>

  <selfservice-vlan-l2vpn
    *ngIf="!loader && data.type === 'vlan-l2vpn' && subscription?.insync"
    class="dialog__grow-wrapper"
    [subscription]="subscription"
    [bus]="communication"
  ></selfservice-vlan-l2vpn>

  <selfservice-loader
    *ngIf="loader && !success"
    class="dialog__grow-wrapper"
    [processId]="processId"
    [bus]="communication"
  ></selfservice-loader>

  <selfservice-success
    *ngIf="success"
    class="dialog__grow-wrapper"
  ></selfservice-success>
  </ng-template>
</div>
