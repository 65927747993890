import { Input, Component, OnChanges, SimpleChanges, EventEmitter } from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogSelfserviceComponent } from "../../dialogs/dialog-selfservice/dialog-selfservice";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { LightPathSubscriptionDetails } from "../../../pages/subscription-detail-replicated/types";
import { HealthIndicator } from "../../models/HealthIndicator";

@Component({
  selector: "connection-replicated",
  templateUrl: "connection.html",
})
export class ConnectionReplicationComponent implements OnChanges {
  @Input() subscription: LightPathSubscriptionDetails;
  @Input() circuit: LightPathSubscriptionDetails["circuits"][0];
  @Input() bus: EventEmitter<string>;
  @Input() demoShowVisual = false;
  @Input() connectionNumber = 1;
  @Input() productType: string;
  @Input() healthData: HealthIndicator[] = [];

  public overflowMenuActive = false;

  constructor(
    public auth: AuthService,
    public dialog: MatDialog,
    private subscriptionService: SubscriptionService,
  ) {}

  // setting this here to keep the template readable.
  get isSelfServiceAvailable(): boolean {
    return !this.somePortsAreUntagged;
  }

  get somePortsAreUntagged() {
    return this.circuit.endpoints.some((endpoint) => endpoint.port.portMode === "Untagged");
  }

  ngOnChanges(changes: SimpleChanges) {
    ["vlan-lightpath"].forEach(async (type) => {
      if (this.auth.hasPendingStrongAction(type)) {
        // We've set the connectionnumber in the saved state.
        // Check if it is there and only open the dialog for
        // the correct one.
        // On failure, open the first connection we see, to make
        // sure the user sees expected behaviour.
        try {
          const ssState = this.auth.getSelfserviceState();
          if (ssState.connectionNumber === this.connectionNumber) {
            this.startDialog(type);
          }
        } catch (e) {
          console.error("Expected to find selfservice state in auth service, but got", e);
          this.startDialog(type);
        }
      }

      let promises = this.circuit.endpoints.map((endpoint) =>
        this.subscriptionService.getHealthDataForSubscription(endpoint.port.subscriptionId),
      );
      this.healthData = await Promise.all(promises);
    });
  }

  startDialog(type) {
    this.auth.clearPendingStrongAction(type);
    this.openSelfserviceDialog(type);
  }

  openSelfserviceDialog(type) {
    const roleCheckResult = this.auth.checkRoleAccess(this.productType, "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.isAuthenticatedForSelfService()) {
      this.dialog.open(DialogAuthComponent, {
        data: {
          selfserviceState: { connectionNumber: this.connectionNumber },
          initialAction: type,
        },
      });
    } else {
      const dialogRef = this.dialog.open(DialogSelfserviceComponent, {
        data: {
          type,
          circuit: this.circuit,
          subscription: this.subscription,
          titleSuffix: this.connectionNumber,
        },
      });

      dialogRef.componentInstance.close.subscribe((event: string) => {
        this.bus.emit(event);
      });
    }
  }
}
