import { gql } from "apollo-angular";
import { IpPrefixSubscriptionFragment } from "../fragments/ip-prefix-subscription";

export const CERT_MITIGATION_PREFIXES_QUERY = gql`
  ${IpPrefixSubscriptionFragment}
  query CertMitigationPrefixes($customer: String!, $products: [String!], $status: [String!]) {
    customer(organisationId: $customer) {
      id: uuid
      subscriptions(productsIncludedFilter: $products, statusFilter: $status) {
        customerId
        ...IpPrefixSubscriptionDetails
      }
    }
  }
`;
