import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import * as pMap from "p-map";
import { SelfServiceCommand } from "../../../helpers/self-service/self-service-command";
import { ApiHelper } from "../../../helpers/apihelper";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ModifyErrorDetail, ModifyResponse } from "../../../helpers/self-service/models/types";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { enumeratedVlans, isValidVlan } from "../../../helpers/self-service/vlanhelper";
import { ModifyLightPath } from "../../../helpers/self-service/modify_lightpath";
import { HttpErrorResponse } from "@angular/common/http";
import { ServicePort } from "../selfservice-add-port/ServicePort";
import { ModifyL2VPN, ModifyL2VPNVlan } from "../../../helpers/self-service/modify_l2vpn";
import { Instance } from "../../models/instance";
import { AuthService } from "../../../services/AuthService";
import { getSortableId } from "../../../helpers/subscription/stringsort";
import { transformError } from "../../../helpers/self-service/transform-error";

@Component({
  selector: "selfservice-vlan-l2vpn",
  templateUrl: "selfservice-vlan-l2vpn.html",
  styleUrls: ["../selfservice-add-port/selfservice-add-port.scss"],
})
export class SelfserviceVlanL2vpnComponent implements OnInit, OnChanges {
  @Input() currentStep = 1;
  @Output() dialogCloseEmitter = new EventEmitter<string>();
  @Input() subscription;
  @Input() bus: EventEmitter<any>;
  public hasErrors = false;
  public errors: ModifyErrorDetail[] = [];
  public buttonLoading = false;
  public loadingData = false;
  public esis: any[] = [];
  public processId: string;

  constructor(
    public api: ApiHelper,
    public subscriptionService: SubscriptionService,
    public auth: AuthService,
  ) {}

  ngOnInit() {
    this.loadingData = true;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"]) {
      this.loadingData = true;

      this.esis = this.subscription.esis.map((esi) => {
        if (esi.endpoints) {
          return {
            ...esi,
            vlan: esi.endpoints[0].vlanrange,
          };
        }
        return esi;
      });
      this.loadPortDetails();
    }
  }

  async loadPortDetails() {
    for (const esi of this.esis) {
      if (esi.endpoints) {
        for (let e = 0; e < esi.endpoints.length; e++) {
          const endpoint = esi.endpoints[e];
          const portdata = await this.subscriptionService.getSubscriptionDetails("Port", endpoint.port.subscriptionId);
          const usedVlans = portdata.services
            .map((service) => service.vlanrange)
            .filter((v: string) => v !== null && v !== endpoint.vlanrange)
            .sort((a: string, b: string) => getSortableId(a) - getSortableId(b));

          esi.endpoints[e] = {
            ...endpoint,
            port: { ...portdata, usedVlans },
            vlan: "",
          };
        }
      }
    }

    this.loadingData = false;
  }

  usedVlansOnEsi(esiIndex): string[] | Set<string> {
    if (this.esis[esiIndex].endpoints.length === 1) {
      return this.esis[esiIndex].endpoints[0].port.usedVlans;
    }

    const collectedVlans = [];
    this.esis[esiIndex].endpoints.map((e) => collectedVlans.push(...e.port.usedVlans));
    return new Set(collectedVlans.sort((a: string, b: string) => getSortableId(a) - getSortableId(b)));
  }

  errorsAtIndex(index: number): boolean {
    let hasErrors = false;
    this.errors.map((error: ModifyErrorDetail) => {
      if (error.loc.length > 1 && Number(error.loc[1]) === index) {
        hasErrors = true;
      }
    });
    return hasErrors;
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  prev() {
    this.currentStep--;
  }

  next() {
    // reset error messages
    this.errors = [];
    this.hasErrors = false;

    this.currentStep++;
  }

  validVlan(port: ServicePort, vlanString: string, currentVlan: string) {
    return vlanString === currentVlan || isValidVlan(port, vlanString);
  }

  /**
   * Quick check to see if the vlans match.
   * This is only important for values with
   * multiple vlans (1,2,3 or 1-3).
   * In case of single vlans, just return true
   * since checking for those is done in validVlan()
   *
   * @returns boolean
   */
  vlanMatches() {
    if (this.loadingData) {
      return false;
    }
    let onlySingles = true;
    const check: Set<string> = new Set(
      this.esis.map((esi) => {
        if (esi.vlan && esi.vlan.match(/[^0-9]/)) {
          onlySingles = false;
        }
        return esi.vlan;
      }),
    );
    return onlySingles ? true : check.size === 1;
  }

  submit() {
    this.buttonLoading = true;
    const modify = new ModifyL2VPNVlan();
    const command = new SelfServiceCommand(this.subscription.subscriptionId, SelfServiceCommandKeys.ModifyL2VpnVlan);
    // set default values (current)
    modify.subscription = this.subscription;

    const esis = this.esis
      .map((esi) => {
        if (esi.endpoints) {
          return esi.endpoints.map((endpoint) => {
            const vlan = !esi.vlan || esi.vlan === "" ? endpoint.vlanrange : esi.vlan;
            return {
              vlan,
              subscription_id: endpoint.port.subscriptionId,
            };
          });
        }
        return esi;
      })
      .filter((sub) => sub.firewallInterconnect === undefined);

    modify.setEsis(esis);
    command.payload = modify;
    console.log(command);

    // reset error div trigger
    this.hasErrors = false;

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.processId = (response as ModifyResponse).pid;
        this.bus.emit({
          processId: this.processId,
          action: SelfServiceCommandKeys.ModifyL2VpnVlan,
        });
      })
      .catch((err) => {
        this.onError(err);
        this.buttonLoading = false;
      });
  }

  onError(err: HttpErrorResponse) {
    try {
      this.errors = transformError(err.error.detail);
      this.hasErrors = true;
    } catch (e) {
      console.log("unknown error", err);
    }
  }

  isMyPort(port: ServicePort): boolean {
    return port.customerId === this.auth.state.viewingCustomer.customerId;
  }
}
