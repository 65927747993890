import { Directive } from "@angular/core";

@Directive()
export class TopologyObject {
  static ESI = "ESI";
  static VC = "VC";
  static SAP = "SAP";
  static PORT = "PORT";
  static MSC = "MSC";
  static L2ENDPOINT = "L2ENDPOINT";
  static L3ENDPOINT = "L3ENDPOINT";
  static INTERNET = "INTERNET";
  static L2VPN = "L2VPN";
  static L3VPN = "L3VPN";
  static FIREWALL = "FIREWALL";

  topologyObjectType: string;
  otherSubscriptionId: string;
  subscriptionInstanceId: string;
  vlanrange: string;
  customerDescription: string;

  constructor(
    subscriptionInstanceId: string,
    topologyObjectType: string,
    otherSubscriptionId?: string,
    vlanrange?: string,
    customerDescription?: string,
  ) {
    this.subscriptionInstanceId = subscriptionInstanceId;
    this.topologyObjectType = topologyObjectType;
    this.otherSubscriptionId = otherSubscriptionId;
    this.vlanrange = vlanrange;
    this.customerDescription = customerDescription ?? topologyObjectType;
  }
}
