import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import * as Highcharts from "highcharts";
import { ApiHelper } from "../../../helpers/apihelper";
import { MessageService } from "../../../services/messageservice";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { SUBSCRIPTION_DETAIL_QUERY, SUBSCRIPTION_QUERY } from "../../../graphql/domain/subscription";
import { TOPOLOGY_QUERY } from "../../../graphql/domain/topology";
import { Apollo } from "apollo-angular";
import { Subscription } from "rxjs";
import { TopologyFactory, TopologyNode } from "../../models/base/topologyfactory";
import { TopologyObject } from "../../models/topologyObject";

@Component({
  selector: "networkgraph",
  templateUrl: "networkgraph.html",
})
export class NetworkgraphComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() subscriptionId: string;
  @ViewChild("chartContainer", { static: true }) chartContainer: ElementRef;
  highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = this.chartInfo; // required
  private chartRef: any;
  private loading = false;
  private subscription: TopologyObject;
  private graphqlTopology: Subscription;
  private subscriptionType: string;

  constructor(
    public translate: TranslateService,
    private api: ApiHelper,
    private msgsrv: MessageService,
    public dialog: MatDialog,
    public router: Router,
    private apollo: Apollo,
  ) {}

  get chartInfo(): Highcharts.Options {
    const self = this;
    return {
      chart: {
        type: "networkgraph",
        height: 800,
        backgroundColor: "transparent",
        style: {
          fontFamily: "Proxima",
        },
      },
      lang: {
        noData: "Retrieving data...",
      },
      title: {
        text: null,
      },
      plotOptions: {
        networkgraph: {
          dataLabels: {
            enabled: false,
          },
          keys: ["from", "to"],
          draggable: false,
          layoutAlgorithm: {
            enableSimulation: true,
            maxSpeed: 2000,
            maxIterations: 200,
            linkLength: 24,
          },
          events: {
            click: (e) => {
              if (!e || !e.point) {
                return;
              }

              const subscriptionId = e.point["otherSubscriptionId"] || e.point["id"];
              if (subscriptionId && subscriptionId.length > 0) {
                this.router.navigate([`/subscription/${subscriptionId}`]);
              }
            },
          },
        },
      },
      tooltip: {
        shared: true,
        shadow: false,
        hideDelay: 800,
        outside: true,
        useHTML: true,
        backgroundColor: "transparent",
        borderColor: "transparent",
        formatter(tooltip) {
          const elementId = this.point["id"] || "null";
          const tooltipText = self.makeTooltip(elementId, this.point, tooltip);
          let element = `<div id="graph-callout-${elementId}" class="graph-callout">`;
          element += `<h1>${tooltipText.title}</h1>`;
          if (tooltipText.subtitle) {
            element += `<span>${tooltipText.subtitle}</div>`;
          }
          return element;
        },
      },
      exporting: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [],
    };
  }

  setChartInstance(chart: Highcharts.Chart) {
    this.chartRef = chart;
  }

  ngOnInit() {
    this.addStylingEvent();
  }

  ngAfterViewInit() {
    this.getNewData();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscriptionId"] && this.subscriptionId !== undefined && this.subscriptionId !== null) {
      this.getNewData();
    }
  }

  getNewData() {
    if (!this.subscriptionId || this.loading) {
      return;
    }

    this.loading = true;

    this.graphqlTopology = this.apollo
      .watchQuery<any>({
        fetchPolicy: "no-cache",
        query: TOPOLOGY_QUERY,
        variables: {
          id: this.subscriptionId,
        },
      })
      .valueChanges.subscribe(({ data }) => {
        this.loading = false;
        const chartData = TopologyFactory.create(data.subscription);
        this.subscriptionType = data.subscription.subscriptionType;
        const chartSeriesData = this.prepareChartSeriesData(chartData) || null;
        if (!chartSeriesData) {
          return;
        }

        const existingSeries = this.chartRef.get("firewall-tree");
        if (existingSeries) {
          existingSeries.remove();
        }

        this.chartRef.addSeries({
          id: "firewall-tree",
          type: "networkgraph",
          data: chartSeriesData,
        });

        if (this.chartContainer) {
          this.chartContainer.nativeElement.className = "networkgraph--container__animate";
        }
      });
  }

  makeTooltip(
    elementId: string,
    graphPoint: Highcharts.Point,
    tooltip: Highcharts.Tooltip,
  ): {
    title: string;
    subtitle: string;
  } {
    const title = graphPoint.options["customerDescription"];
    const subtitle = graphPoint.options["vlanrange"];

    return {
      title,
      subtitle: graphPoint.options["vlanrange"] ? `VLAN ${subtitle}` : null,
    };
  }

  addStylingEvent() {
    this.removeStylingEvent();

    Highcharts.addEvent(Highcharts.Series, "afterSetOptions", (e: any) => {
      if (!e || !e.options || e.options.id !== "firewall-tree") {
        return;
      }

      const nodes = {};
      const data = e.options.data || [];
      if (!Array.isArray(data)) {
        return;
      }

      for (const link of data) {
        if (!link.custom) {
          continue;
        }

        const mass = link.custom.from.type === "FIREWALL" ? 100 : link.custom.from.marker.radius;

        if (link.custom.from) {
          nodes[link.from] = {
            ...link.custom.from,
            id: link.from,
            mass,
          };
        }

        if (link.custom.to) {
          nodes[link.to] = {
            ...link.custom.to,
            id: link.to,
            color: link.custom.to.color ? link.custom.to.color : null,
            mass: link.custom.to.marker.radius,
          };
        }
      }

      e.options.nodes = Object.keys(nodes).map((id) => nodes[id]);
    });
  }

  removeStylingEvent() {
    Highcharts.removeEvent(Highcharts.Series, "afterSetOptions");
  }

  prepareChartSeriesData(data) {
    if (!data || data.length === 0) {
      return null;
    }

    const seriesdata = [];
    for (const nodes of data) {
      if (nodes.length < 2) {
        continue;
      }

      const from = nodes[0];
      const to = nodes[1];
      const item: Highcharts.PointOptionsObject = {
        id: from.subscriptionInstanceId || "unknown",
        from: from.subscriptionInstanceId,
        to: to.subscriptionInstanceId,
        name: from.topologyObjectType || "-",
        custom: {
          from: this.stylingForMarker(from),
          to: this.stylingForMarker(to),
        },
      };

      seriesdata.push(item);
    }

    return seriesdata;
  }

  stylingForMarker(node) {
    let radius = 30;

    switch (node.topologyObjectType) {
      case "FIREWALL":
      case "INTERNET":
      case "L2VPN":
      case "L3VPN":
      case "L3ENDPOINT":
        radius = 34;
        break;

      case "ESI":
      case "SAP":
      default:
        radius = 28;
        break;
    }

    if (this.isSelf(node)) {
      radius += 24;
    }

    return {
      color: "yellow",
      name: node.topologyObjectType,
      type: node.topologyObjectType,
      vlanrange: node.vlanrange || "",
      otherSubscriptionId: node.otherSubscriptionId,
      customerDescription: node.customerDescription,
      marker: {
        radius,
        symbol: `url(assets/images/networkgraph/icon-${node.topologyObjectType}.svg)`,
        width: radius * 2,
        height: radius * 2,
      },
    };
  }

  isSelf(node: TopologyNode) {
    if (!node) {
      return false;
    }

    const matches = (currentNode: TopologyNode) => {
      switch (currentNode.topologyObjectType) {
        case "FIREWALL":
          return this.subscriptionType === "FwSubscription";
        case "INTERNET":
          return ["Sn8IpStaticSubscription", "Sn8IpBgpSubscription"].includes(this.subscriptionType);
        case "L2VPN":
          return this.subscriptionType === "Sn8L2VpnSubscription";
        case "L3VPN":
          return this.subscriptionType === "Sn8L3VpnSubscription";
        default:
          return false;
      }
    };
    return node.otherSubscriptionId === null && matches(node);
  }
}
