import { Component, Input, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogFirewallComponent } from "../../../components/dialogs/dialog-firewall/dialog-firewall";
import inputStories from "../../../stories/input.stories";

@Component({
  selector: "page-tab",
  templateUrl: "page-tab.html",
  styleUrls: ["page-tab.scss"],
})
export class PageTabComponent {
  @Input() activeTab = "config";
  @Input() productType: string;
  @Output() activeTabEmitter = new EventEmitter<boolean>();

  constructor(public dialog: MatDialog) {}

  tab(tab: string) {
    this.activeTab = tab;
    this.emitTabState(tab);
  }

  emitTabState(tab) {
    this.activeTabEmitter.emit(tab);
  }
}
