import { Component, EventEmitter, OnInit } from "@angular/core";
import { MatomoTracker } from "ngx-matomo";
import { SubscriptionService } from "../../services/subscriptionservice";
import { AuthService } from "../../services/AuthService";
import { ApiHelper } from "../../helpers/apihelper";
import { ActivatedRoute, Router } from "@angular/router";
import * as pMap from "p-map";
import { Instance } from "../../components/models/instance";
import { FulfilmentRequest } from "../../components/models/fulfilmentRequest";
import { DialogSelfserviceComponent } from "../../components/dialogs/dialog-selfservice/dialog-selfservice";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DialogAuthComponent } from "../../components/dialogs/dialog-auth/dialog-auth";
import { DialogIncidentComponent } from "../../components/dialogs/dialog-incident/dialog-incident";
import {
  SUBSCRIPTION_QUERY,
  SUBSCRIPTION_STATUS_QUERY,
  SUBSCRIPTION_TYPE_QUERY,
} from "../../graphql/domain/subscription";
import { Apollo } from "apollo-angular";
import { Subscription } from "rxjs";
import { InstanceFactory } from "../../components/models/base/instancefactory";
import * as dayjs from "dayjs";
import { SurfNotification } from "../../components/models/notification";
import { cimToPlannedWork } from "../../helpers/subscription/planned-work";
import { DialogNotificationSettingsComponent } from "../../components/dialogs/dialog-notification-settings/dialog-notification-settings";
import { FetchPolicy } from "@apollo/client/core";
import { CIM_QUERY } from "../../graphql/domain/cim";
import {
  CimQueryImpactTypes,
  CimQueryStatuses,
  SubscriptionStatusResult,
  SubscriptionTypeResult,
} from "../../graphql/custom-types";

@Component({
  selector: "page-subscription-detail",
  templateUrl: "subscription-detail.html",
})
export class SubscriptionDetailPage implements OnInit {
  // public activities: any[] = [];
  public subscriptionId: string;
  public linkPortSubscription = true;
  public customerGuid: string;
  public productType: string;
  public portSubscriptionName: string;
  public subscription: Instance | null = null;
  public detailData: { title: string; status: string; firewallEnabled: any };
  public splittedPorts: any[] = [];
  public isEditable = false;
  public temporaryName: string;
  public dialogRef: MatDialogRef<MatDialog>;
  public __fulfilmentRequest: FulfilmentRequest;
  public __fulfilmentRequestObject: any;
  public FULFILMENT_SERVICE_REQUEST_PROBLEM: string = FulfilmentRequest.FULFILMENT_SERVICE_REQUEST_PROBLEM;
  public activeTab = "config";
  public bus: EventEmitter<string> = new EventEmitter();
  public wirelessVendor: string | undefined = undefined;
  public wirelessHealthData: any;
  public accesspointCountsData: any;
  public selfserviceState: any;
  public subscriptionTerminated = false;
  public impactSetting = "";

  private graphqlSubscription: Subscription;
  private subscriptionCacheId: string;
  private messages: any[] = [];
  private cimNotifications: SurfNotification[] = [];

  constructor(
    public dialog: MatDialog,
    private matomo: MatomoTracker,
    private subscriptionService: SubscriptionService,
    public auth: AuthService,
    private api: ApiHelper,
    private route: ActivatedRoute,
    private router: Router,
    private apollo: Apollo,
  ) {
    matomo.setDocumentTitle("SubscriptionDetail");
    this.customerGuid = localStorage.getItem("viewingCustomerGUID");
    this.portSubscriptionName = "";

    if (this.router.getCurrentNavigation().extras.state) {
      this.selfserviceState = this.router.getCurrentNavigation().extras.state;
    }
    // use this for testing only.
    // else if (localStorage.getItem("selfservice_state") !== null) {
    //   this.selfserviceState = JSON.parse(localStorage.getItem("selfservice_state"));
    // }
  }

  get hasTabs() {
    return ["FW", "L2VPN", "L3VPN", "IP", "LightPath"].includes(this.productType);
  }

  get allPortsAreUntagged() {
    const mapPortModes = this.subscription?._circuits[0].endpoints.map((item) => ({
      portMode: item.port.portMode,
    }));

    if (mapPortModes?.every((item) => item.portMode === "Untagged")) {
      return true;
    }
    return false;
  }

  get allPortsAreNotAggregated() {
    const mapPortTypes = this.subscription?._circuits[0].endpoints.map((item) => ({
      productTag: item.port.product.tag,
    }));

    if (mapPortTypes?.some((item) => item.productTag === "AGGSP")) {
      return false;
    }
    return true;
  }

  get activities() {
    return this.cimNotifications.concat(this.messages).sort((a, b) => a.timestamp - b.timestamp);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.scrollToTop();

      this.auth.userLoaded.subscribe((d) => {
        this.retrieveSubscriptionData();
      });
      if (!this.auth.state.loading) {
        this.retrieveSubscriptionData();
      }
    });

    this.matomo.trackPageView();
    this.bus.subscribe((message: string) => {
      switch (message) {
        case "refresh":
          // evict the cache for the current subscription, to force a refresh.
          // note: this does not really refresh everything.
          // so, until we figure out why an IP service suddenly has no prefixes assigned,
          // we reload the page
          // this.apollo.client.cache.evict({ id: this.subscriptionCacheId });
          // this.retrieveSubscriptionData(true);
          this.router.navigate(["/subscription", this.subscriptionId]);
          break;
      }
    });
  }

  /**
   * Get subscription data
   */
  retrieveSubscriptionData(forceRefresh: boolean = false) {
    this.subscriptionId = this.route.snapshot.paramMap.get("subscriptionId");
    this.productType = this.route.snapshot.paramMap.get("productType");
    if (this.productType === null) {
      // if productType is not set, redirect the user to the new product url
      this.redirectToNewPageStructure();
    } else {
      if (this.selfserviceState && this.selfserviceState.action === "addPrefix") {
        // trigger if selfservice authentication not done yet
        if (!this.auth.isAuthenticatedForSelfService()) {
          this.dialog.open(DialogAuthComponent, {
            data: {
              state: false,
              initialAction: "ip-prefixes",
              selfserviceState: this.selfserviceState,
            },
          });
        }
      }

      // set initial values
      this.subscription = null;
      this.temporaryName = "";
      this.setDetailData("", "loading", false);

      let fetchPolicy: FetchPolicy = "cache-first";
      if (forceRefresh) {
        fetchPolicy = "network-only";
      }

      this.apollo
        .query<SubscriptionStatusResult>({
          query: SUBSCRIPTION_STATUS_QUERY,
          errorPolicy: "all",
          fetchPolicy,
          variables: {
            id: this.subscriptionId,
          },
        })
        .subscribe(({ data }) => {
          if (data?.subscription?.status === "terminated") {
            this.subscriptionTerminated = true;
          }
        });

      this.graphqlSubscription = this.apollo
        .watchQuery<any>({
          query: SUBSCRIPTION_QUERY,
          errorPolicy: "all",
          fetchPolicy,
          variables: {
            id: this.subscriptionId,
          },
        })
        .valueChanges.subscribe(({ data }) => {
          this.subscriptionCacheId = `${data.subscription.__typename}:{"subscriptionId":"${this.subscriptionId}"}`;
          this.fetchCimNotifications();
          this.impactSetting = this.getImpactSetting(data.subscription.minimalImpactNotifications);

          const instance = InstanceFactory.create(data.subscription);
          this.subscription = instance;
          if (instance.status === "terminated") {
            this.subscriptionTerminated = true;
          }

          this.subscriptionService
            .getHealthDataForSubscription(this.subscription.subscriptionId, null, this.subscription.product.type)
            .then((status) => {
              this.setDetailData(
                this.subscription.presentableName.length > 0 ?
                  this.subscription.presentableName
                : this.subscription.description,
                this.subscription.status,
                this.subscription.firewallEnabled,
              );
            });

          this.temporaryName = this.subscription.presentableName;

          if (this.productType === "Wireless") {
            this.subscriptionService
              .getWirelessHealthDataForSubscription(this.subscription.subscriptionId)
              .then((healthData) => {
                this.wirelessHealthData = healthData;
              });
          }

          if (this.productType === "IP" && this.subscription?.ipss?.internetpinnen === true) {
            this.getIpPrefix();
          }

          this.getPortSubscriptionName();

          const requestDialogOpen = this.auth.hasRequestedSelfServiceDialog();
          ["ddos", "sp", "rps"].forEach((type) => {
            if (this.auth.hasPendingStrongAction(type)) {
              this.auth.clearPendingStrongAction(type);
              this.openSelfserviceDialog(type);
            }

            if (requestDialogOpen === type) {
              this.openSelfserviceDialog(type);
            }
          });
        });
      // end new
    }
  }

  fetchCimNotifications = async () => {
    const endDate = dayjs().add(2, "week").toDate();
    this.apollo
      .query<any>({
        query: CIM_QUERY,
        fetchPolicy: "network-only",
        variables: {
          filter: {
            customerId: localStorage.getItem("viewingCustomerGUID"),
            beginTimestamp: new Date(),
            endTimestamp: endDate,
            statuses: [CimQueryStatuses.OPEN, CimQueryStatuses.UPDATED],
            impacts: [
              CimQueryImpactTypes.DOWN,
              CimQueryImpactTypes.REDUCED_REDUNDANCY,
              CimQueryImpactTypes.RESILIENCE_LOSS,
            ],
            subscriptionIds: [this.subscriptionId],
          },
        },
      })
      .subscribe((data) => {
        this.cimNotifications = data.data.notifications
          .map((n) => cimToPlannedWork(n))
          .sort((a, b) => a.start_timestamp - b.start_timestamp);
      });
  };

  setDetailData(title: string, status: string, firewallEnabled: any) {
    this.detailData = { title, status, firewallEnabled };
  }

  /**
   * redirect to product specific url
   * */
  redirectToNewPageStructure() {
    this.apollo
      .query<SubscriptionTypeResult>({
        query: SUBSCRIPTION_TYPE_QUERY,
        errorPolicy: "all",
        fetchPolicy: "cache-first",
        variables: {
          id: this.subscriptionId,
        },
      })
      .subscribe(({ data, errors }) => {
        if (errors && errors[0]?.extensions?.error_type === "not_found") {
          this.router.navigate(["/notfound"]);
        } else if (errors) {
          this.router.navigate(["/error"]);
        }

        const productType = data.subscription.product.type;
        this.router.navigate([`/subscription/${productType}/${this.subscriptionId}`]);
      });
  }

  async getIpPrefix() {
    const mapper = async (pinSubscriptionId) => await this.subscriptionService.getIpPrefixes(pinSubscriptionId);

    const result = await pMap(this.subscription.ipss.internetpinnenPrefixSubscriptions, mapper, { concurrency: 2 });
    this.subscription.ip_prefixes = result;
  }

  async getPortSubscriptionName() {
    if (!this.subscription.portSubscription) {
      return;
    }
    this.subscriptionService.getBaseSubscription(this.subscription.portSubscription).then((result) => {
      const portSubscription = Object.assign(new Instance(), result);
      this.portSubscriptionName = portSubscription.description;
      this.linkPortSubscription = this.subscriptionService.canViewSubscription(portSubscription);
    });
  }

  startEditingCustomerDescription() {
    if (this.subscription.customerDescriptions === null) {
      this.subscription.customerDescriptions = {};
    }
    this.temporaryName = this.subscription.presentableName;
    this.isEditable = true;
  }

  cancelEditingCustomerDescription() {
    this.isEditable = false;
    this.temporaryName = this.subscription.presentableName;
  }

  saveCustomerDescription() {
    if (this.temporaryName.length > 0) {
      this.api
        .set_customer_description(this.customerGuid, this.subscriptionId, this.temporaryName)
        .then((result) => {
          this.isEditable = false;
          this.subscription.updatePresentableName(this.temporaryName);
        })
        .catch((err) => {
          console.log(err);
          this.isEditable = false;
        });
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  /**
   * return to top of the page if a new subscription is selected
   */
  scrollToTop() {
    window.scrollTo(0, 0);
  }

  changeActiveTab(tab) {
    this.activeTab = tab;

    this.retrieveSubscriptionData();
  }

  openSelfserviceDialog(type) {
    const roleCheckResult = this.auth.checkRoleAccess(this.subscription.product?.productType, "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.isAuthenticatedForSelfService()) {
      this.dialog.open(DialogAuthComponent, {
        data: { state: false, initialAction: type },
      });
    } else {
      const ssDialogRef = this.dialog.open(DialogSelfserviceComponent, {
        data: {
          type,
          subscription: this.subscription,
        },
      });
      ssDialogRef.componentInstance.close.subscribe((command) => {
        console.log(`Received '${command}' through close pipe`);

        // evict the cache for the current subscription, to force a refresh.
        this.apollo.client.cache.evict({ id: this.subscriptionCacheId });
        this.retrieveSubscriptionData();
      });
    }
  }

  openNotificationSettingsDialog() {
    const dialogRef = this.dialog.open(DialogNotificationSettingsComponent, {
      data: {
        name: this.subscription.presentableName,
        impactSetting: this.impactSetting,
        subscriptionId: this.subscriptionId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.refresh) {
        this.retrieveSubscriptionData(true);
      }
    });
  }

  openIncidentDialog(event) {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(DialogIncidentComponent, {
      data: {
        subscription: this.subscription,
      },
    });
    return false;
  }

  private getImpactSetting(minimalImpactNotifications) {
    const customerGuid = localStorage.getItem("viewingCustomerGUID");
    let minimalImpactNotification = minimalImpactNotifications.find((el) => el.customerId === customerGuid);

    if (minimalImpactNotification) {
      return minimalImpactNotification.impact;
    }

    return "";
  }
}
