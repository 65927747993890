import { gql } from "apollo-angular";

const L3VpnTopologyFragment = gql`
  fragment L3VpnTopology on SubscriptionInterface {
    ... on Sn8L3VpnSubscription {
      subscriptionId
      customerId
      vc {
        subscriptionInstanceId
        saps {
          subscriptionInstanceId
          name
          title
          sap {
            subscriptionInstanceId
            vlanrange
            ownerSubscriptionId
            port {
              subscriptionId
              description
              customerDescriptions {
                customerId
                description
              }
            }
          }
        }
      }
    }
  }
`;

export { L3VpnTopologyFragment };
