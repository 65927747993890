import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SubscriptionMessage } from "../../../helpers/types";
import { Router } from "@angular/router";

@Component({
  selector: "dialog-activity",
  templateUrl: "dialog-activity.html",
})
export class DialogActivityComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SubscriptionMessage,
    public dialogRef: MatDialogRef<DialogActivityComponent>,
    private router: Router,
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }

  segueToService(affectedService: any) {
    this.router.navigate([`/subscription/${affectedService.subscription_id}`]);
    this.closeDialog();
  }
}
