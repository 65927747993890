import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges } from "@angular/core";
import { SelfServiceCommand, SelfServicePayloads } from "../../../helpers/self-service/self-service-command";
import { ApiHelper } from "../../../helpers/apihelper";
import { SelfServiceCommandKeys } from "../../../helpers/enums/selfServiceCommands";
import { ModifyResponse, ModifyErrorDetail } from "../../../helpers/self-service/models/types";
import { ModifyLightPath, ModifyRedundantLightPath } from "../../../helpers/self-service/modify_lightpath";
import { ModifyL2VPN, ModifyL2VPNSpeedPolicer } from "../../../helpers/self-service/modify_l2vpn";
import { transformError } from "../../../helpers/self-service/transform-error";

@Component({
  selector: "selfservice-sp",
  templateUrl: "selfservice-sp.html",
})
export class SelfserviceSpComponent implements OnChanges {
  @Output() dialogCloseEmitter = new EventEmitter<string>();
  @Input() subscription;
  @Input() bus: EventEmitter<any>;
  public filterStatus: boolean;
  public processId: string;
  public hasErrors = false;
  public errors: ModifyErrorDetail[] = [];
  public buttonLoading = false;

  constructor(public api: ApiHelper) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"] && this.subscription) {
      this.filterStatus = this.subscription._circuits[0].speedPolicer;
    }
  }

  emitCloseDialog() {
    this.dialogCloseEmitter.emit("close");
  }

  submit() {
    this.buttonLoading = true;

    let commandKey: SelfServiceCommandKeys;
    let modify: SelfServicePayloads;
    if (this.subscription.product.productType === "L2VPN") {
      modify = new ModifyL2VPNSpeedPolicer();
      commandKey = SelfServiceCommandKeys.ModifyL2VpnSpeedPolicer;
    } else {
      modify = this.subscription.product.tag === "LP" ? new ModifyLightPath() : new ModifyRedundantLightPath();
      commandKey =
        this.subscription.product.tag === "LP" ?
          SelfServiceCommandKeys.ModifyLightpath
        : SelfServiceCommandKeys.ModifyRedundantLightpath;
    }

    const command = new SelfServiceCommand(this.subscription.subscriptionId, commandKey);
    modify.subscription = this.subscription;
    modify.speedPolicer = !this.filterStatus;
    command.payload = modify;

    // reset error messages
    this.errors = [];
    this.hasErrors = false;

    this.api
      .selfServiceCommand(command)
      .then((response) => {
        this.processId = (response as ModifyResponse).pid;
        this.bus.emit({ processId: this.processId, action: commandKey });
      })
      .catch((err) => {
        this.buttonLoading = false;
        console.log(JSON.stringify(err));
        this.errors = transformError(err.error.detail);
        this.hasErrors = true;
      });
  }
}
