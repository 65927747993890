const infraRules: any = {};
for (const productType of ["Port", "IP", "IP_PREFIX", "L2VPN", "L3VPN", "LightPath", "FW"]) {
  infraRules[productType] = {
    view: ["Infrabeheerder", "Infraverantwoordelijke", "SuperuserRO", "noc-fls", "noc-engineers"],
    edit: ["Infraverantwoordelijke"],
  };
}
export const AccessRules: any = {
  ...infraRules,
  Wireless: {
    view: ["SURFwireless-beheerder", "SuperuserRO"],
    edit: [],
  },
  cert: {
    view: ["Infraverantwoordelijke", "Beveiligingsverantwoordelijke", "surfcert-kernel", "SuperuserRO"],
    edit: ["Infraverantwoordelijke", "Beveiligingsverantwoordelijke", "surfcert-kernel"],
  },
  domains: {
    view: ["SuperuserRO"],
    edit: ["SuperuserRO"],
  },
};

export interface RoleCheckResult {
  ok: boolean;
  event: "requirements-not-met" | "no-roles" | "requirements-met" | "no-organization";
  need: string[];
  productType?: string;
  requestedAction?: "view" | "edit";
}

export const SuperUserRoles = ["SuperuserRO", "noc-fls", "noc-engineers"];

export const rulesAreAvailable = (productType: string): boolean => productType in AccessRules;
