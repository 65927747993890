/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { RecordsUpdate } from "../../models/records-update";
import { RecordsUpdateStatus } from "../../models/records-update-status";

export interface UpdateRecords$Params {
  organisation_id: string;
  zone_id: string;
  token?: string | null;
  check?: boolean;
  body: RecordsUpdate;
}

export function updateRecords(
  http: HttpClient,
  rootUrl: string,
  params: UpdateRecords$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<RecordsUpdateStatus>> {
  const rb = new RequestBuilder(rootUrl, updateRecords.PATH, "patch");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.path("zone_id", params.zone_id, {});
    rb.query("token", params.token, {});
    rb.query("check", params.check, {});
    rb.body(params.body, "application/json");
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<RecordsUpdateStatus>;
    }),
  );
}

updateRecords.PATH = "/surfdomeinen/organisations/{organisation_id}/zones/{zone_id}/records/";
