import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { GenericSubscriptionComponent } from "../generic-subscription";
import {
  IrbSubscriptionDetailsGQL,
  IrbSubscriptionDetailsQuery,
  IrbSubscriptionDetailsType,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";
import { StorageHelper } from "../../../../helpers/storage/storagehelper";

@Component({
  selector: "irb-subscription",
  templateUrl: "irb-subscription.html",
})
export class IrbSubscriptionComponent extends GenericSubscriptionComponent<
  IrbSubscriptionDetailsGQL,
  IrbSubscriptionDetailsQuery,
  IrbSubscriptionDetailsType
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";
  public linkPortSubscription = false; // customer can view the linked port subscription?
  public portSubscriptionName = "Port subscription name";

  constructor(
    public auth: AuthService,
    protected api: ApiHelper,
    protected route: ActivatedRoute,
    protected query: IrbSubscriptionDetailsGQL,
  ) {
    super(api, route);
    this.subscriptionChange.subscribe(this.onSubscriptionChange);
  }

  protected onQuerySuccess(result: ApolloQueryResult<IrbSubscriptionDetailsQuery>): IrbSubscriptionDetailsType {
    return result.data.irbDetails as IrbSubscriptionDetailsType;
  }

  private onSubscriptionChange(subscription: IrbSubscriptionDetailsType) {
    this.portSubscriptionName =
      subscription.portSubscription?.customerDescription ?? subscription.portSubscription?.description;
    this.linkPortSubscription = subscription.portSubscription?.customerId === StorageHelper.currentUser;
  }
}
