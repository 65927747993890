<navigation [activeItem]="" [requestsBubble]="3"></navigation>
<div class="wrapper wrapper--mail">
	<div class="container">
		<div class="mail">
			<div class="mail__list">
				<div class="mail__scroll">
					<h3 class="mail__list__title">{{ 'Page.MessageCenter.AllMessages' | translate }} ({{ notifications.length }})</h3>
					<div *ngFor="let mail of notifications; let i = index" class="mail__item" [class.active]="isActive(mail.id)" (click)="setActiveNotification(i)">
						<svg-icon class="mail__icon mail__icon--{{ mail.toasterColor }}" src="assets/images/icons/{{ mail.notificationIconType }}.svg"></svg-icon>
						<div class="mail__caption">
							<div class="mail__top">
								<h3 class="mail__title">{{ mail.heading }}</h3>
								<div class="mail__date">{{ mail.timestamp | date:'MMM d - h:mm' }}</div>
							</div>
							<div class="mail__bottom">
								<div class="mail__rule">
									<markdown>{{ mail.contents }}</markdown>
								</div>
								<div class="tags">
									<div class="tag" *ngFor="let tag of mail.tags">{{ tag }}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mail__content" *ngIf="activeNotification">
				<div class="mail__scroll">
					<div class="mail__top">
						<div class="mail__left">
							<h3 class="mail__title" [innerHTML]="activeNotification.heading"></h3>
							<div class="mail__date">{{ activeNotification.timestamp | date }}</div>
						</div>
						<div class="tags">
							<div class="tag" *ngFor="let tag of activeNotification.tags">{{ tag }}</div>
						</div>
					</div>
					<div class="mail__message">
						<div class="activity__description">
							<p>{{ activeMessage }}</p>
						  </div>
						  <div class="activity__services">
							  <div class="activity__services-header">
								  <div class="activity__services-col">
									  {{ 'Services.Affected' | translate }}
								  </div>
								  <div class="activity__services-col">
									  {{ 'Global.Impact' | translate }}
								  </div>
							  </div>
							  <div>
								  <div 
									*ngFor="let affectedSubscription of activeNotification.affected_subscriptions" 
									class="activity__services-row"
									(click)="segueToService(affectedSubscription)">
									  <div class="activity__services-col">
										  <div>{{ affectedSubscription.customer_description || affectedSubscription.description}}</div>
										  <span>{{affectedSubscription.name}}</span>
									  </div>
									  <div class="activity__services-col">
										  <div>{{affectedSubscription.impact_type | translate }}</div>
									  </div>
									  <svg-icon src="assets/images/icons/arrow-small-right.svg"></svg-icon>
								  </div>
							  </div>
						  </div>
						
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container"><footer></footer></div>
</div>
