<div class="empty-state">
  <div class="empty-state--icons">
    <div
      *ngFor="let icon of icons"
      class="service-summary__icon service-summary__icon--grey"
    >
      <svg-icon
        src="assets/images/icons/{{ icon.toLowerCase() }}.svg"
      ></svg-icon>
    </div>
  </div>
  <div>
    <h3>{{ translatedTitle }}</h3>
    <span
      [innerHtml]="'ServiceElement.EmptyState.Description' | translate | safe:'html'"
    ></span>
  </div>
</div>
