import { gql } from "apollo-angular";

const LightPathTopologyFragment = gql`
  fragment LightPathTopology on SubscriptionInterface {
    ... on Sn8LightPathSubscription {
      customerId
      vc {
        subscriptionInstanceId
        saps {
          subscriptionInstanceId
          vlanrange
          ownerSubscriptionId
          port {
            subscriptionId
            description
            customerDescriptions {
              customerId
              description
            }
          }
        }
      }
    }
  }
`;

export { LightPathTopologyFragment };
