import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter } from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { ApiHelper } from "../../../helpers/apihelper";
import { Instance } from "../../models/instance";
import { Router } from "@angular/router";
import { Apollo } from "apollo-angular";
import { TOPOLOGY_QUERY } from "../../../graphql/domain/topology";
import { TopologyFactory } from "../../models/base/topologyfactory";
import { SUBSCRIPTION_QUERY } from "../../../graphql/domain/subscription";
import { InstanceFactory } from "../../models/base/instancefactory";
import { SubscriptionDetails } from "../../../helpers/types";
import { PORT_VISUALISER_QUERY } from "../../../graphql/component/port-visualiser";

@Component({
  selector: "port-visualiser",
  templateUrl: "port-visualiser.html",
  styleUrls: ["port-visualiser.scss"],
})
export class PortVisualiser implements OnChanges, OnInit {
  @Input() unavailable: boolean;
  @Input() barsWillRoute = true;
  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @Input() subscriptionId: string;
  @Input() subscription: Instance;
  @Input() highlightedSubscriptionId = "";

  public bookingPercentage = 0;
  public amountReserved = 0;
  public serviceStats: any;
  public loadedSubscriptionId: any;

  public service: Instance;
  private meta: any = {};

  constructor(
    public auth: AuthService,
    public api: ApiHelper,
    private route: Router,
    private apollo: Apollo,
  ) {}

  ngOnInit() {
    this.setServicesStats();
    this.setBookingPercentage();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscriptionId"] && this.subscriptionId && !this.subscription) {
      this.getNewData();
    } else if (changes["subscription"] && this.subscription) {
      this.service = this.subscription;

      if (!this.service.servicesOnPort || !this.service.services) {
        this.subscriptionId = this.service.subscriptionId;
        this.getNewData();
        return;
      }
      this.meta = this.processSubscription(this.service);
      this.setServicesStats();
      this.setBookingPercentage();
    }
  }

  getNewData() {
    if (!this.subscriptionId) {
      return;
    }
    this.apollo
      .watchQuery<any>({
        // fetchPolicy: "no-cache",
        query: PORT_VISUALISER_QUERY,
        variables: {
          id: this.subscriptionId,
          portServices: !this.unavailable,
        },
      })
      .valueChanges.subscribe(({ data }) => {
        this.service = InstanceFactory.create(data.subscription); // unset in case it has been set before via this.subscription
        this.meta = this.processSubscription(this.service);
        this.setServicesStats();
        this.setBookingPercentage();
      });
  }

  processSubscription(subscription: Instance) {
    // assemble meta object
    const meta: SubscriptionDetails = {
      ...subscription,
      description: subscription.description,
      subscriptionId: subscription.subscriptionId,
      services: subscription?.services,
      portSpeed: subscription.portSpeed,
      product: subscription.product,
      location: undefined,
      l3vpnsapss: undefined,
      esis: undefined,
      portMode: "",
      circuits: [],
      saps: [],
      address: undefined,
      ipGateway: undefined,
    };
    if (subscription?.servicesOnPort) {
      meta.services = subscription?.servicesOnPort.map((service) => ({
        ...service,
        productType: service.product.productType,
      }));
    }
    return meta;
  }

  setBookingPercentage() {
    if (this.amountReserved === 0 || !this.meta?.portSpeed) {
      return;
    }

    this.bookingPercentage = Math.round((this.amountReserved / this.meta?.portSpeed) * 100);
  }

  setServicesStats() {
    if (!this.service || !this.service.portMode) {
      return;
    }
    if (this.service.portMode === "Link Member") {
      this.amountReserved = this.service.portSpeed;
      this.serviceStats = [
        {
          subscriptionId: this.service.subscriptionId,
          productType: this.service.product ? this.service.product.productType : "default",
          description: this.service.description,
          serviceSpeed: this.service.portSpeed,
          percentage: 100,
        },
      ];
    } else {
      if (!this.meta.services) {
        return [];
      }
      const portSpeed = this.meta.portSpeed;
      this.amountReserved = 0;

      this.serviceStats = this.meta.services.map((sub) => {
        this.amountReserved += sub.serviceSpeed;
        return {
          subscriptionId: sub.subscriptionId,
          productType: sub.productType || "default",
          description: sub.description,
          serviceSpeed: sub.serviceSpeed,
          percentage: (sub.serviceSpeed / portSpeed) * 100,
        };
      });
    }
  }

  clickBar(service): void {
    if (this.barsWillRoute) {
      if (service.productType !== "Firewall") {
        this.route.navigate(["/subscription/" + service.productType + "/" + service.subscriptionId]);
      } else {
        this.route.navigate(["/subscription/IP/" + service.subscriptionId]);
      }
    } else {
      this.onSelected.emit(service.subscriptionId);
      this.highlightedSubscriptionId = service.subscriptionId;
    }
  }
}
