<div class="row" *ngIf="fetching">
    <div class="col-5">&nbsp;</div>
    <div class="col-2 text-center">
        <div class="highcharts-loading-inner"></div>
    </div>
    <div class="col-5">&nbsp;</div>
</div>
<div *ngIf="!fetching" class="card">
    <div class="row">
        <div class="col-6">
                <div class="inline-card">
                    <div class="inline-card__item inline-card__item--flex">
                        <div class="inline-card__col">
                            <h3 class="inline-card__title">{{ 'SLA.Report.ThisMonth' | translate }}</h3>
                        </div>
                        <div class="inline-card__col inline-card__col--flex">

                            <div class="inline-card__select-wrapper">
                                <select class="inline-card__select" (change)="updateSlsFilter('month', $any($event.target).value)" [(ngModel)]="month">
                                    <option *ngFor="let a of [0,1,2,3,4,5,6,7,8,9,10,11]" [attr.disabled]="(a < firstMonth || a > lastMonth) ? true : null" value="{{ a }}">{{ a | monthname }}</option>
                                </select>
                                <svg-icon src="assets/images/icons/arrow-small-down.svg" class="inline-card__select-icon"></svg-icon>
                            </div>

                            <div class="inline-card__select-wrapper">
                                <select class="inline-card__select" (change)="updateSlsFilter('year', $any($event.target).value)" [(ngModel)]="year">
                                    <option *ngFor="let a of availableYears" value="{{ a }}">{{ a }}</option>
                                </select>
                                <svg-icon src="assets/images/icons/arrow-small-down.svg" class="inline-card__select-icon"></svg-icon>
                            </div>
                        </div>
                    </div>
                    <div class="inline-card__item inline-card__item--flex" *ngIf="dataError === ''">
                        <div class="inline-card__col">
                            <div class="inline-card__status">
                                <svg-icon *ngIf="slsData.sls - slsData.availabilityLastMonth >= slsOffset" src="assets/images/icons/frown.svg" class="inline-card__status-icon inline-card__status-icon--warning"></svg-icon>
                                <svg-icon *ngIf="slsData.sls - slsData.availabilityLastMonth < slsOffset" src="assets/images/icons/smile.svg" class="inline-card__status-icon inline-card__status-icon--ok"></svg-icon>

                                <div class="inline-card__status-caption">
                                    <h3 class="inline-card__status-title">{{ slsData.availabilityLastMonth }}% {{ 'ServiceElement.Action.Availability' | translate }}</h3>
                                    <div>{{ 'SLA.Report.SLSStandard' | translate }} >= {{ slsData.sls }}%</div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-card__col">
                            <div class="service__legend">
                                <table class="service__table">
                                    <tbody>
                                        <tr>
                                            <td><strong>{{ 'Chart.Label.In' | translate }}</strong></td>
                                            <td>{{ slsData.lastMonthThroughputIn  | readable_volume }}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{ 'Chart.Label.Out' | translate }}</strong></td>
                                            <td>{{ slsData.lastMonthThroughputOut | readable_volume }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="inline-card__item" *ngIf="dataError === ''">
                        <h3 class="inline-card__heading">{{ 'Global.Comments' | translate }}</h3>
                        <div class="timeline">
                            <div *ngFor="let e of slsData.events" class="timeline__item">
                                <div><i>{{ e.startTime * 1000 | date:'d MMM y, hh:mm' }} - {{ e.endTime * 1000 | date:'hh:mm' }},</i></div>
                                {{ e.description }}
                            </div>
                        </div>
                    </div>
                </div>




        </div>

        <div class="col-6">

            <div class="inline-card">
                    <div class="inline-card__item inline-card__item--flex">
                        <div class="inline-card__col">
                            <h3 class="inline-card__title">{{ 'SLA.Report.Previous12Months' | translate }}</h3>
                        </div>
                    </div>
                    <div class="inline-card__item inline-card__item--flex" *ngIf="dataError === ''">
                        <div class="inline-card__col">
                            <div class="inline-card__status">
                                <svg-icon *ngIf="slsData.sls - slsData.availabilityLast12Months >= slsOffset" src="assets/images/icons/frown.svg" class="inline-card__status-icon inline-card__status-icon--warning"></svg-icon>
                                <svg-icon *ngIf="slsData.sls - slsData.availabilityLast12Months < slsOffset" src="assets/images/icons/smile.svg" class="inline-card__status-icon inline-card__status-icon--ok"></svg-icon>
                                <div class="inline-card__status-caption">
                                    <h3 class="inline-card__status-title">{{ slsData.availabilityLast12Months }}% {{ 'ServiceElement.Action.Availability' | translate }}</h3>
                                    <div>{{ 'SLA.Report.SLSStandard' | translate }} >= {{ slsData.sls }}%</div>
                                </div>
                            </div>
                        </div>
                        <div class="inline-card__col">
                            <div class="service__legend">
                                <table class="service__table">
                                    <tbody>
                                        <tr>
                                            <td><strong>{{ 'Chart.Label.In' | translate }}</strong></td>
                                            <td>{{ slsData.last12MonthsThroughputIn  | readable_volume }}</td>
                                        </tr>
                                        <tr>
                                            <td><strong>{{ 'Chart.Label.Out' | translate }}</strong></td>
                                            <td>{{ slsData.last12MonthsThroughputOut  | readable_volume }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="inline-card__item" *ngIf="dataError === ''">
                        <h3 class="inline-card__heading">{{ 'Global.Comments' | translate }}</h3>
                        <div class="timeline">
                            <!--
                            <div class="timeline__item">
                                <div><i>Augustus, 2018</i></div>
                                2 incidenten
                            </div>
                            <div class="timeline__item">
                                <div><i>Mei, 2018</i></div>
                                4 incidenten
                            </div>
                            -->
                        </div>
                    </div>
                </div>


        </div>
    </div>
    
    <div *ngIf="!fetching && dataError !== ''" class="dialog__error">
      {{ dataError }}
    </div>

</div>
