/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { BaseService } from "../base-service";
import { ApiConfiguration } from "../api-configuration";
import { StrictHttpResponse } from "../strict-http-response";

import { changeDomain } from "../fn/domains/change-domain";
import { ChangeDomain$Params } from "../fn/domains/change-domain";
import { deleteDomain } from "../fn/domains/delete-domain";
import { DeleteDomain$Params } from "../fn/domains/delete-domain";
import { DomainAuthCode } from "../models/domain-auth-code";
import { DomainMessage } from "../models/domain-message";
import { DomainRead } from "../models/domain-read";
import { getAuthCode } from "../fn/domains/get-auth-code";
import { GetAuthCode$Params } from "../fn/domains/get-auth-code";
import { readDomain } from "../fn/domains/read-domain";
import { ReadDomain$Params } from "../fn/domains/read-domain";
import { readDomainMessages } from "../fn/domains/read-domain-messages";
import { ReadDomainMessages$Params } from "../fn/domains/read-domain-messages";
import { readDomains } from "../fn/domains/read-domains";
import { ReadDomains$Params } from "../fn/domains/read-domains";
import { registerDomain } from "../fn/domains/register-domain";
import { RegisterDomain$Params } from "../fn/domains/register-domain";
import { retryRegistration } from "../fn/domains/retry-registration";
import { RetryRegistration$Params } from "../fn/domains/retry-registration";
import { Status } from "../models/status";
import { updateDomain } from "../fn/domains/update-domain";
import { UpdateDomain$Params } from "../fn/domains/update-domain";

@Injectable({ providedIn: "root" })
export class DomainsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `registerDomain()` */
  static readonly RegisterDomainPath = "/surfdomeinen/organisations/{organisation_id}/domains/registration";

  /**
   * Register Domain.
   *
   * Register a domain or, if auth_code is provided, start a transfer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registerDomain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerDomain$Response(
    params: RegisterDomain$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DomainRead | Status>> {
    return registerDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * Register Domain.
   *
   * Register a domain or, if auth_code is provided, start a transfer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registerDomain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registerDomain(params: RegisterDomain$Params, context?: HttpContext): Observable<DomainRead | Status> {
    return this.registerDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomainRead | Status>): DomainRead | Status => r.body),
    );
  }

  /** Path part for operation `retryRegistration()` */
  static readonly RetryRegistrationPath =
    "/surfdomeinen/organisations/{organisation_id}/domains/registration/{domain_id}";

  /**
   * Retry Registration.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `retryRegistration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retryRegistration$Response(
    params: RetryRegistration$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DomainRead | Status>> {
    return retryRegistration(this.http, this.rootUrl, params, context);
  }

  /**
   * Retry Registration.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `retryRegistration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  retryRegistration(params: RetryRegistration$Params, context?: HttpContext): Observable<DomainRead | Status> {
    return this.retryRegistration$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomainRead | Status>): DomainRead | Status => r.body),
    );
  }

  /** Path part for operation `readDomains()` */
  static readonly ReadDomainsPath = "/surfdomeinen/organisations/{organisation_id}/domains/";

  /**
   * Read Domains.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readDomains()` instead.
   *
   * This method doesn't expect any request body.
   */
  readDomains$Response(
    params: ReadDomains$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<DomainRead>>> {
    return readDomains(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Domains.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readDomains$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readDomains(params: ReadDomains$Params, context?: HttpContext): Observable<Array<DomainRead>> {
    return this.readDomains$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DomainRead>>): Array<DomainRead> => r.body),
    );
  }

  /** Path part for operation `readDomain()` */
  static readonly ReadDomainPath = "/surfdomeinen/organisations/{organisation_id}/domains/{domain_id}";

  /**
   * Read Domain.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readDomain()` instead.
   *
   * This method doesn't expect any request body.
   */
  readDomain$Response(params: ReadDomain$Params, context?: HttpContext): Observable<StrictHttpResponse<DomainRead>> {
    return readDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Domain.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readDomain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readDomain(params: ReadDomain$Params, context?: HttpContext): Observable<DomainRead> {
    return this.readDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomainRead>): DomainRead => r.body),
    );
  }

  /** Path part for operation `changeDomain()` */
  static readonly ChangeDomainPath = "/surfdomeinen/organisations/{organisation_id}/domains/{domain_id}";

  /**
   * Change Domain.
   *
   * Change the type of the domain
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeDomain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeDomain$Response(
    params: ChangeDomain$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DomainRead | Status>> {
    return changeDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * Change Domain.
   *
   * Change the type of the domain
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeDomain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  changeDomain(params: ChangeDomain$Params, context?: HttpContext): Observable<DomainRead | Status> {
    return this.changeDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomainRead | Status>): DomainRead | Status => r.body),
    );
  }

  /** Path part for operation `deleteDomain()` */
  static readonly DeleteDomainPath = "/surfdomeinen/organisations/{organisation_id}/domains/{domain_id}";

  /**
   * Delete Domain.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteDomain()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDomain$Response(params: DeleteDomain$Params, context?: HttpContext): Observable<StrictHttpResponse<Status>> {
    return deleteDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Domain.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteDomain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteDomain(params: DeleteDomain$Params, context?: HttpContext): Observable<Status> {
    return this.deleteDomain$Response(params, context).pipe(map((r: StrictHttpResponse<Status>): Status => r.body));
  }

  /** Path part for operation `updateDomain()` */
  static readonly UpdateDomainPath = "/surfdomeinen/organisations/{organisation_id}/domains/{domain_id}";

  /**
   * Update Domain.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDomain()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDomain$Response(
    params: UpdateDomain$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DomainRead | Status>> {
    return updateDomain(this.http, this.rootUrl, params, context);
  }

  /**
   * Update Domain.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDomain$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateDomain(params: UpdateDomain$Params, context?: HttpContext): Observable<DomainRead | Status> {
    return this.updateDomain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomainRead | Status>): DomainRead | Status => r.body),
    );
  }

  /** Path part for operation `getAuthCode()` */
  static readonly GetAuthCodePath = "/surfdomeinen/organisations/{organisation_id}/domains/{domain_id}/auth_code";

  /**
   * Get Auth Code.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAuthCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthCode$Response(
    params: GetAuthCode$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<DomainAuthCode>> {
    return getAuthCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Auth Code.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAuthCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAuthCode(params: GetAuthCode$Params, context?: HttpContext): Observable<DomainAuthCode> {
    return this.getAuthCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<DomainAuthCode>): DomainAuthCode => r.body),
    );
  }

  /** Path part for operation `readDomainMessages()` */
  static readonly ReadDomainMessagesPath = "/surfdomeinen/organisations/{organisation_id}/domains/{domain_id}/messages";

  /**
   * Read Domain Messages.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `readDomainMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  readDomainMessages$Response(
    params: ReadDomainMessages$Params,
    context?: HttpContext,
  ): Observable<StrictHttpResponse<Array<DomainMessage>>> {
    return readDomainMessages(this.http, this.rootUrl, params, context);
  }

  /**
   * Read Domain Messages.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `readDomainMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  readDomainMessages(params: ReadDomainMessages$Params, context?: HttpContext): Observable<Array<DomainMessage>> {
    return this.readDomainMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DomainMessage>>): Array<DomainMessage> => r.body),
    );
  }
}
