import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClipboardModule } from "ngx-clipboard";

// Dialog components
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatTableModule } from "@angular/material/table";
import { MatSortModule } from "@angular/material/sort";
import { TranslateModule } from "@ngx-translate/core";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NavigationComponent } from "../../components/common/navigation/navigation";
import { FormsModule } from "@angular/forms";
import { SelfserviceToggleComponent } from "../../components/selfservice/selfservice-toggle/selfservice-toggle";
import { RouterModule } from "@angular/router";
import { StatusToIconPipe } from "../../helpers/statusiconpipe/statusiconpipe";
import { CompleterComponent } from "../../components/subscriptions-list/completer/completer";
import { Ng2CompleterModule } from "../../components/common/ng2-completer/ng2-completer";
import { CompleterFilterComponent } from "../../components/subscriptions-list/completer-filter/completer-filter";
import { CompleterSearchComponent } from "../../components/subscriptions-list/completer-search/completer-search";
import { CompleterLocationComponent } from "../../components/subscriptions-list/completer-location/completer-location";
import { NgClickOutsideDirective } from "ng-click-outside2";
import { PaginationComponent } from "../../components/common/pagination/pagination";
import { ConfirmationDialogService } from "../../services/confirmation-dialog";
import { SafeButtonComponent } from "../../components/common/safe-button/safe-button.component";
import { LoaderComponent } from "../../components/common/loader/loader";
import { graphqlProvider, graphqlProviderReplicatedData } from "../graphql.module";
import { LoadingState } from "../../services/loading-state";
import { PageTabComponent } from "../../components/subscription-detail/page-tab/page-tab";
import { CustomDropdownComponent } from "../../components/common/custom-dropdown/custom-dropdown";
import { Ng2SearchPipe } from "../../components/common/ng2-search-filter/ng2-filter.pipe";

@NgModule({
  declarations: [
    NavigationComponent,
    SelfserviceToggleComponent,
    StatusToIconPipe,
    CompleterComponent,
    CompleterFilterComponent,
    CompleterSearchComponent,
    CompleterLocationComponent,
    PaginationComponent,
    SafeButtonComponent,
    LoaderComponent,
    PageTabComponent,
    CustomDropdownComponent,
    Ng2SearchPipe,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    TranslateModule.forChild(),
    AngularSvgIconModule,
    FormsModule,
    RouterModule,
    Ng2CompleterModule,
    NgClickOutsideDirective,
    ClipboardModule,
  ],
  providers: [ConfirmationDialogService, LoadingState, graphqlProvider, graphqlProviderReplicatedData],
  exports: [
    CommonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatTableModule,
    MatSortModule,
    TranslateModule,
    AngularSvgIconModule,
    FormsModule,
    RouterModule,
    Ng2CompleterModule,
    ClipboardModule,
    NgClickOutsideDirective,
    NavigationComponent,
    SelfserviceToggleComponent,
    StatusToIconPipe,
    CompleterComponent,
    CompleterFilterComponent,
    CompleterSearchComponent,
    CompleterLocationComponent,
    PaginationComponent,
    SafeButtonComponent,
    LoaderComponent,
    PageTabComponent,
    CustomDropdownComponent,
    Ng2SearchPipe,
  ],
})
export class NwdSharedModule {}
