import { Component, Input, Output, OnChanges, OnInit, SimpleChanges, EventEmitter } from "@angular/core";
import { AuthService } from "../../../services/AuthService";
import { ApiHelper } from "../../../helpers/apihelper";
import { Instance } from "../../models/instance";
import { Router } from "@angular/router";
import {
  AggspSubscriptionDetailsType,
  IrbSubscriptionDetailsType,
  LinkMemberDetailsType,
  MscSubscriptionDetailsType,
  ServicePortSubscriptionDetailsType,
  ServiceType,
} from "../../../gql/generated-ingestor";
import { PortTileSap } from "../port-tile/port-tile";

@Component({
  selector: "port-visualiser-replicated",
  templateUrl: "port-visualiser.html",
  styleUrls: ["port-visualiser.scss"],
})
export class PortVisualiserReplicated implements OnChanges {
  @Input() unavailable: boolean;
  @Input() barsWillRoute = true;
  @Output() onSelected: EventEmitter<any> = new EventEmitter();
  @Input() subscription:
    | PortTileSap["port"]
    | ServicePortSubscriptionDetailsType
    | MscSubscriptionDetailsType
    | AggspSubscriptionDetailsType
    | IrbSubscriptionDetailsType
    | LinkMemberDetailsType
    | Instance;
  @Input() instance: Instance;
  @Input() highlightedSubscriptionId = "";

  public bookingPercentage = 0;
  public amountReserved = 0;
  public serviceStats: any;
  public loadedSubscriptionId: any;

  public service: Instance;
  private meta: any = {};

  constructor(
    public auth: AuthService,
    public api: ApiHelper,
    private route: Router,
  ) {}

  getProductType = (subscription: any): string => {
    if (subscription.productType) {
      return subscription.productType;
    }
    if (subscription.product?.type) {
      return subscription.product.type;
    }
    return "";
  };

  hasAmountReserved = (subscription: any): subscription is Instance => subscription.amountReserved !== undefined;

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"] && this.subscription) {
      this.setBookingPercentage();
    }
  }

  setBookingPercentage() {
    this.bookingPercentage = Math.round((this.subscription.amountReserved / this.subscription.portSpeed) * 100);
  }

  clickBar(service: ServiceType): void {
    if (this.barsWillRoute) {
      const routerProductType = service.product.type === "Firewall" ? "IP" : service.product.type;
      // @todo insert linked-subscription product tag
      const linkParts = [routerProductType, service.product.tag];
      this.route.navigate(["/subscription/" + linkParts.join("/") + "/" + service.subscriptionId]);
    } else {
      this.onSelected.emit(service.subscriptionId);
      this.highlightedSubscriptionId = service.subscriptionId;
    }
  }
}
