import { gql } from "apollo-angular";
import { FirewallTopologyFragment } from "../fragments/topology/fw_topology";

import { IpBgpTopologyFragment } from "../fragments/topology/ip_bgp_topology";

import { IpStaticTopologyFragment } from "../fragments/topology/ip_static_topology";

import { LightPathTopologyFragment } from "../fragments/topology/lightpath_topology";

import { LightPathRedundantTopologyFragment } from "../fragments/topology/lightpath_redundant_topology";

import { L2VpnTopologyFragment } from "../fragments/topology/l2vpn_topology";

import { L3VpnTopologyFragment } from "../fragments/topology/l3vpn_topology";

export const TOPOLOGY_QUERY = gql`
  ${FirewallTopologyFragment}
  ${IpBgpTopologyFragment}
  ${IpStaticTopologyFragment}
  ${LightPathTopologyFragment}
  ${LightPathRedundantTopologyFragment}
  ${L2VpnTopologyFragment}
  ${L3VpnTopologyFragment}
  query SubscriptionTopology($id: ID!) {
    subscription(id: $id) {
      subscriptionType: __typename
      ...FirewallTopology
      ...IpBgpTopology
      ...IpStaticTopology
      ...LightPathTopology
      ...LightPathRedundantTopology
      ...L2VpnTopology
      ...L3VpnTopology
    }
  }
`;

// ... ${IpBgpTopologyFragment}
// ... ${IpStaticTopologyFragment}
// ... ${LightPathTopologyFragment}
// ... ${LightPathRedundantTopologyFragment}
// ... ${L2VpnTopologyFragment}
// ... ${L3VpnTopologyFragment}
