/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { DomainRead } from "../../models/domain-read";
import { DomainStatus } from "../../models/domain-status";

export interface ReadDomains$Params {
  organisation_id: string;

  /**
   * The sort will accept parameters like `col` or `col:desc` and will split on the `:`. If it does not find a `:` it will sort ascending on that column. Sortable columns: id, name, tld, registrant_id, admin_id, tech_id, type, status
   */
  sort?: Array<string> | null;
  domain_name?: string | null;
  status?: Array<DomainStatus> | null;
  token?: string | null;
  offset?: number;
  limit?: number;
}

export function readDomains(
  http: HttpClient,
  rootUrl: string,
  params: ReadDomains$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<DomainRead>>> {
  const rb = new RequestBuilder(rootUrl, readDomains.PATH, "get");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.query("sort", params.sort, {});
    rb.query("domain_name", params.domain_name, {});
    rb.query("status", params.status, {});
    rb.query("token", params.token, {});
    rb.query("offset", params.offset, {});
    rb.query("limit", params.limit, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<DomainRead>>;
    }),
  );
}

readDomains.PATH = "/surfdomeinen/organisations/{organisation_id}/domains/";
