import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "notification-button",
  templateUrl: "notification-button.html",
  styleUrls: ["notification-button.scss"],
})
export class NotificationButtonComponent implements OnInit, OnChanges {
  @Input() impactSetting;
  public active = true;
  public impactDescription = "";
  private impactDescriptions = {};

  constructor(public translate: TranslateService) {
    let receiveNotificationsFor = this.translate.instant("Notification.ReceiveNotificationsFor");
    this.impactDescriptions = {
      Downtime: receiveNotificationsFor + " " + this.translate.instant("Notification.Status.Downtime"),
      "Loss of Resiliency":
        receiveNotificationsFor + " " + this.translate.instant("Notification.Status.LossofResiliency"),
      "Reduced Redundancy":
        receiveNotificationsFor + " " + this.translate.instant("Notification.Status.ReducedRedundancy"),
      Never: receiveNotificationsFor + " " + this.translate.instant("Notification.Status.Never"),
    };
  }
  ngOnInit() {
    // set sane default
    if (this.impactSetting === "") {
      this.impactDescription = this.impactDescriptions["Loss of Resiliency"];
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes["impactSetting"]) {
      this.impactDescription = this.impactDescriptions[this.impactSetting];

      if (this.impactSetting === "Never") {
        this.active = false;
      } else {
        this.active = true;
      }
    }
  }
}
