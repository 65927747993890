import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { StorageHelper } from "../../../helpers/storage/storagehelper";
import { ActivatedRoute } from "@angular/router";
import { SelfserviceDialogType } from "../../../helpers/self-service/models/types";
import * as Apollo from "apollo-angular";
import { ApolloQueryResult } from "@apollo/client";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CimQueryImpactTypes, CimQueryStatuses } from "../../../graphql/custom-types";
import dayjs from "dayjs";
import { CIM_SUBSCRIPTION } from "../../../graphql/domain/cim";

// prettier-ignore
@Component({ template: "" })
export abstract class GenericSubscriptionComponent<
  Q extends Apollo.Query<R, { subscriptionId: string }>,
  R,
  S extends MinimalSubscription,
> implements OnInit {
  @Input() isTerminated: boolean;
  @Output() incident: EventEmitter<string> = new EventEmitter();
  @Output() notification: EventEmitter<string> = new EventEmitter();

  public impactSetting = "Never";
  public activeTab = "config";
  public activities: Notification[] = [];

  public dialogRef: MatDialogRef<MatDialog>; // TODO test

  public firewalled = false;

  public isEditable = false;

  public messageBus: EventEmitter<string> = new EventEmitter();

  public temporarySubscriptionName = "";

  public pageTitle = "";
  public productType = "";
  public productTag = "";

  public subscriptionId = "";
  public selfserviceDialogTypes: SelfserviceDialogType[] = [];

  public subscriptionChange: EventEmitter<S | undefined> = new EventEmitter();
  protected query: Q | undefined = undefined;
  private _subscription: S | undefined = undefined;

  constructor(
    protected api: ApiHelper,
    protected route: ActivatedRoute,
  ) {
    this.subscriptionId = this.route.snapshot.paramMap.get("subscriptionId");
  }

  public get subscription(): S | undefined {
    return this._subscription;
  }

  public set subscription(subscription: S) {
    const isTerminated = subscription?.status === "terminated"
    this._subscription = {...subscription, isTerminated };
    this.pageTitle = subscription.customerDescription ?? subscription.description;
    this.productType = subscription.product.type;
    this.productTag = subscription.product.tag;
    this.firewalled = subscription.firewallEnabled ?? false;
    this.subscriptionChange.emit(this._subscription);
  }

  ngOnInit() {
    this.messageBus.subscribe((message: string) => {
      if (message === "refresh") {
        const query = this.query;
        if (query) {
          this.querySubscription(query);
        }
      }
    });
    this.messageBus.emit("refresh");

    this.fetchActivities();
  }

  /** Notifications **/
  fetchActivities() {
    const endDate = dayjs().add(2, "week").toDate();
    this.api.apollo
      .query<{ notifications: Array<Notification> }>({
        query: CIM_SUBSCRIPTION,
        fetchPolicy: "network-only",
        variables: {
          filter: {
            customerId: localStorage.getItem("viewingCustomerGUID"),
            beginTimestamp: new Date(),
            endTimestamp: endDate,
            statuses: [CimQueryStatuses.OPEN, CimQueryStatuses.UPDATED],
            impacts: [
              CimQueryImpactTypes.DOWN,
              CimQueryImpactTypes.REDUCED_REDUNDANCY,
              CimQueryImpactTypes.RESILIENCE_LOSS,
            ],
            subscriptionIds: [this.subscriptionId],
          },
        },
      })
      .subscribe((data) => {
        this.activities = data.data.notifications;
      });
  }

  /** Customer description interaction **/
  async startEditingCustomerDescription(originalDescription: string | undefined) {
    this.temporarySubscriptionName = originalDescription ?? "";
    this.isEditable = true;
  }

  async saveCustomerDescription() {
    if (this.temporarySubscriptionName.length > 0) {
      this.api
        .set_customer_description(StorageHelper.currentUser, this.subscriptionId, this.temporarySubscriptionName)
        .then((result) => {
          this.isEditable = false;
          this.messageBus.emit("refresh");
        })
        .catch((err) => {
          console.log(err);
          this.isEditable = false;
        });
    }
  }

  async cancelEditingCustomerDescription() {
    this.isEditable = false;
  }

  /** Dialog and tab interactivity **/
  changeActiveTab(tabName: string) {
    this.activeTab = tabName;
  }

  closeDialog() {
    this.dialogRef.close();
  }

  async openIncidentDialog(event: Event) {
    event.stopPropagation();
    event.preventDefault();
    this.incident.emit("open");
  }

  openSelfserviceDialog(dialogType: SelfserviceDialogType) {
    console.warn("Attempting to open sekf service dialog, but uncertain what to do");
    // const roleCheckResult = this.auth.checkRoleAccess(
    //   this.subscription.product.type,
    //   "edit",
    // );


    // if (!roleCheckResult.ok) {
    //   this.auth.roleEvent.emit(roleCheckResult);
    //   return;
    // }

    // if (!this.auth.isAuthenticatedForSelfService()) {
    //   this.dialog.open(DialogAuthComponent, {
    //     data: { state: false, initialAction: type },
    //   });
    // } else {
    //   const ssDialogRef = this.dialog.open(DialogSelfserviceComponent, {
    //     data: {
    //       type,
    //       subscription: this.subscription,
    //     },
    //   });
    //   ssDialogRef.componentInstance.close.subscribe((command) => {
    //     console.log(`Received '${command}' through close pipe`);

    //     // evict the cache for the current subscription, to force a refresh.
    //     this.apollo.client.cache.evict({ id: this.subscriptionCacheId });
    //     this.retrieveSubscriptionData();
    //   });
    // }
  }

  /** Other */
  private querySubscription(query: Q) {
    query
      .watch({ subscriptionId: this.subscriptionId })
      .valueChanges.subscribe((result) => (this.subscription = this.onQuerySuccess(result)));
  }

  protected abstract onQuerySuccess(result: ApolloQueryResult<R>): S;
}

// TODO replace with a generated subscriptin
export interface MinimalSubscription {
  customerDescription?: string;
  description: string;
  firewallEnabled?: boolean;
  status: string;
  product: {
    __typeName?: "ProductType";
    id: any;
    name: string;
    tag: string;
    type: string;
  };
}
