<div
  class="dialog__body dialog__body--grow dialog__body--grey dialog__body--center"
>
  <div class="selfservice-loader">
    <img src="assets/images/success-illustration.svg" alt="" />
    <h3 class="selfservice-loader__title">
      {{ 'Selfservice.Global.SuccessTitle' | translate }}
    </h3>
    <p>{{ 'Selfservice.Global.SuccessText' | translate }}</p>
    <button class="button button--secondary" mat-dialog-close>
      {{ 'Services.Action.Close' | translate }}
    </button>
  </div>
</div>
<div class="dialog__footer dialog__footer--center dialog__footer--border">
  <div class="dialog__footer-text">
    <selfservice-authentication-box></selfservice-authentication-box>
  </div>
</div>
