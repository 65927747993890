import { gql } from "apollo-angular";
import { L2VpnTopologyFragment } from "../topology/l2vpn_topology";

import { L3VpnTopologyFragment } from "../topology/l3vpn_topology";

const FirewallTopologyFragment = gql`
  ${L2VpnTopologyFragment}
  ${L3VpnTopologyFragment}
  fragment FirewallTopology on SubscriptionInterface {
    ... on FwSubscription {
      customerId
      firewall {
        subscriptionInstanceId

        ipGwEndpoint {
          subscriptionInstanceId
        }

        l2Endpoints {
          esis {
            subscriptionInstanceId
            otherSubscriptions(statusFilter: ["active", "provisioning"]) {
              ...L2VpnTopology
            }
          }
        }

        l3Endpoints {
          subscriptionInstanceId
          saps {
            subscriptionInstanceId
            otherSubscriptions(statusFilter: ["active", "provisioning"]) {
              ...L3VpnTopology
            }
          }
        }
      }
    }
  }
`;

export { FirewallTopologyFragment };
