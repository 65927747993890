<div class="dialog dialog-message">
	<div>
		<div class="dialog-message__close-wrapper">
			<svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
		</div>
		<div class="dialog-message__body">
			<h3 class="dialog-message__title">{{ title }}</h3>
			<div class="dialog-message__text">{{ message }}</div>
    </div>
      <div *ngIf="isConfirm" class="dialog__footer dialog__footer--end dialog__footer--border">
        <div class="button__flex">
          <button class="button button--secondary" mat-dialog-close>{{ 'Global.Action.Cancel' | translate }}</button>
          <button class="button button--primary" (click)="emitPositive()">
            {{ 'Global.Action.Confirm' | translate }}
          </button>
        </div>
      </div>
		</div>
</div>
