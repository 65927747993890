<page
  [detailData]="{title: pageTitle, status: subscription?.status}"
  [firewalled]="firewalled"
  [productType]="productType"
  [terminated]="isTerminated"
>
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col--flex">
        <shortcut-menu-replicated
          *ngIf="!isTerminated"
          [subscription]="subscription"
          [productType]="productType"
          [messages]="activities"
        ></shortcut-menu-replicated>        <!-- Restore messages -->
        <terminated-card *ngIf="isTerminated" [date]="subscription?.endDate * 1000 | date:'y-MM-dd hh:mm'">
        </terminated-card>
      </div>
      <div class="col-lg-9">
        <div class="card">
          <div class="card__top-center">
            <h3 *ngIf="productType !== 'Port'">{{ 'ServiceDetails' | translate }}</h3>
            <div class="card__top-actions">
              <notification-button-replicated [subscription]="subscription" />
              <div
                *ngIf="auth?.isCurrentOrganisation(subscription?.customerId) && subscription && !isTerminated"
                class="button button--secondary"
                (click)="openIncidentDialog($event)"
              >
                {{ 'Services.Fulfilment.ReportProblem' | translate }}
              </div>
            </div>
          </div>

          <div class="row" *ngIf="productType === 'FW'">
            <div class="col-12 col-sm-7">
              <table class="table-properties table-properties--no-margin">
                <tr>
                  <td>{{ 'ServiceElement.Owner' | translate }}</td>
                  <td>{{ subscription?.organisation }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.OwnAlias' | translate }}</td>
                  <td>
                    <span class="alias" *ngIf="!isEditable">
                      {{ subscription | custom_description_ingestor }}
                      <div
                        class="alias__icon"
                        (click)="startEditingCustomerDescription(subscription?.customerDescription)"
                      >
                        <svg-icon src="assets/images/icons/edit.svg"></svg-icon>
                      </div>
                    </span>
                    <div *ngIf="isEditable" class="alias__form">
                      <input
                        [(ngModel)]="temporarySubscriptionName"
                        (blur)="saveCustomerDescription()"
                        class="input"
                        type="text"
                        value="{{ pageTitle }}"
                        autofocus
                      />
                      <span class="alias__form-button alias__form-button--save" (click)="saveCustomerDescription()">
                        <svg-icon src="assets/images/icons/tick.svg"></svg-icon>
                      </span>
                      <span
                        class="alias__form-button alias__form-button--cancel"
                        (click)="cancelEditingCustomerDescription()"
                      >
                        <svg-icon src="assets/images/icons/x.svg"></svg-icon>
                      </span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.SURFnetDescription' | translate }}</td>
                  <td>{{ subscription?.description }}</td>
                </tr>
                <tr
                  third-party-service-ids-row-content
                  *ngIf="subscription?.references?.length"
                  [subscription]="subscription"
                ></tr>
                <tr>
                  <td>{{ 'ServiceElement.ServiceID' | translate }}</td>
                  <td>
                    <span class="clipboard__rule" *ngIf="subscription?.subscriptionId"
                      >{{ subscription?.subscriptionId | shorten_guid }}
                      <span class="clipboard__link" ngxClipboard [cbContent]="subscription?.subscriptionId">
                        <div class="clipboard__icon">
                          <svg-icon src="assets/images/icons/copy.svg"></svg-icon>
                        </div> </span
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.ProductName' | translate }}</td>
                  <td>SURFfirewall</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.ServiceSpeed' | translate }}</td>
                  <td>{{ subscription?.serviceSpeed * 1000000 | readable_size }}</td>
                </tr>
              </table>
            </div>
            <div class="col-12 col-sm-5">
              <table class="table-properties table-properties--no-margin">
                <tr>
                  <td>{{ 'ServiceElement.CustomerASN' | translate }}</td>
                  <td>{{ subscription?.customerAsn }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.FWASN' | translate }}</td>
                  <td>1103</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.AvailabilityZone' | translate }}</td>
                  <td>{{ subscription?.availabilityZone }}</td>
                </tr>
                <tr *ngIf="isTerminated">
                  <td>{{ 'ServiceElement.Status' | translate }}</td>
                  <td>{{ subscription?.status }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.Start' | translate }}</td>
                  <td>{{ subscription?.startDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
                </tr>
                <tr *ngIf="isTerminated">
                  <td>{{ 'ServiceElement.End' | translate }}</td>
                  <td>{{ subscription?.endDate * 1000 | date:'y-MM-dd hh:mm' }}</td>
                </tr>
                <tr>
                  <td>{{ 'ServiceElement.Domain' | translate }}</td>
                  <td>{{ subscription?.domain }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="isTerminated" class="hide-everything-after-this-div"></div>

    <page-tab-replicated [productType]="productType" (activeTabEmitter)="changeActiveTab($event)">
    </page-tab-replicated>

    <div *ngIf="activeTab === 'graphic-view'">
      <networkgraph [subscriptionId]="subscription?.subscriptionId" #chart></networkgraph>
    </div>

    <div *ngIf="activeTab === 'system-stats'">
      <div *ngIf="productType === 'FW'">
        <system-stats [subscription]="subscription"></system-stats>
        <linechart-replicated id="chart" #chart [subscription]="subscription" />
      </div>
    </div>

    <div *ngIf="activeTab === 'config'">
      <div class="section" *ngIf="productType === 'FW'">
        <uplink-detail-container [subscription]="subscription | as_instance" [bus]="messageBus">
        </uplink-detail-container>
      </div>
      <div class="section">
        <!-- <tabbed-detail-container
        [service]="subscription | as_instance"
        [bus]="messageBus"
        [canModify]="true"
        [selfserviceState]="undefined">
      </tabbed-detail-container> -->
      </div>
      <linechart-replicated id="chart" #chart [subscription]="subscription" />
    </div>
  </div>
</page>

<ng-template #problemModal>
  <div class="dialog" style="min-height: 0px !important">
    <div class="dialog__header">
      <h3 class="dialog__title">{{ 'Services.Fulfilment.ReportProblem' | translate }}</h3>
      <svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
    </div>

    <div class="dialog__body dialog__body--grow">
      <div class="row">
        <div class="col-12">
          <label for="" class="form-label">{{ 'ServiceElement.SURFnetDescription' | translate }}</label>
          <div class="input__wrapper">
            <input type="text" class="input" value="{{ subscription.description }}" disabled />
          </div>
          <label for="" class="form-label">UUID</label>
          <div class="input__wrapper">
            <input type="text" class="input" value="{{ subscription.subscriptionId }}" disabled />
          </div>
          <label for="" class="form-label">{{ 'Global.Email' | translate }}</label>
          <div class="input__wrapper">
            <input type="text" class="input" placeholder="{{ 'Global.Email' | translate }}" />
          </div>
          <label for="" class="form-label">{{ 'Global.Message' | translate }}</label>
          <div class="textarea__wrapper">
            <textarea class="textarea" placeholder="{{ 'Global.Message' | translate }}"></textarea>
          </div>
        </div>
      </div>
    </div>

    <div class="dialog__footer dialog__footer--end">
      <div class="button__flex">
        <span class="button button--secondary">{{ 'Global.Action.Cancel' | translate }}</span>
        <span class="button">{{ 'Global.Action.Confirm' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>
