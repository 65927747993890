import { TopologyObject } from "../topologyObject";
import { get_customer_description } from "../../../helpers/subscription/misc";

const one = (v: any[]) => {
  if (!Array.isArray(v)) {
    throw new Error(`Expected iterable parameter, but got ${typeof v}`);
  }
  if (v.length !== 1) {
    throw new Error(`Expected exactly one item in iterable, but got ${v.length}`);
  }

  return v[0];
};

export interface TopologyNode {
  subscriptionInstanceId: string;
  topologyObjectType:
    | "ESI"
    | "VC"
    | "SAP"
    | "PORT"
    | "MSC"
    | "L2ENDPOINT"
    | "L3ENDPOINT"
    | "INTERNET"
    | "L2VPN"
    | "L3VPN"
    | "FIREWALL";
  otherSubscriptionId?: string;
  vlanrange?: string;
  customerDescription?: string;
}

const get_description = (sap) => {
  const customerId = localStorage.getItem("viewingCustomerGUID");
  if (sap.port?.customerDescriptions.length) {
    const description = get_customer_description(customerId, sap.port);
    if (description !== "") {
      return description;
    }
  }
  return sap.port?.description;
};

export class TopologyFactory {
  private static AGGSP = "Sn8AggregatedServicePortSubscription";
  private static SP = "Sn8ServicePortSubscription";
  private static MSC = "Sn8MscSubscription";
  private static IRB = "Sn8IrbServicePortSubscription";
  private static FW = "FwSubscription";
  private static IPS = "Sn8IpStaticSubscription";
  private static IPBGP = "Sn8IpBgpSubscription";
  private static L3VPN = "Sn8L3VpnSubscription";
  private static L2VPN = "Sn8L2VpnSubscription";
  private static LP = "Sn8LightPathSubscription";
  private static LR = "Sn8LightPathRedundantSubscription";
  private static WIRELESS = "SurfWirelessSubscription";

  public static create(subscriptionData: any) {
    switch (subscriptionData.subscriptionType) {
      case TopologyFactory.FW:
        return TopologyFactory.generate_fw_topology(subscriptionData);
      case TopologyFactory.IPBGP:
        return TopologyFactory.generate_ip_bgp_topology(subscriptionData);
      case TopologyFactory.IPS:
        return TopologyFactory.generate_ip_static_topology(subscriptionData);
      case TopologyFactory.L2VPN:
        return TopologyFactory.generate_l2vpn_topology(subscriptionData);
      case TopologyFactory.L3VPN:
        return TopologyFactory.generate_l3vpn_topology(subscriptionData);
      case TopologyFactory.LP:
        return TopologyFactory.generate_lightpath_topology(subscriptionData);
      case TopologyFactory.LR:
        return TopologyFactory.generate_lightpath_redundant_topology(subscriptionData);
    }

    return null;
  }

  private static generate_fw_topology(subscriptionData: any) {
    const firewall = subscriptionData["firewall"];

    const fw_object = new TopologyObject(firewall["subscriptionInstanceId"], TopologyObject.FIREWALL);

    let response = [];
    response = response.concat(TopologyFactory.generate_l2_endpoint_topology(firewall, fw_object));
    response = response.concat(TopologyFactory.generate_l3_endpoint_topology(firewall, fw_object));

    if (firewall["ipGwEndpoint"]) {
      response.push([fw_object, TopologyFactory.generate_ip_gw_endpoint_topology(firewall)]);
    }

    return response;
  }

  private static generate_l3_endpoint_topology(firewall, fw_object) {
    let response = [];
    const l3_endpoints_with_l2vpn = [];
    firewall.l3Endpoints.forEach((l3_endpoint) => {
      // optimistisch
      const otherSubscriptions = l3_endpoint.saps[0].otherSubscriptions;
      if (otherSubscriptions && otherSubscriptions.length) {
        const otherSubscription = one(otherSubscriptions);
        l3_endpoints_with_l2vpn.push([
          l3_endpoint, //
          otherSubscription, // one(other_subscriptions)
        ]);
      }
    });

    for (const [l3_endpoint, l3vpn] of l3_endpoints_with_l2vpn || []) {
      const l3vpn_object = new TopologyObject(
        l3vpn["vc"]["subscriptionInstanceId"],
        TopologyObject.L3VPN,
        l3vpn["subscriptionId"],
      );

      response.push([fw_object, l3vpn_object]);

      const esi_ids = firewall.l3Endpoints.map((l3_endpoint_sap) => l3_endpoint_sap["subscriptionInstanceId"]);

      for (const sap of l3vpn["vc"]["saps"] || []) {
        if (!esi_ids.includes(sap["sap"]["subscriptionInstanceId"])) {
          response.push([l3vpn_object, TopologyFactory.sap_topology_object(sap["sap"])]);
        }
      }
    }
    return response;
  }

  private static generate_l2_endpoint_topology(firewall, fw_object) {
    let response = [];
    const l2_endpoints_with_l2vpn = [];
    firewall.l2Endpoints.forEach((l2_endpoint) => {
      // optimistisch
      const otherSubscriptions = l2_endpoint.esis[0].otherSubscriptions;
      if (otherSubscriptions && otherSubscriptions.length) {
        const otherSubscription = one(otherSubscriptions);
        l2_endpoints_with_l2vpn.push([
          l2_endpoint, //
          otherSubscription, // one(other_subscriptions)
        ]);
      }
    });

    for (const [l2_endpoint, l2vpn] of l2_endpoints_with_l2vpn || []) {
      const esi_ids = l2_endpoint["esis"].map((esi) => esi["subscriptionInstanceId"]);

      const l2vpn_object = new TopologyObject(
        l2vpn["vc"]["subscriptionInstanceId"],
        TopologyObject.L2VPN,
        l2vpn["subscriptionId"],
      );

      response.push([fw_object, l2vpn_object]);

      let esi_object = null;
      for (const esi of l2vpn["vc"]["esis"] || []) {
        if (!esi_ids.includes(esi["subscriptionInstanceId"])) {
          esi_object = new TopologyObject(
            esi["subscriptionInstanceId"],
            TopologyObject.ESI,
            l2vpn["subscriptionId"],
            null,
            esi["title"] ?? "ESI",
          );
        }

        response.push([l2vpn_object, esi_object]);

        for (const sap of esi["saps"] || []) {
          response.push([esi_object, TopologyFactory.sap_topology_object(sap)]);
        }
      }
    }
    return response;
  }

  private static generate_ip_gw_endpoint_topology(firewall: any) {
    const ip_gw_endpoint = firewall["ipGwEndpoint"];
    const ip_gateway_object = new TopologyObject(ip_gw_endpoint["subscriptionInstanceId"], TopologyObject.INTERNET);

    return ip_gateway_object;
  }

  private static generate_lightpath_topology(subscriptionData: any) {
    const vc_object = new TopologyObject(
      subscriptionData["vc"]["subscriptionInstanceId"],
      TopologyObject.VC,
      null,
      null,
    );

    let response = [];
    for (const sap of subscriptionData["vc"]["saps"] || []) {
      response.push([vc_object, TopologyFactory.sap_topology_object(sap)]);
    }

    return response;
  }

  private static sap_topology_object(sap) {
    return new TopologyObject(
      sap["subscriptionInstanceId"],
      TopologyObject.SAP,
      sap["port"]?.["subscriptionId"] ?? sap["ownerSubscriptionId"],
      sap["vlanrange"],
      get_description(sap),
    );
  }

  private static generate_ip_bgp_topology(subscriptionData: any) {
    const vc_object = new TopologyObject(subscriptionData["vc"]["subscriptionInstanceId"], TopologyObject.VC);

    const internet_object = new TopologyObject(subscriptionData["subscriptionId"], TopologyObject.INTERNET, null, null);

    let response = [];
    response.push([vc_object, internet_object]);

    for (const sap of subscriptionData["vc"]["saps"] || []) {
      response.push([vc_object, TopologyFactory.sap_topology_object(sap["sap"])]);
    }

    return response;
  }

  private static generate_l3vpn_topology(subscriptionData: any) {
    const l3vpn_object = new TopologyObject(
      subscriptionData["vc"]["subscriptionInstanceId"],
      TopologyObject.L3VPN,
      null,
      null,
    );

    let response = [];

    for (const sap of subscriptionData["vc"]["saps"] || []) {
      response.push([l3vpn_object, TopologyFactory.sap_topology_object(sap["sap"])]);
    }

    return response;
  }

  private static generate_l2vpn_topology(subscriptionData: any) {
    const l2vpn_object = new TopologyObject(
      subscriptionData["vc"]["subscriptionInstanceId"],
      TopologyObject.L2VPN,
      null,
      null,
    );

    let response = [];

    for (const esi of subscriptionData["vc"]["esis"] || []) {
      const esi_object = new TopologyObject(
        esi["subscriptionInstanceId"],
        TopologyObject.ESI,
        null,
        null,
        esi["name"] ?? "ESI",
      );

      response.push([l2vpn_object, esi_object]);

      for (const sap of esi["saps"] || []) {
        response.push([esi_object, TopologyFactory.sap_topology_object(sap)]);
      }
    }

    return response;
  }

  private static generate_lightpath_redundant_topology(subscriptionData: any) {
    let response = [];

    for (const vc of subscriptionData["lrss"]["vcs"] || []) {
      const vc_object = new TopologyObject(vc["subscriptionInstanceId"], TopologyObject.VC);

      for (const sap of vc["saps"] || []) {
        response.push([vc_object, TopologyFactory.sap_topology_object(sap)]);
      }
    }

    return response;
  }

  private static generate_ip_static_topology(subscriptionData: any) {
    let response = [];

    const vc_object = new TopologyObject(subscriptionData["vc"]["subscriptionInstanceId"], TopologyObject.VC);

    const internet_object = new TopologyObject(subscriptionData["subscriptionId"], TopologyObject.INTERNET);

    response.push([vc_object, internet_object]);
    response.push([vc_object, TopologyFactory.sap_topology_object(subscriptionData["vc"]["sap"]["sap"])]);

    return response;
  }
}
