import { Component, Input } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { DialogNotificationSettingsComponent } from "../../dialogs/dialog-notification-settings/dialog-notification-settings";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: "notification-button-replicated",
  templateUrl: "notification-button.html",
  styleUrls: ["notification-button.scss"],
})
export class NotificationButtonReplicated {
  @Input() subscription;
  @Input() messageBus;
  public active = true;
  private impactDescriptions = {};

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
  ) {
    let receiveNotificationsFor = this.translate.instant("Notification.ReceiveNotificationsFor");
    this.impactDescriptions = {
      Downtime: `${receiveNotificationsFor} ${this.translate.instant("Notification.Status.Downtime")}`,
      "Loss of Resiliency": `${receiveNotificationsFor} ${this.translate.instant("Notification.Status.LossofResiliency")}`,
      "Reduced Redundancy": `${receiveNotificationsFor} ${this.translate.instant("Notification.Status.ReducedRedundancy")}`,
      Never: `${receiveNotificationsFor} ${this.translate.instant("Notification.Status.Never")}`,
    };
  }

  get isActive() {
    if (this.subscription?.minimalImpactNotification === "Never") {
      return false;
    }
    return true;
  }

  get impactDescription() {
    const min_impact = this.subscription?.minimalImpactNotification || "Loss of Resiliency";
    return this.impactDescriptions[min_impact];
  }

  openNotificationSettingsDialog() {
    const dialogRef = this.dialog.open(DialogNotificationSettingsComponent, {
      data: {
        name: this.subscription.description,
        impactSetting: this.subscription?.minimalImpactNotification,
        subscriptionId: this.subscription.subscriptionId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.subscription.minimalImpactNotification = result.minimalImpactNotification;
    });
  }
}
