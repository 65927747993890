/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { DomainRead } from "../../models/domain-read";

export interface ReadDomain$Params {
  organisation_id: string;
  domain_id: string;
  token?: string | null;
}

export function readDomain(
  http: HttpClient,
  rootUrl: string,
  params: ReadDomain$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<DomainRead>> {
  const rb = new RequestBuilder(rootUrl, readDomain.PATH, "get");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.path("domain_id", params.domain_id, {});
    rb.query("token", params.token, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DomainRead>;
    }),
  );
}

readDomain.PATH = "/surfdomeinen/organisations/{organisation_id}/domains/{domain_id}";
