export class ExtendedChartComponent {
  public _chartRef: any;
  public hiddenSeries: any[] = [];

  get chartRef(): any {
    return this._chartRef;
  }

  set chartRef(ref: any) {
    this._chartRef = ref;
  }

  toggleLegendItem(series?: string) {
    const index = this.seriesIndex(series);
    if (index !== -1) {
      if (this.chartRef.series[index].visible) {
        this.chartRef.series[index].hide();
      } else {
        this.chartRef.series[index].show();
      }
    }
  }

  seriesIndex(series: string) {
    // avoid recalculation of length on each iteration
    // and don't fail when series is undef
    const length = this.chartRef?.series?.length ?? 0;
    for (let i = 0; i < length; i++) {
      if (this.chartRef.series[i].name === series) {
        return i;
      }
    }
    return -1;
  }

  isSeriesHidden(series: string) {
    const index = this.seriesIndex(series);
    if (index !== -1) {
      return !this.chartRef.series[index].visible;
    }
    return true;
  }

  export(format: string) {
    switch (format) {
      case "csv":
        this.chartRef.downloadCSV();
        break;
      case "png":
        this.chartRef.exportChart();
        break;
    }
  }
}
