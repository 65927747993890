<div class="webarchive">
  <div class="webarchive__title">
    {{ 'Malfunction.ActivityOverTime' | translate }}
    <span>{{ filterSubscriptionName }}</span>
  </div>
  <div class="webarchive__card">
    <div *ngIf="loading" class="webarchive__placeholder"></div>
    <div *ngIf="!loading" class="webarchive__items">
      <div
        class="webarchive__item"
        *ngFor="let item of webarchiveData | keyvalue" 
        (click)="toggleCalendar(item.key)"
        [ngClass]="{'active': item.key === activeYear, 'zero': ticketsAreZero(item.key, item.value['tickets']) }"
      >
        <div class="webarchive__chart-box">
          <div class="webarchive__chart">
            <span
              *ngFor="let amount of item.value['tickets']"
              [ngStyle]="{'animation-delay': amount * 100 + 'ms'}"
            >
              <i
                [style.height.%]="amount * 10"
                [ngClass]="{'red': amount > 10}"
              ></i>
            </span>
          </div>
        </div>
        <div class="webarchive__chart-title">
          <span>{{ item.key }}</span>
          <svg-icon
            class="webarchive__chart-arrow"
            src="assets/images/icons/chevron-down.svg"
          >
          </svg-icon>
        </div>
      </div>
    </div>
    <div class="webarchive__expand" *ngIf="activeYear">
      <webarchive-calendar
        [year]="activeYear"
        [webarchiveData]="webarchiveData"
      ></webarchive-calendar>
    </div>
  </div>
</div>
