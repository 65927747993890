import { gql } from "apollo-angular";

const IpStaticTopologyFragment = gql`
  fragment IpStaticTopology on SubscriptionInterface {
    ... on Sn8IpStaticSubscription {
      subscriptionId
      customerId
      vc {
        subscriptionInstanceId
        sap {
          sap {
            subscriptionInstanceId
            vlanrange
            ownerSubscriptionId
            port {
              subscriptionId
              description
              customerDescriptions {
                customerId
                description
              }
            }
          }
        }
      }
    }
  }
`;

export { IpStaticTopologyFragment };
