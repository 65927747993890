import { Component, OnInit, ViewChild } from "@angular/core";
import { MatomoTracker } from "ngx-matomo";
import { MalfunctionFilterType } from "../../helpers/types";
import { SubscriptionService } from "../../services/subscriptionservice";
import { AuthService } from "../../services/AuthService";
import { ApiHelper } from "../../helpers/apihelper";
import { formatDate, Location } from "@angular/common";
import { MalfunctionFilterComponent } from "../../components/malfunction/malfunction-filter/malfunction-filter";
import { MessageService } from "../../services/messageservice";
import { Subscription as RxSubscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { Apollo } from "apollo-angular";
import { cimToPlannedWork } from "../../helpers/subscription/planned-work";
import { StorageHelper } from "../../helpers/storage/storagehelper";
import { CIM_QUERY } from "../../graphql/domain/cim";
import { CimResult } from "../../graphql/custom-types";
import { Impact, NotificationFilter, ProcessState } from "../../gql/generated";

@Component({
  selector: "page-malfunction",
  templateUrl: "malfunction.html",
  styleUrls: ["malfunction.scss"],
})
export class MalfunctionPage implements OnInit {
  @ViewChild(MalfunctionFilterComponent) filter;
  public cimMessages: any[] = [];
  public filteredMessages: any[] = [];
  public filterData: MalfunctionFilterType;
  public activeTypeFilters: string[] = [];
  public activeImpactFilters: string[] = [];
  public filterSubscriptionId = "";
  public filterSubscriptionName = "";
  public presetSubscriptionId = "none";
  public presetProductType = "none";
  public loading = true;
  public customerGuid: string;
  public filterProductType = "";
  public hasMore = true;
  private loadMoreActive = false;
  private subEnableFilter: RxSubscription;
  private subDisableFilter: RxSubscription;
  private apolloQuery: any;

  constructor(
    private matomo: MatomoTracker,
    public auth: AuthService,
    public api: ApiHelper,
    private msgsrv: MessageService,
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private location: Location,
    private apollo: Apollo,
  ) {
    matomo.setDocumentTitle("Malfunction");

    this.presetSubscriptionId = this.route.snapshot.paramMap.get("subscriptionId");
    this.presetProductType = this.route.snapshot.paramMap.get("productType");
    this.customerGuid = localStorage.getItem("viewingCustomerGUID");

    auth.userLoaded.subscribe((d) => {
      this.validateCustomerGuid();
      this.triggerFilter();
    });

    this.msgsrv.enableMalfunctionFilter$.subscribe((filter: MalfunctionFilterType) => {
      this.filterData = filter;
      this.triggerFilter();
    });

    this.subEnableFilter = msgsrv.enableFilter$.subscribe((filter) => {
      switch (filter.type) {
        case "type":
          this.activeTypeFilters.push(filter.value);
          break;
        case "impact":
          this.activeImpactFilters.push(filter.value);
          break;
        default:
          break;
      }

      this.adjustFilter();
    });

    this.subDisableFilter = msgsrv.disableFilter$.subscribe((filter) => {
      switch (filter.type) {
        case "type":
          const filterIndex = this.activeTypeFilters.indexOf(filter.value);
          if (filterIndex > -1) {
            this.activeTypeFilters.splice(filterIndex, 1);
          }
          break;
        case "impact":
          const tagIndex = this.activeImpactFilters.indexOf(filter.value);
          if (tagIndex > -1) {
            this.activeImpactFilters.splice(tagIndex, 1);
          }
          break;
        default:
          break;
      }

      this.adjustFilter();
    });
  }

  get messages() {
    // return sortNotifications(this.imsMessages.concat(this.cimMessages));
    return this.cimMessages.sort((a, b) => (a.start_timestamp > b.start_timestamp ? -1 : 1));
  }

  triggerFilter() {
    if (!this.auth.state.viewingCustomer.customerId) {
      return;
    }

    if (this.filterData) {
      this.filterSubscriptionId = this.filterData.subscriptionId;
      this.filterSubscriptionName = this.filterData.subscriptionName;
      this.filterProductType = this.filterData.productType;
      this.loadMoreActive = this.filterData.loadMoreActive;
      this.hasMore = this.filterData.hasMore ?? true;
      this.updateUrl();

      this.fetchCimNotifications(
        this.filterData.productType,
        this.filterData.subscriptionId,
        this.filterData.filterStartDate,
        this.filterData.filterEndDate,
      );
      this.filterData = null;
    }
  }

  updateUrl() {
    let newUrl = "storing-en-onderhoud";
    if (this.filterProductType !== "") {
      newUrl += "/" + this.filterProductType;
    }

    if (this.filterSubscriptionId !== "") {
      newUrl += "/" + this.filterSubscriptionId;
    }

    this.location.replaceState(newUrl);
  }

  ngOnInit() {
    this.matomo.trackPageView();
  }

  async fetchCimNotifications(productType: string, subscriptionId: string, filterStartDate: any, filterEndDate: any) {
    this.loading = true;
    const customerId = StorageHelper.currentUser;
    const filter: NotificationFilter = {
      customerId,
      beginTimestamp: filterStartDate,
      endTimestamp: filterEndDate,
      impacts: [Impact.Down, Impact.ReducedRedundancy, Impact.ResilienceLoss, Impact.Unknown],
      statuses: [ProcessState.Open, ProcessState.Updated, ProcessState.Closed],
    };
    if (subscriptionId) {
      filter.subscriptionIds = [subscriptionId];
    } else {
      filter.productTypes = productType ? [productType] : [];
    }
    this.apollo
      .watchQuery<CimResult>({
        query: CIM_QUERY,
        variables: { filter },
      })
      .valueChanges.subscribe(({ data }) => {
        const result = data.notifications
          .map((notification) => cimToPlannedWork(notification, false))
          .sort((a, b) => (a.start_timestamp > b.start_timestamp ? -1 : 1));

        // if data is loaded from 'load more', append it to the existing set
        if (this.loadMoreActive) {
          if (result) {
            this.cimMessages = this.cimMessages.concat(result);
          } else {
            this.hasMore = false; // no more data to load; disable load more button
          }
        } else {
          // replace result
          this.cimMessages = result;
        }

        this.adjustFilter();
        this.loading = false;
      });
  }

  /**
   * validate the customerGuid and force a switchViewingCustomer
   */
  validateCustomerGuid() {
    // if no presets, don't run the validation
    if (!this.presetSubscriptionId || !this.presetProductType) {
      return;
    }
  }

  /**
   * adjustFilter Type / Impact filter
   * */
  adjustFilter() {
    this.filteredMessages = this.messages.filter((item) => {
      if (this.checkActivityTypeFilter(item) && this.checkImpactFilter(item)) {
        return true;
      }

      return false;
    });
  }

  /**
   * check Type value
   * */
  checkActivityTypeFilter(item) {
    if (this.activeTypeFilters.length === 0) {
      return true;
    }
    return this.activeTypeFilters.includes(item.category);
  }

  /**
   * check Impact value
   * */
  checkImpactFilter(item) {
    if (this.activeImpactFilters.length === 0) {
      return true;
    }

    let impactItemFound = false;
    this.activeImpactFilters.forEach((value) => {
      if (item.tags?.includes(value)) {
        impactItemFound = true;
      }
    });

    return impactItemFound;
  }

  /**
   * compare the date of tis element to the dat of the prev.
   * */
  isNewDate(index) {
    if (index === 0) {
      return true;
    }

    if (this.messages[index - 1] !== undefined) {
      const prevElementDate = formatDate(this.messages[index - 1].start_timestamp, "yyyy-MM-dd", "en-US");
      const thisElementDate = formatDate(this.messages[index].start_timestamp, "yyyy-MM-dd", "en-US");

      if (prevElementDate === thisElementDate) {
        return false;
      }
    }

    return true;
  }
}
