<div class="title-flex" style="margin: 0">
  <h3 class="title-with-icon"><svg-icon src="assets/images/icons/chart.svg"></svg-icon><span>System stats</span></h3>
</div>
<div class="card card--no-padding">
  <div class="system-stats__header">
    <div class="chart__header-right">
      <div class="chart__header-right-flex">
        <label for="" class="chart__select-label">
          <span>{{ 'Chart.Period' | translate }}</span>
          <span class="chart__select-reset" (click)="resetDatePickers()" *ngIf="!defaultDate">Reset</span>
        </label>
        <div class="chart__period-filter">
          <button
            *ngFor="let preset of presets"
            [ngClass]="{'active': selectedPreset === preset}"
            (click)="setDatePreset(preset)">
            {{ preset }}
          </button>
        </div>
      </div>
      <div class="chart__header-date-inputs">
        <div class="chart__select-wrapper">
          <input
            class="chart__input"
            type="text"
            id="startDate"
            mwlFlatpickr
            [enableTime]="true"
            [dateFormat]="'d-m-Y H:i'"
            [time24hr]="true"
            [convertModelValue]="true"
            [disableMobile]="true"
            [(ngModel)]="firstDate"
            [prevArrow]="'<i></i>'"
            [nextArrow]="'<i></i>'"
            [maxDate]="tomorrow"
            (ngModelChange)="onDateChange()" />
          <svg-icon class="chart__select-calendar-icon" src="assets/images/icons/select-calendar.svg"> </svg-icon>
        </div>
        <span class="chart__header-date-seperator">-</span>
        <div class="chart__select-wrapper">
          <input
            class="chart__input"
            type="text"
            mwlFlatpickr
            [enableTime]="true"
            [dateFormat]="'d-m-Y H:i'"
            [time24hr]="true"
            [convertModelValue]="true"
            [disableMobile]="true"
            [(ngModel)]="secondDate"
            [maxDate]="tomorrow"
            (ngModelChange)="onDateChange()" />
          <svg-icon class="chart__select-calendar-icon" src="assets/images/icons/select-calendar.svg"> </svg-icon>
        </div>
      </div>
    </div>
  </div>
  <div class="system-stats">
    <div class="system-stats__col">
      <div class="system-stats__box">
        <div class="system-stats__box-title">CPU Usage</div>
        <ng-container *ngIf="retrievingChartData; then chartLoading; else cpuUsageChart;"></ng-container>
        <ng-template #chartLoading><loader [iconOnly]="true"></loader></ng-template>
        <ng-template #cpuUsageChart>
          <highcharts-chart
            [Highcharts]="highcharts"
            [options]="chartOptionsCPUUsage"
            (chartInstance)="setCpuUsageChartInstance($event)">
          </highcharts-chart>
        </ng-template>
      </div>
    </div>
    <div class="system-stats__col">
      <div class="system-stats__box">
        <div class="system-stats__box-title">Memory Usage</div>
        <ng-container *ngIf="retrievingChartData; then chartLoading; else memoryUsageChart;"></ng-container>
        <ng-template #memoryUsageChart>
          <highcharts-chart
            [Highcharts]="highcharts"
            [options]="chartOptionsMemoryUsage"
            (chartInstance)="setMemoryUsageChartInstance($event)">
          </highcharts-chart>
        </ng-template>
      </div>
    </div>
    <div class="system-stats__col">
      <div class="system-stats__box">
        <div class="system-stats__box-title">Sessions</div>
        <ng-container *ngIf="retrievingChartData; then chartLoading; else sessionChart;"></ng-container>
        <ng-template #sessionChart>
          <highcharts-chart
            [Highcharts]="highcharts"
            [options]="chartOptionsSessions"
            (chartInstance)="setSessionsChartInstance($event)">
          </highcharts-chart>
        </ng-template>
      </div>
    </div>
    <div class="system-stats__col">
      <div class="system-stats__box">
        <div class="system-stats__box-title">Session Rate</div>
        <ng-container *ngIf="retrievingChartData; then chartLoading; else sessionRateChart;"></ng-container>
        <ng-template #sessionRateChart>
          <highcharts-chart
            [Highcharts]="highcharts"
            [options]="chartOptionsSessionRate"
            (chartInstance)="setSessionRateChartInstance($event)">
          </highcharts-chart>
        </ng-template>
      </div>
    </div>
  </div>
</div>
