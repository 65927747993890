import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MessageService } from "../../../services/messageservice";
import { ApiHelper } from "../../../helpers/apihelper";
import { Instance } from "../../models/instance";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { ViewportScroller } from "@angular/common";
import { ReadableSizePipe } from "../../../helpers/bitspipe/bitspipe";

@Component({
  selector: "service-summary",
  templateUrl: "service-summary.html",
})
export class ServiceSummary implements OnChanges {
  @Input() service: any;
  @Input() gateway: any;
  @Input() portId: string;
  @Input() subscriptionInstanceId: string;
  @Input() showTraffic: boolean;
  @Input() forceTrafficBottom = false;
  @Input() showTrafficButton: boolean;
  @Input() showMoreDetailsButton = true;
  @Input() vlanranges: any[];
  @Input() endpoint: any;
  @Input() parent: any;
  public isAccessible: boolean;
  public healthData: any;
  public sortedTags = [];

  constructor(
    private msgsrv: MessageService,
    private api: ApiHelper,
    private subscriptionService: SubscriptionService,
    private viewportScroller: ViewportScroller,
  ) {
    this.vlanranges = [];
  }

  get organisationName(): string {
    if (typeof this.service.organisation === "string") {
      return this.service.organisation;
    }
    if (this.service.organisation?.name) {
      return this.service.organisation.name;
    }
    return "-";
  }

  get traffic() {
    if (!this.showTraffic || !this.healthData) {
      return null;
    }

    const bitsPipe = new ReadableSizePipe();

    const payload = {
      up: { speed: 0, unit: "" },
      down: { speed: 0, unit: "" },
    };

    const info = {
      up: bitsPipe.transform(this.healthData.trafficIn),
      down: bitsPipe.transform(this.healthData.trafficOut),
    };
    for (const speedKey of Object.keys(info)) {
      const speedInfo = info[speedKey];
      const parts = speedInfo.split(" ");
      if (parts.length <= 1) {
        continue;
      }

      payload[speedKey].speed = parts[0];
      payload[speedKey].unit = parts[1];
    }

    return payload;
  }

  get locations() {
    if (!this.service) {
      return [];
    }

    if (this.service.address) {
      return [`${this.service.address.city} (${this.service.address.street} ${this.service.address.number})`];
    }

    // L2VPN
    let addresses = [];
    for (const esi of this.service.esis || []) {
      for (const endpoint of esi.endpoints || []) {
        if (!endpoint.port || !endpoint.port.address || !endpoint.port.address.city) {
          continue;
        }

        addresses.push(
          `${endpoint.port.address.city} (${endpoint.port.address.street} ${endpoint.port.address.number})`,
        );
      }
    }

    // L3VPN
    for (const l3vpnsaps of this.service.l3vpnsapss || []) {
      if (!l3vpnsaps.sap || !l3vpnsaps.sap.port || !l3vpnsaps.sap.port.address || !l3vpnsaps.sap.port.address.city) {
        continue;
      }

      addresses.push(
        `${l3vpnsaps.sap.port.address.city} (${l3vpnsaps.sap.port.address.street} ${l3vpnsaps.sap.port.address.number})`,
      );
    }

    // LightPath
    for (const sap of this.service._ports || []) {
      if (sap?.port?.address?.city) {
        addresses.push(`${sap.port.address.city} (${sap.port.address.street} ${sap.port.address.number})`);
      }
    }

    // remove duplicates
    const uniqueAddresses = [...new Set(addresses)];

    return uniqueAddresses;
  }

  get icon() {
    if (!this.service || !this.service.product) {
      return "ip";
    }

    if (this.service.ipGateway) {
      return "ip";
    }

    return this.service.product.productType.toLowerCase();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["portId"] && this.portId !== null) {
      if (this.subscriptionService.canViewSubscription(this.service)) {
        this.loadPortService(this.portId);
      }
    }
    if (changes["service"]) {
      this.isAccessible = this.subscriptionService.canViewSubscription(this.service);

      if (this.isAccessible) {
        this.subscriptionService
          .getHealthDataForSubscription(
            this.service.subscriptionId,
            this.service.product.productType === "FW" ? this.subscriptionInstanceId : null, // Optional
          )
          .then((status) => {
            const trafficIn = status.traffic.last.in;
            const trafficOut = status.traffic.last.out;

            this.healthData = {
              status: status.status,
              trafficIn,
              trafficOut,
            };
          });
      }
    }
  }

  loadPortService(portId: string) {
    this.api.raw_details("Port", portId).then((port) => {
      this.service.port = Object.assign(new Instance(), port);
    });
  }

  navigateToChart(duration?: number) {
    this.viewportScroller.scrollToAnchor("chart");
    const filterProps = {
      connection: this.service,
      vlanranges: this.vlanranges,
    };

    this.msgsrv.setChartFilter(filterProps);
  }
}
