import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { MessageService } from "../../../services/messageservice";
import { ApiHelper } from "../../../helpers/apihelper";
import { Instance } from "../../models/instance";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { ViewportScroller } from "@angular/common";
import { ReadableSizePipe } from "../../../helpers/bitspipe/bitspipe";
import { ServiceType } from "../../../gql/generated-ingestor";
import { LocationType } from "../../../gql/generated-ingestor";
import { getDetailRouterLink } from "../../../helpers/getDetailRouterLink";

@Component({
  selector: "service-summary-replicated",
  templateUrl: "service-summary.html",
})
export class ServiceSummaryReplicated implements OnChanges {
  @Input() service: any;
  @Input() gateway: any;
  @Input() portId: string;
  @Input() subscriptionInstanceId: string;
  @Input() showTraffic: boolean;
  @Input() forceTrafficBottom = false;
  @Input() showTrafficButton: boolean;
  @Input() showMoreDetailsButton = true;
  @Input() vlanranges: any[];
  @Input() endpoint: any;
  @Input() parent: any;
  public isAccessible: boolean;
  public healthData: any;
  public sortedTags = [];
  public icon = "ip";

  constructor(
    private msgsrv: MessageService,
    private api: ApiHelper,
    private subscriptionService: SubscriptionService,
    private viewportScroller: ViewportScroller,
  ) {
    this.vlanranges = [];
  }

  get organisationName(): string {
    if (typeof this.service.organisation === "string") {
      return this.service.organisation;
    }
    if (this.service?.customer?.fullname) {
      return this.service?.customer?.fullname;
    }

    return "-";
  }

  get traffic() {
    if (!this.showTraffic || !this.healthData) {
      return null;
    }

    const bitsPipe = new ReadableSizePipe();

    const payload = {
      up: { speed: 0, unit: "" },
      down: { speed: 0, unit: "" },
    };

    const info = {
      up: bitsPipe.transform(this.healthData.trafficIn),
      down: bitsPipe.transform(this.healthData.trafficOut),
    };
    for (const speedKey of Object.keys(info)) {
      const speedInfo = info[speedKey];
      const parts = speedInfo.split(" ");
      if (parts.length <= 1) {
        continue;
      }

      payload[speedKey].speed = parts[0];
      payload[speedKey].unit = parts[1];
    }

    return payload;
  }

  get locations(): LocationType[] {
    return this.service.locations || [];
  }

  async setIcon() {
    if (this.service?.ipGateway) {
      this.icon = "ip";
    } else if (!this.service?.product) {
      this.icon = "ip";
    } else {
      this.icon = this.service?.product?.type?.toLowerCase();
    }
  }

  routerLinkForService(service: ServiceType): string[] {
    return [getDetailRouterLink(service, service.product.type)];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["service"]) {
      this.isAccessible = this.subscriptionService.canViewSubscription(this.service);
      this.setIcon();

      if (this.isAccessible) {
        this.subscriptionService
          .getHealthDataForSubscription(
            this.service.subscriptionId,
            this.service.product.type === "FW" ? this.subscriptionInstanceId : null, // Optional
          )
          .then((status) => {
            const trafficIn = status.traffic.last.in;
            const trafficOut = status.traffic.last.out;

            this.healthData = {
              status: status.status,
              trafficIn,
              trafficOut,
            };
          });
      }
    }
  }

  loadPortService(portId: string) {
    this.api.raw_details("Port", portId).then((port) => {
      this.service.port = Object.assign(new Instance(), port);
    });
  }

  navigateToChart(duration?: number) {
    this.viewportScroller.scrollToAnchor("chart");
    const filterProps = {
      connection: this.service,
      vlanranges: this.vlanranges,
    };

    this.msgsrv.setChartFilter(filterProps);
  }
}
