import { Component, Input } from "@angular/core";

import { MatDialog } from "@angular/material/dialog";
import { DialogActivityComponent } from "../../../components/dialogs/dialog-activity/dialog-activity";
import { Notification } from "../../../gql/generated";

@Component({
  selector: "activity-container-replicated",
  templateUrl: "activity-container.html",
})
export class ActivityContainerReplication {
  @Input() messages: Notification[] = [];
  constructor(public dialog: MatDialog) {}

  openDialog(message) {
    const dialogRef = this.dialog.open(DialogActivityComponent, {
      data: message,
    });
  }

  trackByFn(index, item) {
    return item.id;
  }
}
