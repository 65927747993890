import { ServicePort, VirtualCircuit } from "./models/types";

export class ModifyLightPath {
  protected remote_port_shutdown: boolean;
  protected speed_policer: boolean;
  private service_ports: ServicePort[];

  get payload(): object {
    return {
      service_ports: this.service_ports,
      remote_port_shutdown: this.remote_port_shutdown,
      speed_policer: this.speed_policer,
    };
  }

  set speedPolicer(val: boolean) {
    this.speed_policer = val;
  }

  set remotePortShutdown(val: boolean) {
    this.remote_port_shutdown = val;
  }

  set subscription(sub: any) {
    this.speed_policer = sub._circuits[0].speedPolicer;
    this.remote_port_shutdown = sub._circuits[0].remotePortShutdown;

    this.service_ports = sub._circuits[0].endpoints.map(
      (endpoint) =>
        ({
          subscription_id: endpoint.port.subscriptionId,
          vlan: endpoint.vlanrange,
        }) as ServicePort,
    );
  }

  updateCircuit(index: number, ports: ServicePort[]) {
    this.service_ports = ports;
  }

  check(): boolean {
    return this.service_ports.length === 2;
  }
}

export class ModifyRedundantLightPath extends ModifyLightPath {
  private virtual_circuits: VirtualCircuit[];

  get payload() {
    return {
      virtual_circuits: this.virtual_circuits,
      remote_port_shutdown: this.remote_port_shutdown,
      speed_policer: this.speed_policer,
    };
  }

  set subscription(sub: any) {
    this.speed_policer = sub._circuits[0].speedPolicer;
    this.remote_port_shutdown = sub._circuits[0].remotePortShutdown;

    this.virtual_circuits = sub._circuits.map((circuit) => ({
      service_ports: circuit.endpoints.map(
        (endpoint) =>
          ({
            subscription_id: endpoint.port.subscriptionId,
            vlan: endpoint.vlanrange,
          }) as ServicePort,
      ),
    }));
  }
  updateCircuit(index: number, ports: ServicePort[]) {
    this.virtual_circuits[index].service_ports = ports;
  }
}
