import { Component, Input, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { Instance } from "../../models/instance";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { InstanceFactory } from "../../models/base/instancefactory";

@Component({
  selector: "subscription-detail-container",
  templateUrl: "subscription-detail-container.html",
})
export class SubscriptionDetailContainer implements OnChanges {
  @Input() service: Instance;
  @Input() productType: string;
  @Input() services: Instance[] = [];
  public activeSubscription: Instance;
  public customerGuid: string;
  public activeSubscriptionVlanranges: any[] = [];

  constructor(public subscriptionService: SubscriptionService) {
    this.customerGuid = localStorage.getItem("viewingCustomerGUID");
  }

  get locationsForSubscription() {
    if (!this.activeSubscription) {
      return [];
    }

    const values = this.activeSubscription._ports.reduce((all, el) => {
      all[`${el.port.address.city} (${el.port.surfLocationCode})`] = true;
      return all;
    }, {});
    return Object.keys(values);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.service || this.service.services === undefined) {
      return;
    }
    this.services = (this.service.services || []).map((service) => Object.assign(new Instance(), service));
    if (!this.activeSubscription && Array.isArray(this.services) && this.services.length > 0) {
      this.activeSubscription = this.services[0];
      this.getVlanIds();
    }
  }

  setActiveSubscription(sub) {
    this.activeSubscription = null;

    setTimeout(() => {
      this.activeSubscription = sub;
      this.getVlanIds();
    }, 1);
  }

  getVlanIds() {
    if (!this.activeSubscription) {
      return;
    }
    this.activeSubscriptionVlanranges = [this.activeSubscription.vlanrange];
  }

  OnClickBar(subscriptionId) {
    const findSubscription = this.services.find((x) => x.subscriptionId === subscriptionId);
    this.setActiveSubscription(findSubscription);
  }
}
