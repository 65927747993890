/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { DomainType } from "../../models/domain-type";
import { DomainZone } from "../../models/domain-zone";
import { ZoneType } from "../../models/zone-type";

export interface GetOverviewCombined$Params {
  organisation_id: string;

  /**
   * The sort will accept parameters like `col` or `col:desc` and will split on the `:`. If it does not find a `:` it will sort ascending on that column. Sortable columns: name, domain_type, domain_status, domain_dnssec_keys_cnt, zone_type, zone_status, zone_dnssec_status
   */
  sort?: Array<string> | null;
  name?: string | null;
  zone_type?: ZoneType | null;
  domain_type?: DomainType | null;
  token?: string | null;
  offset?: number;
  limit?: number;
}

export function getOverviewCombined(
  http: HttpClient,
  rootUrl: string,
  params: GetOverviewCombined$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<DomainZone>>> {
  const rb = new RequestBuilder(rootUrl, getOverviewCombined.PATH, "get");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.query("sort", params.sort, {});
    rb.query("name", params.name, {});
    rb.query("zone_type", params.zone_type, {});
    rb.query("domain_type", params.domain_type, {});
    rb.query("token", params.token, {});
    rb.query("offset", params.offset, {});
    rb.query("limit", params.limit, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<DomainZone>>;
    }),
  );
}

getOverviewCombined.PATH = "/surfdomeinen/organisations/{organisation_id}/overview/combined";
