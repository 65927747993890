<navigation [activeItem]="" [requestsBubble]="3"></navigation>
<div class="cert">
  <div class="container">
    <cert-header></cert-header>
    <cert-tabs [activeTab]="activeTab" (tabEmitter)="changeTab($event)"></cert-tabs>
    <section *ngIf="activeTab === 'scan'">
      <div class="cert__filter-and-chart">
        <cert-filter [prefixes]="prefixes" (filtersChanged)="onFiltersChanged($event)"></cert-filter>
        <i class="cert__filter-divider">
          <span></span>
          <span></span>
          <span></span>
        </i>
        <cert-chart [searchFilter]="searchFilter"></cert-chart>
      </div>
      <cert-table
        [airtScans]="airtScans"
        [pageInfo]="pageInfo"
        (paginationChanged)="onPaginationChanged($event)"
        (sortingChanged)="onSortingChanged($event)"></cert-table>
    </section>

    <section *ngIf="activeTab === 'ddos'">
      <cert-mitigation></cert-mitigation>
      <cert-security></cert-security>
      <cert-ddos-chart [happyState]="true"></cert-ddos-chart>
    </section>

    <footer></footer>
  </div>
</div>
