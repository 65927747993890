<div class="row">
  <div class="col-12 col-lg-6">
    <div class="card card--no-padding">
      <div class="kpi-table">
        <header class="kpi-table__header">2.4 GHz</header>
        <div class="kpi-table__row">
          <div class="kpi-table__col"></div>
          <div
            *ngFor="let item of kpiItems; index as i"
            class="kpi-table__col"
            [matTooltip]="item.name"
            [matTooltipPosition]="'above'"
          >
            <h4 class="truncate">KPI {{ i + 1 }}</h4>
            <div class="truncate kpi-table__subtitle">{{ item.name }}</div>
          </div>
        </div>
        <div *ngFor="let row of kpiRowsLeft" class="kpi-table__row">
          <div class="kpi-table__col">
            <div class="truncate">{{ 'kpi.days' + row.days | translate }}</div>
          </div>
          <div
            *ngFor="let col of row.values"
            class="kpi-table__col kpi-table__col--{{ getStatus(col.value, col.kpi.danger, col.kpi.warning) }}"
          >
            <!--  "| number: '1.1'" is added to ensure always 1 decimal is shown -->
            {{ col.value !== undefined ? (col.value | number: '1.1') : '?' }}
            <span *ngIf="col.kpi.percentage && col.value !== undefined"
              >%</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-6">
    <div class="card card--no-padding">
      <div class="kpi-table">
        <header class="kpi-table__header">5 GHz</header>
        <div class="kpi-table__row">
          <div class="kpi-table__col"></div>
          <div
            *ngFor="let item of kpiItems; index as i"
            class="kpi-table__col"
            [matTooltip]="item.name"
            [matTooltipPosition]="'above'"
          >
            <h4 class="truncate">KPI {{ i + 1 }}</h4>
            <div class="truncate kpi-table__subtitle">{{ item.name }}</div>
          </div>
        </div>
        <div *ngFor="let row of kpiRowsRight" class="kpi-table__row">
          <div class="kpi-table__col">
            <div class="truncate">{{ 'kpi.days' + row.days | translate }}</div>
          </div>
          <div
            *ngFor="let col of row.values"
            class="kpi-table__col kpi-table__col--{{ getStatus(col.value, col.kpi.danger, col.kpi.warning) }}"
          >
            <!--  "| number: '1.1'" is added to ensure always 1 decimal is shown -->
            {{ col.value !== undefined ? (col.value | number: '1.1') : '?' }}
            <span *ngIf="col.kpi.percentage && col.value !== undefined"
              >%</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
