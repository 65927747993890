/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { HistoryAction } from "../../models/history-action";
import { HistoryObjectType } from "../../models/history-object-type";
import { HistoryRead } from "../../models/history-read";

export interface ReadHistory$Params {
  organisation_id: string;

  /**
   * The sort will accept parameters like `col` or `col:desc` and will split on the `:`. If it does not find a `:` it will sort ascending on that column. Sortable columns: id, datetime, object_type, object_id, object_name, action
   */
  sort?: Array<string> | null;
  after?: string | null;
  before?: string | null;
  object_type?: HistoryObjectType | null;
  object_id?: number | null;
  object_name?: string | null;
  action?: HistoryAction | null;
  data?: string | null;
  token?: string | null;
  offset?: number;
  limit?: number;
}

export function readHistory(
  http: HttpClient,
  rootUrl: string,
  params: ReadHistory$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<HistoryRead>>> {
  const rb = new RequestBuilder(rootUrl, readHistory.PATH, "get");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.query("sort", params.sort, {});
    rb.query("after", params.after, {});
    rb.query("before", params.before, {});
    rb.query("object_type", params.object_type, {});
    rb.query("object_id", params.object_id, {});
    rb.query("object_name", params.object_name, {});
    rb.query("action", params.action, {});
    rb.query("data", params.data, {});
    rb.query("token", params.token, {});
    rb.query("offset", params.offset, {});
    rb.query("limit", params.limit, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<HistoryRead>>;
    }),
  );
}

readHistory.PATH = "/surfdomeinen/organisations/{organisation_id}/history/";
