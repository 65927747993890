import { Component, Input } from "@angular/core";
import { SubscriptionMessage } from "../../../helpers/types";

import { MatDialog } from "@angular/material/dialog";
import { DialogActivityComponent } from "../../../components/dialogs/dialog-activity/dialog-activity";
import { Notification } from "../../../gql/generated";

@Component({
  selector: "notification-container-replicated",
  templateUrl: "notification-container.html",
})
export class NotificationContainerReplication {
  @Input() messages: Notification[];
  constructor(public dialog: MatDialog) {}
  openDialog(message) {
    const dialogRef = this.dialog.open(DialogActivityComponent, {
      data: message,
    });
  }
}
