import { Component, ViewChild, ElementRef, Input, SimpleChanges, OnChanges } from "@angular/core";
import { AccessPointType } from "../../../gql/generated-ingestor";

@Component({
  selector: "access-points-replicated",
  templateUrl: "access-points.html",
  styleUrls: ["access-points.scss"],
})
export class AccessPointsComponent implements OnChanges {
  @Input() accessPoints: AccessPointType[] | null = [];
  @ViewChild("scrollarea") scrollarea: ElementRef;
  rightDisabled = true;
  leftDisabled = true;
  accessPointSummaries: AccessPointSummary[] = [];

  constructor() {}

  ngOnChanges(_: SimpleChanges) {
    this.accessPointSummaries = this.summarizeAccessPoints(this.accessPoints);
  }

  summarizeAccessPoints(accessPoints: AccessPointType[]): AccessPointSummary[] {
    let accessPointCounts = [];
    accessPoints.forEach((accessPoint) => {
      let brand = accessPoint.brand;
      let model = accessPoint.model;
      let status = accessPoint.ciStatus;

      if (accessPointCounts[brand] === undefined) {
        accessPointCounts[brand] = [];
      }
      if (accessPointCounts[brand][model] === undefined) {
        accessPointCounts[brand][model] = [];
        accessPointCounts[brand][model]["total"] = 0;
      }

      if (accessPointCounts[brand][model][status] === undefined) {
        accessPointCounts[brand][model][status] = 1;
      } else {
        accessPointCounts[brand][model][status] += 1;
      }
      accessPointCounts[brand][model]["total"] += 1;
    });

    let summaries: AccessPointSummary[] = [];
    for (let brand in accessPointCounts) {
      for (let model in accessPointCounts[brand]) {
        const summary = {
          brand,
          model,
          active: accessPointCounts[brand][model]["Active"] ? accessPointCounts[brand][model]["Active"] : "-",
          inactive: accessPointCounts[brand][model]["Stopped"] ? accessPointCounts[brand][model]["Stopped"] : "-",
          total: accessPointCounts[brand][model]["total"] ? accessPointCounts[brand][model]["total"] : "-",
        };
        summaries.push(summary);
      }
    }
    return summaries;
  }

  scrollLeft() {
    this.scrollarea.nativeElement.scrollLeft -= 150;

    setTimeout(() => {
      this.checkScroll();
    }, 500);
  }

  scrollRight() {
    this.scrollarea.nativeElement.scrollLeft += 150;
    setTimeout(() => {
      this.checkScroll();
    }, 500);
  }

  checkScroll() {
    this.leftDisabled = this.scrollarea.nativeElement.scrollLeft === 0;

    let newScrollLeft = this.scrollarea.nativeElement.scrollLeft;
    let width = this.scrollarea.nativeElement.clientWidth;
    let scrollWidth = this.scrollarea.nativeElement.scrollWidth;
    this.rightDisabled = scrollWidth - (newScrollLeft + width) === 0;
  }
}

interface AccessPointSummary {
  brand: string;
  model: string;
  active: number;
  inactive: number;
  total: number;
}
