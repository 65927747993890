import * as pMap from "p-map";
import { ApiHelper } from "../helpers/apihelper";
import { AuthService } from "../services/AuthService";
import { Injectable } from "@angular/core";
import { Instance } from "../components/models/instance";
import { HealthIndicator } from "../components/models/HealthIndicator";
import { SubscriptionCount } from "../helpers/types";

@Injectable()
export class SubscriptionService {
  private customerGuid: string;

  constructor(
    private api: ApiHelper,
    private auth: AuthService,
  ) {
    this.customerGuid = localStorage.getItem("viewingCustomerGUID");
  }

  canViewSubscription(subscription: Instance) {
    // check if customerID is in my list of accessible customers.
    const sid = subscription.customerId || subscription?.customer?.customerId || subscription.port?.customerId || null;

    if (this.customerGuid === sid) {
      return true;
    } else {
      return false;
    }
  }

  async getSubscriptionsOfType(productType: string): Promise<any> {
    return this.api.subscriptionsOfType(this.getCustomerGuid(), productType);
  }

  async getBaseSubscriptionsForList(subscriptionIds: string[]): Promise<any> {
    const mapper = async (subscriptionId: string) =>
      this.api.base_subscription(subscriptionId).catch((err) => console.log("Unable to fetch %s", subscriptionId));
    return await pMap(subscriptionIds, mapper, { concurrency: 2 });
  }

  async getSubscriptionCounts(productType: string, options: any = {}): Promise<SubscriptionCount> {
    const subscriptionCount = await this.api.subscriptionCounts(this.getCustomerGuid(), productType, options);
    return {
      productType,
      ...subscriptionCount,
    };
  }

  async getSubscriptionDetails(productType: string, subscriptionId: string) {
    let productTypeString = productType.toLowerCase();
    console.log(`getSubscriptionDetails(${productType}, ${subscriptionId})`);
    if (!(`${productTypeString}_details` in this.api)) {
      console.warn("Trying to call undefined method", `${productTypeString}_details`);
      return new Promise<any>((resolve, reject) => {
        resolve(null);
      });
    }

    return this.api[`${productTypeString}_details`](subscriptionId);
  }

  async getBaseSubscription(subscriptionId: string) {
    return this.api.base_subscription(subscriptionId);
  }

  async getMessagesBySubscription(subscriptionId: string, activeMsgs: boolean) {
    return this.api.messagesBySubscription(subscriptionId, activeMsgs);
  }

  async getHealthDataForSubscription(subscriptionId: string, instanceId: string = null, productType: string = null) {
    return this.api.health(subscriptionId, instanceId, productType);
  }

  async getWirelessHealthDataForSubscription(subscriptionId: string) {
    return this.api.wireless_health_details(subscriptionId);
  }

  async getWirelessListKpiForSubscription(subscriptionId: string): Promise<any> {
    return this.api.wireless_list_kpi(subscriptionId, false);
  }

  async getAccesspointData(subscriptionId: string) {
    return this.api.wireless_accesspoint_details(subscriptionId);
  }

  async getIpPrefixes(subscriptionId: string) {
    return this.api.ip_prefix(subscriptionId);
  }

  async getSlsReport(subscriptionId: string) {
    return this.api.sls_report(subscriptionId);
  }

  getCustomerGuid(): string {
    // if customerGuid is loaded after constructor call, reload viewingCustomerGUID from localStorage
    if (!this.customerGuid) {
      this.customerGuid = localStorage.getItem("viewingCustomerGUID");
    }
    return this.customerGuid;
  }
}
