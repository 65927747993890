import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Sort } from "@angular/material/sort";
import { Apollo } from "apollo-angular";
import { CERT_MITIGATION_PREFIXES_QUERY } from "../../../graphql/component/cert-mitigation";
import { CustomerWithSubscriptionIds } from "../../../graphql/custom-types";
import { ApiHelper } from "../../../helpers/apihelper";
import { AuthService } from "../../../services/AuthService";
import { DialogAuthComponent } from "../../dialogs/dialog-auth/dialog-auth";
import { DialogSelfserviceComponent } from "../../dialogs/dialog-selfservice/dialog-selfservice";
import { InstanceFactory } from "../../models/base/instancefactory";
import { IpPrefixInstance } from "../../models/ipprefixinstance";
import { LoadingState } from "../../../services/loading-state";

@Component({
  selector: "cert-mitigation",
  templateUrl: "cert-mitigation.html",
  styleUrls: ["cert-mitigation.scss"],
})
export class CertMitigationComponent implements OnInit {
  public subscriptions: IpPrefixInstance[] = [];
  public mitigationState: any = {};
  public loading = false;
  private sort: Sort = {
    active: "description",
    direction: "asc",
  };
  private pendingAction = false;
  private pendingActionSubscriptionId = "";

  constructor(
    public auth: AuthService,
    private apollo: Apollo,
    private api: ApiHelper,
    private dialog: MatDialog,
    public loadingState: LoadingState,
  ) {}

  get sortedData(): IpPrefixInstance[] {
    return this.sortData(this.sort);
  }

  ngOnInit() {
    this.handlePendingAction();
    this.loading = true;
    this.apollo
      .query<CustomerWithSubscriptionIds>({
        query: CERT_MITIGATION_PREFIXES_QUERY,
        variables: {
          customer: localStorage.getItem("viewingCustomerGUID"),
          products: ["IP_PREFIX"],
          status: ["active"],
        },
      })
      .subscribe(({ data }) => {
        this.subscriptions = [];
        this.loadingState.total = data.customer.subscriptions?.length || 0;
        data.customer.subscriptions?.forEach((sub) => {
          this.subscriptions.push(InstanceFactory.create(sub));
          this.getMitigationStatus(sub.subscriptionId);
        });
      });
  }

  /**
   * First: check if there is a pending strong action for auto mitigation.
   * If so, set the flag and store the subscriptionID.
   *
   */
  handlePendingAction() {
    if (this.auth.hasPendingStrongAction("autoMitigation")) {
      const state = this.auth.getSelfserviceState();
      if (state) {
        this.pendingActionSubscriptionId = state.subscription?.subscriptionId;
        this.auth.clearPendingStrongAction("autoMitigation");
        this.pendingAction = true;
      }
    }
  }

  /**
   * Called after the automitigation state is fetched (see getMitigationStatus()).
   *
   * @param subscriptionId the subscription ID
   */
  startPendingAction(subscriptionId: string) {
    const subscription = this.subscriptions.find((s) => s.subscriptionId === subscriptionId);
    this.toggleClick(subscription);
    this.pendingAction = false; // reset flag to prevent another dialog after submitting
  }

  /**
   * Fetch the mitigation status. Temporarily disabled until the API call works.
   *
   * @param subscriptionId The subscription ID
   */
  getMitigationStatus(subscriptionId: string) {
    this.mitigationState[subscriptionId] = "disabled";
    this.api
      .getAutoMitigation(subscriptionId)
      .then((value) => {
        this.mitigationState[subscriptionId] = value.status;

        if (this.pendingAction && subscriptionId === this.pendingActionSubscriptionId) {
          setTimeout(() => this.startPendingAction(subscriptionId), 200);
        }
      })
      // .catch((err) => {

      // })
      .finally(() => {
        this.loading = this.loadingState.done();
      });
  }

  /**
   * Used in templatee: extract prefix part from CIDR notation
   *
   * @param prefix Prefix in CIDR notation
   * @returns prefix size
   */
  prefixFor(prefix?: string): string {
    if (!prefix) {
      return "-";
    }
    const m = prefix.match(/(\/[0-9]{2,3})$/);
    return m ? m[1] : "-";
  }

  addressFor(prefix?: string): string {
    if (!prefix) {
      return "-";
    }
    const pos = prefix.indexOf("/");
    if (pos < 0) {
      return "";
    }
    return prefix.substring(0, pos);
  }

  ipVersionFor(afi?: string): string {
    if (!afi) {
      return "-";
    }
    switch (afi) {
      case "4":
        return "IPv4";
      case "6":
        return "IPv6";
      default:
        return "";
    }
  }

  changeSort(sort: Sort): void {
    this.sort = sort;
  }

  sortData(sort: Sort): IpPrefixInstance[] {
    if (!sort.active || sort.direction === "") {
      return this.subscriptions;
    }

    return this.subscriptions.sort((a, b) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "description":
          return this.compare(a.description, b.description, isAsc);
        case "prefix":
          let prefixA = a.ip_prefix?.prefix;
          let prefixB = b.ip_prefix?.prefix;
          if (prefixA === undefined) {
            prefixA = "";
          }
          if (prefixB === undefined) {
            prefixB = "";
          }
          // convert : and / to .
          prefixA = prefixA.replace(":", ".").replace("/", ".");
          prefixB = prefixB.replace(":", ".").replace("/", ".");

          prefixA = prefixA
            .split(".")
            .map((num) => num.padStart(3, "0"))
            .join("");
          prefixB = prefixB
            .split(".")
            .map((num) => num.padStart(3, "0"))
            .join("");

          // remove non numeric data
          prefixA = prefixA.replace(/\D/g, "");
          prefixB = prefixB.replace(/\D/g, "");

          prefixA = Number(prefixA);
          prefixB = Number(prefixB);

          return this.compare(prefixA, prefixB, isAsc);
        case "startdate":
          return this.compare(a?.startDate, b?.startDate, isAsc);
        case "version":
          const versionA = a.ip_prefix?.afi;
          const versionB = b.ip_prefix?.afi;

          return this.compare(versionA, versionB, isAsc);
        case "in_use":
          const inuseA = a.ipSubscriptions?.length;
          const inuseB = b.ipSubscriptions?.length;

          return this.compare(inuseA, inuseB, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  toggleClick(subscription: IpPrefixInstance) {
    // update automitigation
    this.openSelfserviceDialog(subscription);
  }

  openSelfserviceDialog(subscription) {
    const roleCheckResult = this.auth.checkRoleAccess("cert", "edit");
    if (!roleCheckResult.ok) {
      this.auth.roleEvent.emit(roleCheckResult);
      return;
    }

    if (!this.auth.isAuthenticatedForSelfService()) {
      this.dialog.open(DialogAuthComponent, {
        data: {
          selfserviceState: { subscription },
          initialAction: "autoMitigation",
        },
      });
    } else {
      const dialogRef = this.dialog.open(DialogSelfserviceComponent, {
        data: {
          type: "autoMitigation",
          subscription,
          mitigationState: this.mitigationState[subscription.subscriptionId],
        },
      });

      dialogRef.componentInstance.close.subscribe((event: string) => {
        if (event === "refresh") {
          console.log(`Refresh mitigation state for subscription ${subscription.subscriptionId}`);
          this.getMitigationStatus(subscription.subscriptionId);
        }
      });
    }
  }
}
