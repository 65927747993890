<navigation [activeItem]="" [requestsBubble]="3"></navigation>
<div class="wrapper">
	<div class="container">
		<div class="row">
			<div class="col-xs-12 offset-md-2 col-md-8 offset-lg-3 col-lg-6">
				<div class="card">
					<h2>{{ 'Footer.Disclaimer' | translate }}</h2>
					<p [innerHtml]="'Page.Disclaimer.Description1' | translate"></p>
					<p [innerHtml]="'Page.Disclaimer.Description2' | translate"></p>
					<p [innerHtml]="'Page.Disclaimer.Description3' | translate"></p>
				</div>
			</div>
		</div>
	</div>
	<div class="container"><footer [activeItem]="3"></footer></div>
</div>
