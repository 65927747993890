<div class="dialog">
  <div class="dialog__header">
		<h3 class="dialog__title">
			<svg-icon src="assets/images/icons/lightpath.svg" class="dialog__icon"></svg-icon>
			<span>{{ 'ChooseOrganisation.Dialog.ChooseOrganisation' | translate }}</span>
		</h3>
		<svg-icon src="assets/images/icons/x.svg" class="dialog__close" (click)="closeDialog()"></svg-icon>
  </div>
  <section class="dialog__tabpanel">
	 <div class="dialog__body dialog__body--scroll dialog__body--grow">
	 	<div class="dialog__inner">
            <div class="table__wrapper">
                <table class="table">
                    <tr>
                        <th>{{ 'Global.Name' | translate }}</th>
                        <th>&nbsp;</th>
                    </tr>
                    <tr *ngFor="let o of organisations" (click)="switchCustomer(o.customerId)">
                        <td>{{ o.name }}</td>
                        <td class="text-right">
                            <span style="cursor: pointer">
                                <svg-icon src="assets/images/icons/arrow-long.svg" class="table__arrow"></svg-icon>
                            </span>
                        </td>
                    </tr>
                </table>
            </div>
		</div>
	  </div>
	</section>
</div>
