<navigation [activeItem]="4" [requestsBubble]="3"></navigation>
<div class="wrapper">
<div class="container">
	<div class="row">
		<div class="col-xs-12 col-lg-8">
			<div class="card card--cols">
				<h2>{{ 'Page.Support.Malfunction' | translate }}</h2>
				<div class="row">
					<div class="col-xs-12 col-sm-6">
						<p>{{ 'Page.Support.Description1' | translate }} </p>
					</div>
					<div class="col-xs-12 col-sm-6">
						<p>{{ 'Page.Support.Description2' | translate }}</p>
					</div>
				</div>
				<div class="row">
					<div class="col-xs-12 col-sm-6">
						<div class="badge">{{ 'Page.Support.24hour' | translate }}</div>
					</div>
					<div class="col-xs-12 col-sm-6">
						<ul class="list">
							<li>+31-887873638</li>
							<li><a href="mailto:helpdesk@surf.nl">helpdesk@surf.nl</a></li>
						</ul>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-xs-12 col-md-6">
					<div class="card">
						<h2>{{ 'Page.Support.Question' | translate }}</h2>
						<p>{{ 'Page.Support.Description3' | translate }}</p>
						<ul class="list">
							<li>+31-887873000</li>
							<li><a href="mailto:automation-helpdesk@surf.nl">automation-helpdesk@surf.nl</a></li>
						</ul>
					</div>
				</div>
				<div class="col-xs-12 col-md-6 col--flex">
					<div class="card card--flex">
						<div class="card__flex__top">
							<h2>{{ 'Page.Support.Address' | translate }}</h2>
							<p>Kantoren Hoog Overborch (Hoog Catharijne) <br>
							Moreelsepark 48 <br>
							3511 EP Utrecht</p>
						</div>
						<div class="card__flex__bottom">
							<ul class="list">
								<li><a href="https://www.google.com/maps/place/Moreelsepark+48,+3511+EP+Utrecht/@52.0890566,5.1112767,17z/data=!4m5!3m4!1s0x47c66f5cf0744629:0xd26462bf1e621a9a!8m2!3d52.0890566!4d5.1134654" target="_blank">{{ 'Page.Support.Directions' | translate }}</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="container"><footer></footer></div>
</div>
