import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from "@angular/core";
import { Instance } from "../../models/instance";
import { ApiHelper } from "../../../helpers/apihelper";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { get_customer_description } from "../../../helpers/subscription/misc";
import { StorageHelper } from "../../../helpers/storage/storagehelper";

@Component({
  selector: "port-tile",
  templateUrl: "port-tile.html",
  styleUrls: ["../port-visualiser/port-visualiser.scss"],
})
export class PortTile implements OnChanges {
  @Input() service: Instance;
  @Input() serviceId: string;
  @Input() demoShowVisual = false;
  public healthState: any;
  public showVisual = true;

  constructor(
    private api: ApiHelper,
    private subscriptionService: SubscriptionService,
  ) {}

  get portPresentableName() {
    if (this.service === undefined) {
      return "";
    }

    if (this.service.portMode === "Link Member") {
      if (this.service.customerDescriptions && this.service.customerDescriptions[this.service.customerId]) {
        return this.service.customerDescriptions[this.service.customerId];
      }
      return this.service.description;
    }

    if (!this.service.port.customerDescriptions) {
      return this.service.port.description;
    }

    if (Array.isArray(this.service.port.customerDescriptions)) {
      const description = get_customer_description(StorageHelper.currentUser, this.service.port);
      if (description) {
        return description;
      }
    }

    return this.service.port.customerDescriptions[StorageHelper.currentUser] ?? this.service.port.description;
  }

  get port() {
    if (!this.service) {
      return undefined;
    }
    if (this.service && this.service.portMode === "Link Member") {
      return this.service;
    }
    return this.service.port;
  }

  get portOwner(): string {
    const customerName = this.port?.customer?.name || this.port?.customer;
    return this.port?.organisation || customerName || "-";
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["serviceId"]) {
      this.loadPortService(this.serviceId);
    }
  }

  loadPortService(serviceId: string) {
    if (this.service && !this.demoShowVisual) {
      this.showVisual = this.subscriptionService.canViewSubscription(this.service);
      if (!this.showVisual) {
        this.subscriptionService.getHealthDataForSubscription(serviceId).then((status) => {
          this.healthState = status.status;
        });
        return;
      }
    }
    this.api.raw_details("Port", serviceId).then((port) => {
      const obj = Object.assign(new Instance(), port);
      this.subscriptionService.getHealthDataForSubscription(serviceId).then((status) => {
        this.healthState = status.status;
      });
      if (this.service === undefined) {
        this.service = obj;
      }
      this.service.port = obj;
    });
  }
}
