import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit } from "@angular/core";
import { Subscription as RxSubscription } from "rxjs";
import * as Highcharts from "highcharts";
import { ReadablePacketSizePipe, ReadableSizePipe } from "../../../helpers/bitspipe/bitspipe";
import { ExtendedChartComponent } from "../../models/base/extendedchartcomponent";
import { ApiHelper } from "../../../helpers/apihelper";
import { SurfLog } from "../../../helpers/surflog";
import { Instance } from "../../models/instance";
import { MessageService } from "../../../services/messageservice";
import { ChartFilterType } from "../../../helpers/types";
import { MatDialog } from "@angular/material/dialog";
import { DialogSlsComponent } from "../../dialogs/dialog-sls/dialog-sls";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "linechart",
  templateUrl: "linechart.html",
})
export class LinechartComponent extends ExtendedChartComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() errorState = false;
  @Input() metaIsLoaded = false;
  @Input() splineData: any;
  @Input() usePresetSplineData = false;
  @Input() subscription;
  @Input() public hideSeries: any;
  @Input() firewallMode = false;
  @Input() happyState = false;

  public kpiTypes = {
    Aerohive: [
      { key: "dhcp", label: "KPI 1 - DHCP" },
      { key: "voipup", label: "KPI 2 - MOS VoIP Up" },
      { key: "voipdown", label: "KPI 3 - MOS VoIP Down" },
      { key: "throughputup", label: "KPI 4 - Throughput Up" },
      { key: "throughputdown", label: "KPI 5 - Throughput Down" },
      { key: "radius", label: "KPI 6 - Radius" },
    ],
    Aruba: [
      { key: "wifi_assoc_time", label: "KPI 1 - WiFi Association Time" },
      { key: "dns_res_time", label: "KPI 2 - DNS Resolution Time" },
      { key: "voice_quality", label: "KPI 3 - Voice Quality" },
      { key: "throughput_up", label: "KPI 4 - Throughput Up" },
      { key: "throughput_down", label: "KPI 5 - Throughput Down" },
    ],
  };

  highcharts: typeof Highcharts = Highcharts;
  chartConstructor = "chart"; // optional string, defaults to 'chart'
  chartOptions: Highcharts.Options = this.chartInfo; // required
  updateFlag = false; // optional boolean
  oneToOneFlag = true; // optional boolean, defaults to false
  packetsSubFilters = ["unicast", "broadcast", "multicast"];
  packetsSubFilterActive = "unicast";
  splineDataRaw: any;
  firewallDataRaw = [];
  portsFilterData = [];
  serviceInstanceId = "";
  instanceIdFilter = "";
  vlans = null;
  selectedPreset = "1w";
  presets = ["1d", "1w", "1m", "1y"];

  public chartFilter = 2;
  public chartDataModus = "bits";
  public exportTitle = "";
  public defaultTimeWindow = 7 * 24 * 3600 * 1000;
  public firstDate = new Date(Date.now() + -this.defaultTimeWindow);
  public secondDate = new Date();
  public circuitFilter: any;
  public portFilter: any;
  public kpiFilter: any;
  public linkMemberSubscriptions = [];
  public defaultDate = true;
  public tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
  public exportOverflowVisible = false;
  public firewallFilter = "Average";
  public selectedPortSubscription: any;
  public chartNotification = "Retrieving data...";

  private subChartFilter: RxSubscription;

  constructor(
    public translate: TranslateService,
    private api: ApiHelper,
    private msgsrv: MessageService,
    public dialog: MatDialog,
  ) {
    super();
    this.circuitFilter = "";
    this.portFilter = "";
    this.kpiFilter = "clients";
    this.firewallDataRaw["filtered"] = [];
    this.firewallDataRaw["unfiltered"] = [];

    this.subChartFilter = this.msgsrv.chartFilter$.subscribe((filter: ChartFilterType) => {
      const circuitFilterData = filter.connection;
      // do this so the port <select> will select the correct port.
      // it needs to be one of the objects in this.circuitFilter.endpoints.
      if (circuitFilterData) {
        if (this.subscription?.product.productType === "Port") {
          this.circuitFilter = this.subscription?.services.find(
            (el) => filter.vlanranges.includes(el.vlanrange) && el.subscriptionId === circuitFilterData.subscriptionId,
          );

          this.selectedPortSubscription = this.circuitFilter.subscriptionId;

          this.updateInstanceIdFilters(this.circuitFilter.subscriptionId, this.circuitFilter.vlanrange);
        }

        if (this.subscription?.product.productType === "IP" || this.subscription?.product.productType === "FW") {
          if (this.subscription?._saps !== undefined) {
            this.portFilter = this.subscription?._saps.find(
              (el) =>
                filter.vlanranges.includes(el.vlanrange) &&
                el.port?.subscriptionId === circuitFilterData.subscriptionId,
            );
          }

          let portFilterItem = this.portsFilterData.find(
            (el) =>
              el.value.port?.subscriptionId === circuitFilterData.subscriptionId ||
              el.value.firewallSubscriptionId === circuitFilterData.subscriptionId ||
              el.value.otherSubscriptionId === circuitFilterData.subscriptionId ||
              el.value.subscriptionInstanceId === circuitFilterData.ipGateway?.subscriptionInstanceId,
          );
          if (portFilterItem !== undefined) {
            this.portFilter = portFilterItem.value;
          }

          this.updateServiceInstanceIdFilters();
        }

        if (this.subscription?.product.productType === "L2VPN" || this.subscription?.product.productType === "L3VPN") {
          let portFilterItem = this.portsFilterData.find(
            (el) =>
              el?.value?.port?.subscriptionId === circuitFilterData.subscriptionId ||
              el?.value?.firewallSubscriptionId === circuitFilterData.subscriptionId,
          );
          if (portFilterItem !== undefined) {
            this.portFilter = portFilterItem.value;
          }

          this.updateServiceInstanceIdFilters();
        }
      }
    });
  }

  get chartInfo(): any {
    const bitsPipe = new ReadableSizePipe();
    const packetPipe = new ReadablePacketSizePipe();
    const thisContext = this;
    const disableLoader = () => {
      this.chartRef.hideLoading();
    };
    return {
      chart: {
        type: "line",
        zoomType: "x",
        height: 400,
        showAxes: false,
        backgroundColor: "#FAFBFD",
        spacingLeft: 24,
        spacingRight: 24,
        spacingTop: 48,
        selectionMarkerFill: "rgba(0,0,0,0.05)",
        resetZoomButton: {
          position: {
            x: 0,
            y: 8,
          },
        },
        style: {
          fontFamily: "Proxima",
        },
        events: {
          selection(event) {
            if (event.xAxis !== undefined) {
              /**
               * trigger new data loading after selecting in the chart
               */
              thisContext.firstDate = new Date(event.xAxis[0].min);
              thisContext.secondDate = new Date(event.xAxis[0].max);
              thisContext.defaultDate = false;
              if (this.subscription?.product.productType === "Wireless") {
                thisContext.getNewWirelessData();
              } else {
                thisContext.getNewData();
              }
            }
          },
        },
      },
      lang: {
        noData: "Retrieving data...",
      },

      exporting: {
        chartOptions: {
          chart: {
            style: {
              fontFamily: "Helvetica",
            },
          },
          yAxis: [
            {
              labels: {
                style: {
                  font: "9px Helvetica",
                },
              },
              title: {
                // text: 'Test titel',
                text: thisContext.chartDataModus,
              },
            },
          ],
          xAxis: [
            {
              type: "datetime",
              dateTimeLabelFormats: {
                hour: "%H:%M",
                minute: "%H:%M",
              },
              labels: {
                style: {
                  font: "9px Helvetica",
                },
              },
            },
          ],
        },
        enabled: false,

        csv: {
          decimalPoint: ".",
          lineDelimiter: "\n",
        },
      },

      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        shadow: false,
        backgroundColor: "white",
        borderColor: "#E6E9EB",
        pointFormatter() {
          const tipLabel = thisContext.translate.instant(`Chart.TrafficGraph.Tooltip.Wireless.${this.series.name}`);
          if (thisContext.subscription?.product.productType === "Wireless") {
            let v = parseFloat(this.y).toFixed(2);
            return `<span style="color:${this.color}">\u25CF</span> ${tipLabel}: <b>${v}</b><br/>`;
          } else {
            let v = "";
            if (thisContext.chartDataModus === "bits") {
              v = bitsPipe.transform(this.y);
            } else {
              v = packetPipe.transform(this.y);
            }

            return `<span style="color:${this.color}">\u25CF</span> ${tipLabel}: <b>${v}</b><br/>`;
          }
        },
      },
      yAxis: {
        title: {
          text: null,
        },

        gridLineColor: "#E6E9EB",
        labels: {
          style: {
            color: "#7f8c8d",
            font: "9px Proxima",
          },
        },
        // minRange: 10000
      },
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          hour: "%H:%M",
          minute: "%H:%M",
        },
        minPadding: 0,
        maxPadding: 0,
        lineColor: "transparent",
        minorTickLength: 0,
        tickLength: 0,
        labels: {
          style: {
            color: "#7f8c8d",
            font: "9px Proxima",
          },
        },
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "in",
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
          },
          data: [],
          color: "#177abf",
          lineWidth: 1,
          fillColor: {
            linearGradient: { x1: 1, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, "rgba(231, 134, 122, 0.15)"],
              [1, "rgba(255,255,255,.0)"],
            ],
          },
        },
        {
          name: "out",
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
          },
          data: [],
          color: "#e7303a",
          lineWidth: 1,
          fillColor: {
            linearGradient: { x1: 1, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, "rgba(60, 106, 133, 0.15)"],
              [1, "rgba(255,255,255,.0)"],
            ],
          },
        },
        {
          name: "Piek in",
          data: [],
          color: "#ee7628",
          lineWidth: 1,
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
          },
          fillColor: {
            linearGradient: { x1: 1, y1: 0, x2: 0, y2: 1 },
            stops: [],
          },
        },
        {
          name: "Piek uit",
          data: [],
          color: "#2ca055",
          lineWidth: 1,
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
          },
          fillColor: {
            linearGradient: { x1: 1, y1: 0, x2: 0, y2: 1 },
            stops: [],
          },
        },

        {
          name: "",
          data: [],
          color: "#8B5CF6",
          lineWidth: 1,
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
          },
        },
        {
          name: "",
          data: [],
          color: "#EC4899",
          lineWidth: 1,
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
          },
        },
        {
          name: "",
          data: [],
          color: "CadetBlue",
          lineWidth: 1,
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
          },
        },
        {
          name: "",
          data: [],
          color: "Gold",
          lineWidth: 1,
          marker: {
            enabled: false,
            symbol: "circle",
            radius: 2,
          },
        },
      ],
    };
  }

  /**
   * Switch between bits and packets.
   */
  // Todo: networkdashboard-api #41 (correct typo?)
  toggleBitsPackets() {
    if (this.chartDataModus === "bits") {
      this.chartDataModus = "packets";
    } else {
      this.chartDataModus = "bits";
    }

    if (this.subscription?.product.productType === "Wireless") {
      this.getNewWirelessData();
    } else {
      this.getNewData();
    }
  }

  /**
   * Switch between packet types (unicast/multicast/broadcast)
   *
   * @param chartDataModusValue
   */
  updatePacketsSubFilters(chartDataModusValue) {
    this.packetsSubFilterActive = chartDataModusValue;
    if (this.firewallMode) {
      this.updateFirewallSeries();
    } else {
      // replaced `this.updateChartSeries()` with `getNewData()`
      // to re-fetch packet data since it will be
      // requested for this specific type only.
      if (this.subscription?.product.productType === "Wireless") {
        this.getNewWirelessData();
      } else {
        this.getNewData();
      }
    }

    this.loadHealthForSubscriptions();
  }

  /**
   * Chart data from this port
   *
   * @param serviceInstanceIdValue
   */
  updateServiceInstanceIdFilters(serviceInstanceIdValue = null) {
    if (serviceInstanceIdValue) {
      if (serviceInstanceIdValue !== "-1") {
        this.portFilter = serviceInstanceIdValue;
      } else {
        this.portFilter = null;
      }
    }

    if (this.subscription?.product.productType === "Wireless") {
      this.getNewWirelessData();
    } else {
      this.getNewData();
    }
    this.loadHealthForSubscriptions();
  }

  /**
   * Chart data from this subscription
   *
   * @param instanceIdValue
   * @param vlanrange
   */
  updateInstanceIdFilters(instanceIdValue, vlanrange = null) {
    if (instanceIdValue === undefined) {
      instanceIdValue = "";
    }

    this.instanceIdFilter = instanceIdValue;
    this.vlans = vlanrange;

    if (this.subscription?.product.productType === "Wireless") {
      this.getNewWirelessData();
    } else {
      this.getNewData();
    }

    this.loadHealthForSubscriptions();
  }

  setChartInstance(chart: Highcharts.Chart) {
    this.chartRef = chart;
  }

  toggleLegendItem(series?: string) {
    super.toggleLegendItem(series);
  }

  updateExportTitle() {
    if (this.chartDataModus === "bits") {
      this.translate.get("Chart.Label.BitsS").subscribe((exportTitleTranslation: string) => {
        this.exportTitle = exportTitleTranslation;
      });
    }

    if (this.chartDataModus === "packets") {
      this.translate.get("Chart.Label.PacketsS").subscribe((exportTitleTranslation: string) => {
        this.exportTitle = exportTitleTranslation;
      });
    }

    this.chartRef.update({
      exporting: {
        chartOptions: {
          yAxis: [
            {
              title: {
                text: this.exportTitle,
              },
            },
          ],
        },
      },
    });
  }

  exportAsPNG() {
    this.updateExportTitle();
    this.chartRef.exportChart({ type: "image/png" });
  }
  exportAsJPG() {
    this.updateExportTitle();
    this.chartRef.exportChart({ type: "image/jpeg" });
  }
  exportAsCSV() {
    this.updateExportTitle();
    this.chartRef.downloadCSV();
  }

  setDatePreset(preset: string) {
    this.selectedPreset = preset;
    this.firstDate = new Date();

    switch (preset) {
      case "1d":
        this.firstDate.setDate(this.firstDate.getDate() - 1);
        break;
      case "1w":
        this.firstDate.setDate(this.firstDate.getDate() - 7);
        break;
      case "1m":
        this.firstDate.setMonth(this.firstDate.getMonth() - 1);
        break;
      case "1y":
        this.firstDate.setDate(this.firstDate.getDate() - 365);
        break;
    }

    this.secondDate = new Date();
    if (this.subscription?.product.productType === "Wireless") {
      this.getNewWirelessData();
    } else {
      this.getNewData();
    }
  }

  /**
   * Handle newly picked dates (chart range)
   */
  onDateChange() {
    this.selectedPreset = "";
    if (this.secondDate > new Date()) {
      this.secondDate = new Date();
    }

    if (this.firstDate > this.secondDate) {
      this.firstDate = new Date(this.secondDate.getTime() + -this.defaultTimeWindow);
    }

    this.defaultDate = false;
    if (this.subscription?.product.productType === "Wireless") {
      this.getNewWirelessData();
    } else {
      this.getNewData();
    }
  }

  resetDatePickers() {
    this.selectedPreset = "";
    this.secondDate = new Date();
    this.firstDate = new Date(this.secondDate.getTime() + -this.defaultTimeWindow);
    this.defaultDate = true;

    if (this.subscription?.product.productType === "Wireless") {
      this.getNewWirelessData();
    } else {
      this.getNewData();
    }
  }

  toggleExportOptions() {
    this.exportOverflowVisible = !this.exportOverflowVisible;
  }

  updateLinkMemberData() {
    if (!this.subscription || !this.subscription.linkMemberSubscriptions) {
      return;
    }

    this.subscription.linkMemberSubscriptions.forEach(async (serviceId) => {
      this.api.raw_details("Port", serviceId).then((port) => {
        const instance = Object.assign(new Instance(), port);
        this.linkMemberSubscriptions.push(instance);
      });
    });
  }

  /**
   * Show the SLS (Availability) dialog.
   */
  showSlsDialog() {
    const dialogRef = this.dialog.open(DialogSlsComponent, {
      data: this.subscription,
    });
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.updateChartSeries();
  }

  chartError(reason?: string) {
    this.chartNotification = `No data found (${reason})`;
    this.chartRef.hideLoading();
    // hideNoData, because the string is not updated unless hidden.
    this.chartRef.hideNoData();
    this.chartRef.showNoData(this.chartNotification);
  }

  resetChartError() {
    this.chartNotification = "Retrieving data...";
    this.chartRef.hideNoData();
    this.chartRef.showNoData(this.chartNotification);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["subscription"] && this.subscription !== undefined && this.subscription !== null) {
      if (this.subscription?.product.productType === "Wireless") {
        this.defaultTimeWindow = 7 * 24 * 3600 * 1000;
        this.firstDate = new Date(Date.now() + -this.defaultTimeWindow);
      }

      // Maurits: commented out this code since it did set the portFilter which caused a wrong call to the health endpoint
      // TODO: @gravity please fix portFilter call to health endpoint.
      if (this.subscription.product.productType === "LightPath") {
        this.circuitFilter = this.subscription._circuits[0];
        // this.portFilter = this.subscription._circuits[0].endpoints[0];
      }

      if (this.subscription?.product.productType === "Wireless") {
        this.getNewWirelessData();
      } else {
        this.getNewData();
      }
      this.updateLinkMemberData();
      this.loadHealthForSubscriptions();
    }

    // if we reuse this component, remove all old data
    if (this.subscription === null) {
      this.circuitFilter = "";
      this.portFilter = "";
      this.kpiFilter = "clients";
      this.firewallDataRaw["filtered"] = [];
      this.firewallDataRaw["unfiltered"] = [];
      this.clearAllData();
    }

    // only run when property "splineData" changed
    if (changes["splineData"]) {
      this.updateChartSeries();
    }
  }

  clearAllData() {
    if (this.chartRef) {
      while (this.chartRef.series.length > 0) {
        this.chartRef.series[0].remove(false);
      }
      this.chartRef.redraw(); // update the visual aspect
    }
  }

  /**
   * Returns true if this is a redundant LightPath
   * */
  isRedundant() {
    if (this.subscription.tags.indexOf("LR") !== -1) {
      return true;
    }
    return false;
  }

  getNewData() {
    if (this.chartRef !== undefined) {
      this.chartRef.showLoading(" ");
      this.resetChartError();
    }

    let subscriptionId = this.subscription.subscriptionId;
    let serviceInstanceId = "";
    let service = null;
    if (!this.subscription) {
      return;
    }

    // if a linkmember is selected, use the selected InstanceId
    if (this.instanceIdFilter !== "") {
      subscriptionId = this.instanceIdFilter;
      // default value for instanceId is the subscriptionInstanceId of the linked service.
      if (this.vlans !== null) {
        service = this.subscription.services.find(
          (e) => e.subscriptionId === this.instanceIdFilter && e.vlanrange === this.vlans,
        );
      } else {
        service = this.subscription.services.find((e) => e.subscriptionId === this.instanceIdFilter);
      }

      if (service && service.product.productType !== "Port") {
        serviceInstanceId = service.portSubscriptionInstanceId;
      }
    }

    if (this.portFilter) {
      if (this.portFilter.firewallSubscriptionId !== undefined) {
        if (service && service.product.productType === "FW") {
          subscriptionId = this.portFilter.firewallSubscriptionId;
          serviceInstanceId = this.portFilter.subscriptionInstanceId;
        } else {
          subscriptionId = this.portFilter.firewallSubscriptionId;
          serviceInstanceId = "";
        }
      } else {
        serviceInstanceId = this.portFilter.subscriptionInstanceId;
      }
    }

    // splineData;

    if (this.usePresetSplineData) {
      if (!this.splineData || this.splineData.length === 0) {
        this.chartError("No spline data for usePresetSplineData");
      } else {
        this.splineDataRaw = this.splineData;
        this.updateChartSeries();
      }
    } else if (this.firewallMode) {
      this.api
        .firewall_stats_aggregated(subscriptionId, "unfiltered", this.chartDataModus, this.firstDate, this.secondDate)
        .then((chartData) => {
          if (chartData.error === true) {
            this.chartError("api error");
          } else {
            this.firewallDataRaw["unfiltered"] = chartData;
            this.updateFirewallSeries();
          }
        })
        .catch((err) => {
          // SurfLog.err(`Linechart: Failed to load aggregated stats for ${this.subscription.subscriptionId}`);
          // this.chartError('http failure');
        });

      this.api
        .firewall_stats_aggregated(subscriptionId, "filtered", this.chartDataModus, this.firstDate, this.secondDate)
        .then((chartData) => {
          if (chartData.error === true) {
            this.chartError("api error");
          } else {
            this.firewallDataRaw["filtered"] = chartData;
            this.updateFirewallSeries();
          }
        })
        .catch((err) => {
          // SurfLog.err(`Linechart: Failed to load aggregated stats for ${this.subscription.subscriptionId}`);
          // this.chartError('http failure');
        });
    } else {
      this.api
        .service_stats_aggregated(
          subscriptionId,
          serviceInstanceId,
          this.chartDataModus,
          this.packetsSubFilterActive,
          this.firstDate,
          this.secondDate,
        )
        .then((chartData) => {
          if (chartData.error === true) {
            this.chartError("api error");
          } else {
            this.splineDataRaw = chartData;
            this.updateChartSeries();
          }
        })
        .catch((err) => {
          SurfLog.err(`Linechart: Failed to load aggregated stats for ${this.subscription.subscriptionId}`);
          this.chartError("http failure");
        });
    }

    this.loadPortsFilterData();
  }

  getNewWirelessData() {
    if (this.chartRef !== undefined) {
      this.chartRef.showLoading(" ");
      this.resetChartError();
    }

    let subscriptionId = this.subscription.subscriptionId;
    let frequency = "all";
    if (!this.subscription) {
      return;
    }
    if (this.kpiFilter === "clients") {
      frequency = "5";
      // if switch KPIs ensure that no second line is left in the graph
      if (this.chartRef.series.length > 1) {
        this.chartRef.series[1].remove();
      }
    }

    this.api
      .wireless_stats_aggregated(subscriptionId, this.kpiFilter, frequency, this.firstDate, this.secondDate)
      .then((chartData) => {
        if (chartData.error === true) {
          this.chartError("api error");
        } else {
          this.splineDataRaw = chartData;
          this.updateWirelessChartSeries();
        }
      })
      .catch((err) => {
        SurfLog.err(`Linechart: Failed to load aggregated stats for ${this.subscription.subscriptionId}`);
        this.chartError("http failure");
      });

    this.loadPortsFilterData();
  }

  onClickedOutside() {
    if (this.exportOverflowVisible) {
      this.exportOverflowVisible = false;
    }
  }

  async loadHealthForSubscriptions() {
    let subscriptionId = this.subscription.subscriptionId;

    // if a instanceIdFilter is selected, use the selected InstanceId
    if (this.instanceIdFilter !== "") {
      subscriptionId = this.instanceIdFilter;
    }

    // if a serviceInstanceId is selected, use the selected InstanceId
    if (this.portFilter) {
      if (this.portFilter.firewallSubscriptionId) {
        subscriptionId = this.portFilter.firewallSubscriptionId;
        this.api
          .health(subscriptionId, this.portFilter.subscriptionInstanceId, this.subscription?.product.productType)
          .then((data) => {
            this.subscription.health = data;
          });
      } else if (this.portFilter.product && this.portFilter.product.productId) {
        subscriptionId = this.portFilter.subscriptionId;
        this.api
          .health(subscriptionId, this.portFilter.product.productId, this.subscription?.product.productType)
          .then((data) => {
            this.subscription.health = data;
          });
      } else {
        if (this.portFilter.otherSubscriptionId) {
          subscriptionId = this.portFilter.otherSubscriptionId;
        } else if (this.portFilter.port) {
          subscriptionId = this.portFilter.port.subscriptionId;
        } else if (this.portFilter.port) {
          subscriptionId = this.portFilter.subscriptionId;
        }
        subscriptionId = this.subscription.subscriptionId;
        let subscriptionInstanceId3 = "";
        if (this.portFilter.subscriptionInstanceId) {
          subscriptionInstanceId3 = this.portFilter.subscriptionInstanceId;
        }

        this.api
          .health(subscriptionId, subscriptionInstanceId3, this.subscription?.product.productType)
          .then((data) => {
            this.subscription.health = data;
          });
      }
    } else {
      this.api.health(subscriptionId, "", this.subscription?.product.productType).then((data) => {
        this.subscription.health = data;
      });
    }
  }

  updateChartSeries() {
    if (this.splineDataRaw === undefined || this.splineDataRaw === null) {
      return;
    }

    if (this.chartDataModus === "bits") {
      this.splineData = {
        in: this.splineDataRaw.in,
        out: this.splineDataRaw.out,
        spike_in: this.splineDataRaw.spike_in,
        spike_out: this.splineDataRaw.spike_out,
      };
    } else {
      this.splineData = {
        in: this.splineDataRaw[this.packetsSubFilterActive].in,
        out: this.splineDataRaw[this.packetsSubFilterActive].out,
        spike_in: this.splineDataRaw[this.packetsSubFilterActive].spike_in,
        spike_out: this.splineDataRaw[this.packetsSubFilterActive].spike_out,
      };
    }

    if (this.splineData.in.length === 0 && this.splineData.out.length === 0) {
      this.chartError("empty");
    }

    const lineColors = {
      0: "#177abf",
      1: "#e7303a",
      2: "#ee7628",
      3: "#2ca055",
    };

    if (this.chartRef !== undefined) {
      this.chartRef.showLoading(" ");

      let indexNow = 0;
      let noDataFound = true;

      for (const serieName in this.splineData) {
        if (this.chartRef.series[indexNow] === undefined) {
          this.chartRef.addSeries(
            {
              name: serieName,
              data: this.splineData[serieName],
              type: this.chartInfo.type,
              color: lineColors[indexNow],
            },
            false,
            false,
          );
        } else {
          this.chartRef.series[indexNow].update({ name: serieName, type: this.chartInfo.type }, false);
          this.chartRef.series[indexNow].setData(this.splineData[serieName], false, false, false);
        }

        if (this.splineData[serieName].length > 0) {
          noDataFound = false;
        }
        indexNow++;
      }

      if (/*!this.metaIsLoaded || */ noDataFound) {
        this.chartError("empty response");
      }

      this.chartRef.redraw(true);
      this.chartRef.hideLoading();
      this.chartRef.zoom(); // force zoom out
    }
  }

  updateWirelessChartSeries() {
    if (this.splineDataRaw === undefined || this.splineDataRaw === null) {
      return;
    }

    if (this.kpiFilter === "clients") {
      this.splineData = {
        five: this.splineDataRaw["five"],
      };
    } else {
      this.splineData = {
        twofour: this.splineDataRaw["twofour"],
        five: this.splineDataRaw["five"],
      };
    }

    const lineColors = {
      0: "#177abf",
      1: "#e7303a",
      2: "#ee7628",
      // 3: "#2ca055",
    };

    if (this.chartRef !== undefined) {
      this.chartRef.showLoading(" ");

      let indexNow = 0;
      let noDataFound = true;

      for (const serieName in this.splineData) {
        if (this.chartRef.series[indexNow] === undefined) {
          this.chartRef.addSeries(
            {
              name: serieName,
              data: this.splineData[serieName],
              type: this.chartInfo.type,
              color: lineColors[indexNow],
            },
            false,
            false,
          );
        } else {
          this.chartRef.series[indexNow].update({ name: serieName, type: this.chartInfo.type }, false);
          this.chartRef.series[indexNow].setData(this.splineData[serieName], false, false, false);
        }

        if (this.splineData[serieName].length > 0) {
          noDataFound = false;
        }
        indexNow++;
      }

      if (/*!this.metaIsLoaded || */ noDataFound) {
        this.chartError("empty response");
      }

      this.chartRef.redraw(true);
      this.chartRef.hideLoading();
      this.chartRef.zoom(); // force zoom out
    }
  }

  updateFirewallSeries() {
    const lineColors = {
      "Unfiltered - in": "#ee7628",
      "Filtered - in": "#177abf",
      "Unfiltered - out": "#2ca055",
      "Filtered - out": "#e7303a",
      "Unfiltered - peak in": "#34D399",
      "Filtered - peak in": "#8B5CF6",
      "Unfiltered - peak out": "#FBBF24",
      "Filtered - peak out": "#EC4899",
    };

    if (this.chartDataModus === "bits") {
      if (this.firewallDataRaw["unfiltered"].length === 0 || this.firewallDataRaw["filtered"].length === 0) {
        return;
      }
    } else {
      if (
        this.firewallDataRaw["unfiltered"][this.packetsSubFilterActive] === undefined ||
        this.firewallDataRaw["filtered"][this.packetsSubFilterActive] === undefined
      ) {
        return;
      }
    }

    if (this.chartDataModus === "bits") {
      if (this.firewallFilter === "Average") {
        this.splineData = {
          "Unfiltered - in": this.firewallDataRaw["unfiltered"].in,
          "Filtered - in": this.firewallDataRaw["filtered"].in,
          "Unfiltered - out": this.firewallDataRaw["unfiltered"].out,
          "Filtered - out": this.firewallDataRaw["filtered"].out,
        };
      } else if (this.firewallFilter === "Peak") {
        this.splineData = {
          "Unfiltered - peak in": this.firewallDataRaw["unfiltered"].spike_in,
          "Filtered - peak in": this.firewallDataRaw["filtered"].spike_in,
          "Unfiltered - peak out": this.firewallDataRaw["unfiltered"].spike_out,
          "Filtered - peak out": this.firewallDataRaw["filtered"].spike_out,
        };
      } else if (this.firewallFilter === "Average_Peak") {
        this.splineData = {
          "Unfiltered - in": this.firewallDataRaw["unfiltered"].in,
          "Filtered - in": this.firewallDataRaw["filtered"].in,
          "Unfiltered - out": this.firewallDataRaw["unfiltered"].out,
          "Filtered - out": this.firewallDataRaw["filtered"].out,
          "Unfiltered - peak in": this.firewallDataRaw["unfiltered"].spike_in,
          "Filtered - peak in": this.firewallDataRaw["filtered"].spike_in,
          "Unfiltered - peak out": this.firewallDataRaw["unfiltered"].spike_out,
          "Filtered - peak out": this.firewallDataRaw["filtered"].spike_out,
        };
      }
    } else {
      if (this.firewallFilter === "Average") {
        this.splineData = {
          "Unfiltered - in": this.firewallDataRaw["unfiltered"][this.packetsSubFilterActive].in,
          "Filtered - in": this.firewallDataRaw["filtered"][this.packetsSubFilterActive].in,
          "Unfiltered - out": this.firewallDataRaw["unfiltered"][this.packetsSubFilterActive].out,
          "Filtered - out": this.firewallDataRaw["filtered"][this.packetsSubFilterActive].out,
        };
      } else if (this.firewallFilter === "Peak") {
        this.splineData = {
          "Unfiltered - peak in": this.firewallDataRaw["unfiltered"][this.packetsSubFilterActive].spike_in,
          "Filtered - peak in": this.firewallDataRaw["filtered"][this.packetsSubFilterActive].spike_in,
          "Unfiltered - peak out": this.firewallDataRaw["unfiltered"][this.packetsSubFilterActive].spike_out,
          "Filtered - peak out": this.firewallDataRaw["filtered"][this.packetsSubFilterActive].spike_out,
        };
      } else if (this.firewallFilter === "Average_Peak") {
        this.splineData = {
          "Unfiltered - in": this.firewallDataRaw["unfiltered"][this.packetsSubFilterActive].in,
          "Filtered - in": this.firewallDataRaw["filtered"][this.packetsSubFilterActive].in,
          "Unfiltered - out": this.firewallDataRaw["unfiltered"][this.packetsSubFilterActive].out,
          "Filtered - out": this.firewallDataRaw["filtered"][this.packetsSubFilterActive].out,
          "Unfiltered - peak in": this.firewallDataRaw["unfiltered"][this.packetsSubFilterActive].spike_in,
          "Filtered - peak in": this.firewallDataRaw["filtered"][this.packetsSubFilterActive].spike_in,
          "Unfiltered - peak out": this.firewallDataRaw["unfiltered"][this.packetsSubFilterActive].spike_out,
          "Filtered - peak out": this.firewallDataRaw["filtered"][this.packetsSubFilterActive].spike_out,
        };
      }
    }

    // if (this.splineData.in.length === 0 && this.splineData.out.length === 0) {
    //   this.chartError('empty');
    // }

    if (this.chartRef !== undefined) {
      this.chartRef.showLoading(" ");

      let indexNow = 0;
      let noDataFound = true;

      while (this.chartRef.series.length) {
        this.chartRef.series[0].remove();
      }

      for (const serieName in this.splineData) {
        if (this.chartRef.series[indexNow] === undefined) {
          this.chartRef.addSeries(
            {
              name: serieName,
              data: this.splineData[serieName],
              type: this.chartInfo.type,
              color: lineColors[serieName],
            },
            true,
            false,
          );
        } else {
          this.chartRef.series[indexNow].update(
            {
              name: serieName,
              type: this.chartInfo.type,
              color: lineColors[serieName],
            },
            false,
          );
          this.chartRef.series[indexNow].setData(this.splineData[serieName], false);
        }

        if (this.splineData[serieName] !== undefined && this.splineData[serieName].length > 0) {
          noDataFound = false;
        }
        indexNow++;
      }

      if (/*!this.metaIsLoaded || */ noDataFound) {
        this.chartError("empty response");
        this.chartRef.redraw(true);
        this.chartRef.hideLoading();
      } else {
        this.chartRef.redraw(true);
        this.chartRef.hideLoading();
      }
    }
  }

  loadPortsFilterData() {
    const firewallInterconnectTitle = this.translate?.instant("ServiceElement.FirewallInterconnect");
    const internetUplinkTitle = this.translate.instant("ServiceElement.SURFinternetUplink");

    const portTitle = this.translate.instant("ServiceElement.Port");

    const returnValues = [];

    if (this.subscription.esis) {
      // FOR L2VPN

      let firewallInterconnect = this.subscription?.esis?.filter((v) => v.segmentId === 99);

      let portCounter = 1;
      if (firewallInterconnect.length > 0) {
        returnValues.unshift({
          name: portTitle + " " + portCounter + " - " + firewallInterconnectTitle,
          value: firewallInterconnect[0].firewallInterconnect,
        });
        portCounter++;
      }

      for (const esis of this.subscription.esis) {
        if (esis?.segmentId !== 99) {
          for (const endpoint of esis?.endpoints) {
            returnValues.push({
              name: portTitle + " " + portCounter + " - " + endpoint.port.description,
              value: endpoint,
            });
          }
          portCounter++;
        }
      }
    } else if (this.subscription?.l3vpnsapss) {
      // FOR L3VPN

      if (this.subscription?.firewallInterconnects) {
        for (const thisFirewallInterconnect of this.subscription.firewallInterconnects) {
          returnValues.push({
            name: firewallInterconnectTitle,
            value: thisFirewallInterconnect,
          });
        }
      }

      for (const thisEndpoint of this.subscription.l3vpnsapss) {
        returnValues.push({
          name: thisEndpoint.sap.port.description,
          value: thisEndpoint.sap,
        });
      }
    } else if (this.subscription.l3Endpoints) {
      // Firewall
      returnValues.push({
        name: internetUplinkTitle,
        value: this.subscription.ipGateway,
      });

      for (const thisEndpoint of this.subscription.l2Endpoints) {
        returnValues.push({
          name: thisEndpoint.description,
          value: thisEndpoint,
        });
      }

      for (const thisEndpoint of this.subscription.l3Endpoints) {
        returnValues.push({
          name: thisEndpoint.description,
          value: thisEndpoint,
        });
      }
    }

    this.portsFilterData = returnValues;
  }
}
