import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { SubscriptionService } from "../../../services/subscriptionservice";
import {
  AggspSubscriptionDetailsType,
  IrbSubscriptionDetailsType,
  MscSubscriptionDetailsType,
  ServicePortSubscriptionDetailsType,
} from "../../../gql/generated-ingestor";

@Component({
  selector: "subscription-detail-container-replicated",
  templateUrl: "subscription-detail-container.html",
})
export class SubscriptionDetailContainerReplicated implements OnChanges {
  @Input() subscription:
    | ServicePortSubscriptionDetailsType
    | MscSubscriptionDetailsType
    | AggspSubscriptionDetailsType
    | IrbSubscriptionDetailsType;
  @Input() productType: string;
  @Input() services: any[] = [];
  @Input() productTag: string;
  public activeSubscription: any;
  public customerGuid: string;
  public activeSubscriptionVlanranges: any[] = [];

  constructor(public subscriptionService: SubscriptionService) {
    this.customerGuid = localStorage.getItem("viewingCustomerGUID");
  }

  // get locationsForSubscription() {
  //   if (!this.activeSubscription) {
  //     return [];
  //   }

  //   // @todo ingestor: need some more details in the linked services.
  //   // const values = this.activeSubscription._ports.reduce((all, el) => {
  //   //   all[`${el.port.address.city} (${el.port.surfLocationCode})`] = true;
  //   //   return all;
  //   // },
  //   // return Object.keys(values);
  //   return [];
  // }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.subscription || this.subscription.services === undefined) {
      return;
    }
    // @todo update when linked-subscription data is available
    this.services = this.subscription.services;
    if (!this.activeSubscription && Array.isArray(this.services) && this.services.length > 0) {
      this.activeSubscription = this.services[0];
      this.getVlanIds();
    }
  }

  setActiveSubscription(sub) {
    this.activeSubscription = null;

    setTimeout(() => {
      this.activeSubscription = sub;
      this.getVlanIds();
    }, 1);
  }

  getVlanIds() {
    if (!this.activeSubscription) {
      return;
    }
    this.activeSubscriptionVlanranges = [this.activeSubscription.vlanrange];
  }

  OnClickBar(subscriptionId) {
    const findSubscription = this.services.find((x) => x.subscriptionId === subscriptionId);
    this.setActiveSubscription(findSubscription);
  }
}
