<div class="completer__flex">
	<ng2-completer
        [(ngModel)]="organisation"
        [selectOnClick]="true"
        (selected)="onOrganisationSelected($event)"
        [matchClass]="'match'"
	    [dataService]="dataService"
        [minSearchLength]="1"
        [placeholder]="auth.state.viewingCustomer.name"
        [textNoResults]="'Global.NoResults' | translate"
        (keyup)="processKey($event)">
	</ng2-completer>
	<span class="completer__button" (click)="switchOrganisation()"><svg-icon class="completer__icon" src="assets/images/icons/refresh.svg"></svg-icon></span>
</div>
<div class="completer__close"></div>
