import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, NgModule } from "@angular/core";
import { Router } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AngularSvgIconModule } from "angular-svg-icon";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import * as Sentry from "@sentry/angular-ivy";

import { TruncateModule } from "@yellowspot/ng-truncate";
import { NgxMdModule } from "ngx-md";
import { SafePipe } from "../helpers/safepipe/safepipe";

import { MatomoConfig } from "../helpers/matomoconfig";
import { AppComponent } from "./app.component";

// Dialog components
import { ChooseOrganisationDialogComponent } from "../components/dialogs/choose-organisation/dialog";
import { DialogActivityComponent } from "../components/dialogs/dialog-activity/dialog-activity";
import { DialogAffectedServicesComponent } from "../components/dialogs/dialog-affected-services/dialog-affected-services";
import { DialogAuthComponent } from "../components/dialogs/dialog-auth/dialog-auth";
import { DialogCertComponent } from "../components/dialogs/dialog-cert/dialog-cert";
import { DialogEditComponent } from "../components/dialogs/dialog-edit/dialog-edit";
import { DialogFirewallComponent } from "../components/dialogs/dialog-firewall/dialog-firewall";
import { DialogFulfilmentComponent } from "../components/dialogs/dialog-fulfilment/dialog-fulfilment";
import { DialogIncidentComponent } from "../components/dialogs/dialog-incident/dialog-incident";
import { DialogIPPrefixesAddComponent } from "../components/dialogs/dialog-ip-prefixes-add/dialog-ip-prefixes-add";
import { DialogIPPrefixesRequestComponent } from "../components/dialogs/dialog-ip-prefixes-request/dialog-ip-prefixes-request";
import { DialogIPPrefixesComponent } from "../components/dialogs/dialog-ip-prefixes/dialog-ip-prefixes";
import { DialogNotificationSettingsComponent } from "../components/dialogs/dialog-notification-settings/dialog-notification-settings";
import { DialogOnboardingComponent } from "../components/dialogs/dialog-onboarding/dialog-onboarding";
import { DialogOrderComponent } from "../components/dialogs/dialog-order/dialog-order";
import { DialogSelfserviceComponent } from "../components/dialogs/dialog-selfservice/dialog-selfservice";
import { DialogSendConfigComponent } from "../components/dialogs/dialog-send-config/dialog-send-config";
import { DialogSlsComponent } from "../components/dialogs/dialog-sls/dialog-sls";
import { DialogTicketsComponent } from "../components/dialogs/dialog-tickets/dialog-tickets";
import { EditLightpathDirective } from "../components/dialogs/dialog/type/editLightpath";
import { EditLightpathOptionsDirective } from "../components/dialogs/dialog/type/editLightpathOptions";

// Dashboard components
import { ActivityContainer } from "../components/dashboard/activity-container/activity-container";
import { NotificationContainer } from "../components/dashboard/notification-container/notification-container";
import { ServiceTileAvailableComponent } from "../components/dashboard/service-tile-available/service-tile-available";
import { ServiceTileAvailableDirective } from "../components/dashboard/service-tile-available/service-tile-available-directive";
import { ServiceTileComponent } from "../components/dashboard/service-tile/service-tile";
import { ServiceTileDirective } from "../components/dashboard/service-tile/service-tile-directive";

// Malfunction components
import { MalfunctionCardComponent } from "../components/malfunction/malfunction-card/malfunction-card";
import { MalfunctionFilterComponent } from "../components/malfunction/malfunction-filter/malfunction-filter";
import { TimelineFooterComponent } from "../components/malfunction/timeline-footer/timeline-footer";
import { WebarchiveCalendarComponent } from "../components/malfunction/webarchive-calendar/webarchive-calendar";
import { WebarchiveComponent } from "../components/malfunction/webarchive/webarchive";

// Selfservice components
import { SelfserviceAddPortComponent } from "../components/selfservice/selfservice-add-port/selfservice-add-port";
import { SelfserviceAuthenticationBoxComponent } from "../components/selfservice/selfservice-authentication-box/selfservice-authentication-box";
import { SelfserviceBfdComponent } from "../components/selfservice/selfservice-bfd/selfservice-bfd";
import { SelfserviceBgpComponent } from "../components/selfservice/selfservice-bgp/selfservice-bgp";
import { SelfserviceDdosComponent } from "../components/selfservice/selfservice-ddos/selfservice-ddos";
import { SelfserviceIPPrefixesComponent } from "../components/selfservice/selfservice-ip-prefixes/selfservice-ip-prefixes";
import { SelfserviceLoaderComponent } from "../components/selfservice/selfservice-loader/selfservice-loader";
import { SelfserviceRemovePortComponent } from "../components/selfservice/selfservice-remove-port/selfservice-remove-port";
import { SelfserviceRpsComponent } from "../components/selfservice/selfservice-rps/selfservice-rps";
import { SelfserviceSpComponent } from "../components/selfservice/selfservice-sp/selfservice-sp";
import { SelfserviceSuccessComponent } from "../components/selfservice/selfservice-success/selfservice-success";
import { SelfserviceUnavailableComponent } from "../components/selfservice/selfservice-unavailable/selfservice-unavailable";
import { SelfserviceVlanL2vpnComponent } from "../components/selfservice/selfservice-vlan-l2vpn/selfservice-vlan-l2vpn";
import { SelfserviceVlanLightpathComponent } from "../components/selfservice/selfservice-vlan-lightpath/selfservice-vlan-lightpath";

// Common components
import { FilterBarComponent } from "../components/common/filter-bar/filter-bar";
import { ScrollEventModule } from "../components/common/ngx-scroll-event/scroll.module";

// Subscriptions (list) components
import { ChecktagComponent } from "../components/subscriptions-list/checktag/checktag";
import { KpiRowComponent } from "../components/subscriptions-list/kpi-row/kpi-row";
import { ServiceElementSimple } from "../components/subscriptions-list/service-element-simple/service-element-simple";
import { ServiceElementSimple as ServiceElementSimpleReplicated } from "../components/subscriptions-list/service-element-simple-replicated/service-element-simple";
import { ServiceElement } from "../components/subscriptions-list/service-element/service-element";
import { ServiceStatusComponent } from "../components/subscriptions-list/service-status/service-status";

// Subscription detail components
import { AccessPointsComponent } from "../components/subscription-detail/access-points/access-points";
import { ConnectionComponent } from "../components/subscription-detail/connection/connection";
import { EmptyStateComponent } from "../components/subscription-detail/empty-state/empty-state";
import { FirewallRulesComponent } from "../components/subscription-detail/firewall-rules/firewall-rules";
import { KpiTableComponent } from "../components/subscription-detail/kpi-table/kpi-table";
import { LinechartComponent } from "../components/subscription-detail/linechart/linechart";
import { NetworkgraphComponent } from "../components/subscription-detail/networkgraph/networkgraph";
import { NotificationButtonComponent } from "../components/subscription-detail/notification-button/notification-button";
import { PortTile } from "../components/subscription-detail/port-tile/port-tile";
import { PortVisualiser } from "../components/subscription-detail/port-visualiser/port-visualiser";
import { ServiceSummary } from "../components/subscription-detail/service-summary/service-summary";
import { ShortcutMenu } from "../components/subscription-detail/shortcut-menu/shortcut-menu";
import { SubscriptionDetailContainer } from "../components/subscription-detail/subscription-detail-container/subscription-detail-container";
import { SystemStatsComponent } from "../components/subscription-detail/system-stats/system-stats";
import { TabbedDetailContainer } from "../components/subscription-detail/tabbed-detail-container/tabbed-detail-container";
import { TerminatedCardComponent } from "../components/subscription-detail/terminated-card/terminated-card";
import { UserStatsComponent } from "../components/subscription-detail/user-stats/user-stats";
import { PageComponent } from "../components/page/page";
import { SLAReport } from "../components/subscription-detail/sla-report/sla-report";

// Pages
import { HighchartsChartModule } from "highcharts-angular";
import { AboutPage } from "../pages/about/about";
import { AvailabilityExportPage } from "../pages/availability-export/availability-export";
import { DashboardPage } from "../pages/dashboard/dashboard";
import { DisclaimerPage } from "../pages/disclaimer/disclaimer";
import { ErrorPage } from "../pages/error/error";
import { IPPrefixesPage } from "../pages/ip-prefixes/ip-prefixes";
import { IPPrefixesPageReplicated } from "../pages/ip-prefixes-replicated/ip-prefixes";
import { LoginPage } from "../pages/login/login";
import { MalfunctionPage } from "../pages/malfunction/malfunction";
import { MessagecenterPage } from "../pages/messagecenter/messagecenter";
import { NotfoundPage } from "../pages/notfound/notfound";
import { NotificationSettingsPage } from "../pages/notification-settings/notification-settings";
import { SubscriptionDetailPage } from "../pages/subscription-detail/subscription-detail";
import { SubscriptionDetailPage as SubscriptionDetailPageReplicated } from "../pages/subscription-detail-replicated/subscription-detail";
import { SubscriptionsPage } from "../pages/subscriptions/subscriptions";
import { SubscriptionsPage as SubscriptionsReplicatedPage } from "../pages/subscriptions-replicated/subscriptions";
import { SupportPage } from "../pages/support/support";
import { AppRoutingModule } from "./app-routing.module";

import { ApiHelper } from "../helpers/apihelper";
import { AuthService } from "../services/AuthService";
import { MessageService } from "../services/messageservice";
import { SubscriptionService } from "../services/subscriptionservice";

import { ReadableSizePipe, ReadableVolumePipe } from "../helpers/bitspipe/bitspipe";
import { TwentyFourHourTimePipe } from "../helpers/datehelper/24htime";
import { MonthNamePipe } from "../helpers/datehelper/monthname";
import { EnabledPipe } from "../helpers/enabledpipe/enabledpipe";
import { GuidPipe } from "../helpers/guidpipe/guidpipe";
import { KeysPipe } from "../helpers/keyspipe/keyspipe";
import { AsInstancePipe } from "../helpers/instance/to-instance.pipe";
import { SlsEventDescriptionPipe } from "../helpers/self-service/pipes/eventdescription";
import { StateToCamelcase } from "../helpers/statetocamelcase/statetocamelcase";

import { MatomoModule } from "ngx-matomo";
import { NWDHttpInterceptor } from "../helpers/interceptor/http.interceptor";
import { ForbiddenPage } from "../pages/forbidden/forbidden";

import { FlatpickrModule } from "angularx-flatpickr";
import { SelfserviceStatusService } from "../helpers/selfservicestatus.service";
import { WebsocketService } from "../helpers/websocket.service";

import { DragDropModule } from "@angular/cdk/drag-drop";
import { DialogMessageComponent } from "../components/dialogs/dialog-message/dialog-message";
import { DialogGenericComponent } from "../components/dialogs/dialog-generic/dialog-generic";
import { DialogRolesComponent } from "../components/dialogs/dialog-roles/dialog-roles";
import { UplinkDetailContainer } from "../components/subscription-detail/uplink-detail-container/uplink-detail-container";
import { CustomDescriptionIngestorPipe, CustomDescriptionPipe } from "../helpers/descriptionpipe/customdescription";

import { ApolloModule } from "apollo-angular";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { CertChartComponent } from "../components/cert/cert-chart/cert-chart";
import { CertDdosLinechartComponent } from "../components/cert/cert-ddos-chart/cert-ddos-chart";
import { CertFilterComponent } from "../components/cert/cert-filter/cert-filter";
import { CertHeaderComponent } from "../components/cert/cert-header/cert-header";
import { CertMitigationComponent } from "../components/cert/cert-mitigation/cert-mitigation";
import { CertSecurityComponent } from "../components/cert/cert-security/cert-security";
import { CertTableComponent } from "../components/cert/cert-table/cert-table";
import { CertTabsComponent } from "../components/cert/cert-tabs/cert-tabs";
import { FooterComponent } from "../components/common/footer/footer";
import { TagsInputModule } from "../components/common/ngx-tags-input/tags-input.module";
import { SwitchComponent } from "../components/common/switch/switch";
import { SelfserviceDdosAutoMitigationComponent } from "../components/selfservice/selfservice-ddos-auto-mitigation/selfservice-ddos-auto-mitigation";
import { CertPage } from "../pages/cert/cert";
import { CacheService } from "../services/cacheservice";
import { NwdSharedModule } from "./nwd-shared/nwd-shared.module";
import { ApiModule as DomainsApiModule } from "./domains-api/api.module";
import { EffectsModule } from "@ngrx/effects";

// added for the refactor to use replicated data
import { PortSubscriptionComponent } from "../pages/subscription-detail-replicated/components/port-subscription/port-subscription";
import { MscSubscriptionComponent } from "../pages/subscription-detail-replicated/components/msc-subscription/msc-subscription";
import { PortVisualiserReplicated } from "../components/subscription-detail-replicated/port-visualiser/port-visualiser";
import { SubscriptionDetailContainerReplicated } from "../components/subscription-detail-replicated/subscription-detail-container/subscription-detail-container";
import { AggspSubscriptionComponent } from "../pages/subscription-detail-replicated/components/aggsp-subscription/aggsp-subscription";
import { IrbSubscriptionComponent } from "../pages/subscription-detail-replicated/components/irb-subscription/irb-subscription";
import { ServiceSummaryReplicated } from "../components/subscription-detail-replicated/service-summary/service-summary";
import { FirewallSubscriptionComponent } from "../pages/subscription-detail-replicated/components/firewall-subscription/firewall-subscription";
import { L2vpnSubscriptionComponent } from "../pages/subscription-detail-replicated/components/l2vpn-subscription/l2vpn-subscription";
import { L3vpnSubscriptionComponent } from "../pages/subscription-detail-replicated/components/l3vpn-subscription/l3vpn-subscription";
import { WirelessSubscriptionComponent } from "../pages/subscription-detail-replicated/components/wireless-subscription/wireless-subscription";
import { IpBgpSubscriptionComponent } from "../pages/subscription-detail-replicated/components/ip-subscription/ip-bgp-subscription";
import { IpStaticSubscriptionComponent } from "../pages/subscription-detail-replicated/components/ip-subscription/ip-static-subscription";
import { AccessPointsComponent as AccessPointsComponentReplicated } from "../components/subscription-detail-replicated/access-points/access-points";
import { TabbedDetailContainerReplicated } from "../components/subscription-detail-replicated/tabbed-detail-container/tabbed-detail-container";
import { ThirdPartyServiceIdsRowContentComponent } from "../components/subscription-detail-replicated/third-party-service-ids-row-content/third-party-service-ids-row-content";
import { NotificationButtonReplicated } from "../components/subscription-detail-replicated/notification-button/notification-button";

// add non-default functionality to HighCharts here.
// the modules are already available, they just
// need loading and extending HighCharts
import * as Highcharts from "highcharts";
import HC_nodata from "highcharts/modules/no-data-to-display";
import HC_export from "highcharts/modules/exporting";
import HC_exportdata from "highcharts/modules/export-data";
import HC_networkgraph from "highcharts/modules/networkgraph";
import { ShortcutMenuReplicated } from "../components/subscription-detail-replicated/shortcut-menu/shortcut-menu";
import { LinechartReplicatedComponent } from "../components/subscription-detail-replicated/linechart/linechart";
import { PortTileReplication } from "../components/subscription-detail-replicated/port-tile/port-tile";
import { LightpathSubscriptionComponent } from "../pages/subscription-detail-replicated/components/lightpath-subscription/lightpath-subscription";
import { PageTabReplicationComponent } from "../components/subscription-detail-replicated/page-tab/page-tab";
import { ConnectionReplicationComponent } from "../components/subscription-detail-replicated/connection/connection";
import { NetworkgraphReplicationComponent } from "../components/subscription-detail-replicated/networkgraph/networkgraph";
import { LightpathRedundantSubscriptionComponent } from "../pages/subscription-detail-replicated/components/lightpath-subscription/lightpath-redundant-subscription";
import { ServiceTileReplicationComponent } from "../components/dashboard-replicated/service-tile/service-tile";
import { DashboardPageReplicated } from "../pages/dashboard-replicated/dashboard";
import { ActivityContainerReplication } from "../components/dashboard-replicated/activity-container/activity-container";
import { NotificationContainerReplication } from "../components/dashboard-replicated/notification-container/notification-container";

HC_nodata(Highcharts);
HC_export(Highcharts);
HC_exportdata(Highcharts);
HC_networkgraph(Highcharts);

Highcharts.setOptions({
  time: {
    timezone: "Europe/Amsterdam",
    useUTC: false,
  },
  lang: {
    noData: "Geen data beschikbaar",
  },
});

// AoT requires an exported function for factories
export const HttpLoaderFactory = (http: HttpClient) => new TranslateHttpLoader(http, "./assets/i18n/", ".json");
export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, "./assets/i18n/", ".json");

@NgModule({
  declarations: [
    AppComponent,
    ServiceTileComponent,
    ServiceStatusComponent,
    ServiceTileAvailableComponent,
    FilterBarComponent,
    ChecktagComponent,
    SwitchComponent,
    FooterComponent,
    LinechartComponent,
    NetworkgraphComponent,
    EmptyStateComponent,
    DashboardPage,
    DialogAffectedServicesComponent,
    DialogIPPrefixesComponent,
    DialogIPPrefixesRequestComponent,
    DialogIPPrefixesAddComponent,
    SubscriptionsPage,
    SubscriptionsReplicatedPage,
    SubscriptionDetailPage,
    SupportPage,
    MessagecenterPage,
    AboutPage,
    DisclaimerPage,
    IPPrefixesPage,
    IPPrefixesPageReplicated,
    LoginPage,
    MalfunctionPage,
    ErrorPage,
    NotfoundPage,
    ForbiddenPage,
    CertPage,
    AvailabilityExportPage,
    NotificationSettingsPage,
    SubscriptionDetailPageReplicated,
    SafePipe,
    KeysPipe,
    GuidPipe,
    StateToCamelcase,
    ReadableSizePipe,
    ReadableVolumePipe,
    MonthNamePipe,
    TwentyFourHourTimePipe,
    EnabledPipe,
    SlsEventDescriptionPipe,
    CustomDescriptionPipe,
    DialogTicketsComponent,
    DialogEditComponent,
    DialogOrderComponent,
    DialogFirewallComponent,
    DialogMessageComponent,
    DialogGenericComponent,
    DialogRolesComponent,
    DialogAuthComponent,
    DialogSelfserviceComponent,
    DialogOnboardingComponent,
    DialogFulfilmentComponent,
    DialogActivityComponent,
    DialogAffectedServicesComponent,
    DialogIPPrefixesComponent,
    DialogSendConfigComponent,
    DialogCertComponent,
    DialogIncidentComponent,
    DialogNotificationSettingsComponent,
    ChooseOrganisationDialogComponent,
    ServiceSummary,
    ServiceElement,
    ServiceElementSimple,
    ServiceElementSimpleReplicated,
    ServiceTileDirective,
    ServiceTileAvailableDirective,
    EditLightpathOptionsDirective,
    SLAReport,
    TabbedDetailContainer,
    UplinkDetailContainer,
    ActivityContainer,
    ShortcutMenu,
    PortVisualiser,
    SubscriptionDetailContainer,
    NotificationContainer,
    PortTile,
    ConnectionComponent,
    DialogSlsComponent,
    MalfunctionCardComponent,
    MalfunctionFilterComponent,
    WebarchiveComponent,
    WebarchiveCalendarComponent,
    TimelineFooterComponent,
    FirewallRulesComponent,
    AccessPointsComponent,
    AccessPointsComponentReplicated,
    KpiTableComponent,
    NotificationButtonComponent,
    TerminatedCardComponent,
    UserStatsComponent,
    SelfserviceDdosComponent,
    SelfserviceDdosAutoMitigationComponent,
    SelfserviceRpsComponent,
    SelfserviceSpComponent,
    SelfserviceBfdComponent,
    SelfserviceBgpComponent,
    SelfserviceIPPrefixesComponent,
    SelfserviceAddPortComponent,
    SelfserviceRemovePortComponent,
    SelfserviceVlanLightpathComponent,
    SelfserviceVlanL2vpnComponent,
    SelfserviceAuthenticationBoxComponent,
    SelfserviceLoaderComponent,
    SelfserviceSuccessComponent,
    SelfserviceUnavailableComponent,
    KpiRowComponent,
    CertChartComponent,
    CertFilterComponent,
    CertHeaderComponent,
    CertMitigationComponent,
    CertSecurityComponent,
    CertTableComponent,
    CertTabsComponent,
    SystemStatsComponent,
    CertDdosLinechartComponent,
    PortSubscriptionComponent,
    MscSubscriptionComponent,
    AggspSubscriptionComponent,
    WirelessSubscriptionComponent,
    FirewallSubscriptionComponent,
    IpBgpSubscriptionComponent,
    IpStaticSubscriptionComponent,
    PortVisualiserReplicated,
    SubscriptionDetailContainerReplicated,
    TabbedDetailContainerReplicated,
    CustomDescriptionIngestorPipe,
    ServiceSummaryReplicated,
    ShortcutMenuReplicated,
    LinechartReplicatedComponent,
    IrbSubscriptionComponent,
    PortTileReplication,
    LightpathSubscriptionComponent,
    LightpathRedundantSubscriptionComponent,
    PageTabReplicationComponent,
    ConnectionReplicationComponent,
    NetworkgraphReplicationComponent,
    ServiceTileReplicationComponent,
    DashboardPageReplicated,
    ActivityContainerReplication,
    NotificationContainerReplication,
    L2vpnSubscriptionComponent,
    L3vpnSubscriptionComponent,
    ThirdPartyServiceIdsRowContentComponent,
    PageComponent,
    NotificationButtonReplicated,
  ],
  imports: [
    HttpClientModule,
    NwdSharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AngularSvgIconModule.forRoot(),
    BrowserModule,
    AppRoutingModule,
    TruncateModule,
    ScrollEventModule,
    BrowserAnimationsModule,
    TagsInputModule.forRoot(),
    NgxMdModule.forRoot(),
    HighchartsChartModule,
    NgxSkeletonLoaderModule,
    FlatpickrModule.forRoot(),
    DragDropModule,
    NgMultiSelectDropDownModule.forRoot(),
    ApolloModule,
    MatomoModule.forRoot({
      scriptUrl: MatomoConfig.tracker_url,
      trackers: [
        {
          trackerUrl: MatomoConfig.url,
          siteId: MatomoConfig.site_id,
        },
      ],
      routeTracking: {
        enable: true,
      },
    }),
    DomainsApiModule.forRoot({
      rootUrl: "https://api.dev.automation.surf.net",
    }),
    StoreModule.forRoot({}),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
    }),
    EffectsModule.forRoot([]),
    AsInstancePipe,
  ],
  providers: [
    // { provide: ErrorHandler, useFactory: SurfExceptionHandlerProvider },
    // SurfExceptionHandlerProvider,
    AuthService,
    SubscriptionService,
    MessageService,
    CacheService,
    ApiHelper,
    WebsocketService,
    SelfserviceStatusService,
    {
      provide: APP_INITIALIZER,
      useFactory: (ds: AuthService) => () => ds.load(),
      deps: [AuthService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: NWDHttpInterceptor, multi: true },
    // graphqlProvider,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
