<div class="card activity-card">
  <!-- ERROR messages -->
  <div *ngIf="messages.length === 0">
    <div class="activity-card__header activity-card__unplanned" [class.success]="messages.length === 0">
      <svg-icon class="" src="assets/images/icons/warning.svg"></svg-icon>
      <h2>{{'Overview.Activities.Unplanned' | translate }}</h2>
    </div>
    <div class="activity-card__empty-state">
      <svg-icon class="activity-card__empty-state--icon" src="assets/images/icons/icon-rocket.svg"></svg-icon>
      <span class="activity-card__empty-state--text"> {{'Subscription.Message.NoError' | translate}} </span>
    </div>
  </div>
  <div *ngIf="messages.length > 0">
    <div class="activity-card__header activity-card__unplanned">
      <svg-icon class="" src="assets/images/icons/warning.svg"></svg-icon>
      <h2>{{'Overview.Activities.Unplanned' | translate }}</h2>
    </div>
    <ul>
      <li
        *ngFor="let message of messages | slice:0:3; let i=index; trackBy: trackByFn"
        class="activity-card__item"
        (click)="openDialog(message)">
        <span class="activity-card__message"> {{ message.heading }} </span>
        <span class="activity-card__time">
          {{ message.startTimestamp | date:'d MMM' }}, {{ message.startTimestamp | date:'HH:mm' }} {{ 'Global.Until' |
          translate }} {{ message.endTimestamp | date:'HH:mm'}}
        </span>
      </li>
    </ul>
    <div class="activity-card__footer">
      <a [routerLink]="['/storing-en-onderhoud']">
        <span>{{ 'Overview.RecentMessages.Follow' | translate }}</span>
      </a>
    </div>
  </div>
</div>
