import { gql } from "apollo-angular";

const LightPathRedundantTopologyFragment = gql`
  fragment LightPathRedundantTopology on SubscriptionInterface {
    ... on Sn8LightPathRedundantSubscription {
      customerId
      lrss {
        vcs {
          subscriptionInstanceId
          saps {
            subscriptionInstanceId
            vlanrange
            ownerSubscriptionId
            port {
              subscriptionId
              description
              customerDescriptions {
                customerId
                description
              }
            }
          }
        }
      }
    }
  }
`;

export { LightPathRedundantTopologyFragment };
