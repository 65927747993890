<div class="timeline-footer">
  <div *ngIf="showEmptyState" class="timeline-footer__center">
    <svg-icon class="timeline-footer__clock" src="assets/images/icons/icon-nothing-found.svg"></svg-icon>
    <div class="timeline-footer__title">{{ 'Malfunction.TimelineFooterTitle' | translate }}</div>
    <div class="timeline-footer__text">{{ 'Malfunction.TimelineFooterText' | translate }}</div>
  </div>
  <div *ngIf="!showEmptyState" class="timeline-footer__center">
    <button class="button button--secondary" *ngIf="hasMoreResults" (click)="loadMore()">{{ 'Malfunction.TimelineFooterLoadMore' | translate }}</button>
    <div *ngIf="!hasMoreResults" class="timeline-footer__title">-</div>
    <div class="timeline-footer__infoline">
      <svg-icon class="timeline-footer__infoline-icon" src="assets/images/icons/icon-info.svg"></svg-icon>
      <div>{{ 'Malfunction.TimelineFooterText' | translate }}</div>
    </div>
  </div>
</div>
