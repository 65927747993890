import { gql } from "apollo-angular";

const IpBgpTopologyFragment = gql`
  fragment IpBgpTopology on SubscriptionInterface {
    ... on Sn8IpBgpSubscription {
      subscriptionId
      customerId
      vc {
        subscriptionInstanceId
        saps {
          sap {
            subscriptionInstanceId
            vlanrange
            ownerSubscriptionId
            port {
              subscriptionId
              description
              customerDescriptions {
                customerId
                description
              }
            }
          }
        }
      }
    }
  }
`;

export { IpBgpTopologyFragment };
