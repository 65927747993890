<div *ngIf="!unavailable">
  <div class="port__items-container">
    <span
      class="port__items-bar service-{{ service.productType | lowercase }}"
      *ngFor="let service of serviceStats"
      [style.width.%]="service.percentage"
      matTooltip="{{ service.description }}"
      [matTooltipPosition]="'above'"
      (click)="clickBar(service)"
      [ngClass]="{'active': service.subscriptionId === highlightedSubscriptionId, 'disable-animation' : !barsWillRoute }">
    </span>
  </div>
  <div class="port__items-footer">
    <span class="port__items-footer port__items-footer-left">
      <span class="port__items-footer-left-notransform">{{ amountReserved * 1000000 | readable_size }}</span
      >&nbsp;{{'ServiceElement.Reserved' | translate }}
    </span>
    <span class="port__items-footer port__items-footer-right">
      <span>{{ bookingPercentage }}% {{'ServiceElement.BookingsPercentage' | translate }}</span>
    </span>
  </div>
</div>

<div *ngIf="unavailable" class="service__unavailable-bar">
  <span class="service__unavailable-bar-side">
    <svg-icon class="service__unavailable-bar-icon" src="assets/images/icons/lock.svg"></svg-icon>
  </span>
  <span class="service__unavailable-bar-text">
    <strong>{{ 'Global.NoAccess' | translate }}</strong> {{ 'Global.PleaseContact' | translate }} {{
    service?.organisation }}
  </span>
  <span class="service__unavailable-bar-side"></span>
</div>
