export const getFilteredQuery = (query, subscriptions) => {
  const filteredSubscriptions = subscriptions.filter((subscription) => {
    // make search case insensitive
    const searchQuery = query.toLowerCase();
    const name = subscription.description.toLowerCase();
    const subscriptionHasCustomName =
      subscription.customerDescriptions && subscription.customerDescriptions.hasOwnProperty(subscription.customerId);

    // Search on name
    if (name.includes(searchQuery)) {
      return true;
    }

    // Search on custom name
    if (subscriptionHasCustomName) {
      const customName = subscription.customerDescriptions[subscription.customerId].toLowerCase();

      if (customName.includes(searchQuery)) {
        return true;
      }
    }

    return false;
  });
  return filteredSubscriptions;
};
