/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { Status } from "../../models/status";

export interface DeleteTemplate$Params {
  organisation_id: string;
  template_id: number;
  token?: string | null;
  check?: boolean;
}

export function deleteTemplate(
  http: HttpClient,
  rootUrl: string,
  params: DeleteTemplate$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Status>> {
  const rb = new RequestBuilder(rootUrl, deleteTemplate.PATH, "delete");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.path("template_id", params.template_id, {});
    rb.query("token", params.token, {});
    rb.query("check", params.check, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Status>;
    }),
  );
}

deleteTemplate.PATH = "/surfdomeinen/organisations/{organisation_id}/templates/{template_id}";
