<navigation
  [activeItem]="1"
  [requestsBubble]="3"
  [detailData]="{title:pageTitle,status:'active'}"
  [terminated]="false"
></navigation>

<div class="container">
  <footer></footer>
</div>
