import { Component } from "@angular/core";
import { AuthService } from "../../../../services/AuthService";
import { ApiHelper } from "../../../../helpers/apihelper";
import { GenericSubscriptionComponent } from "../generic-subscription";
import {
  MscSubscriptionDetailsGQL,
  MscSubscriptionDetailsQuery,
  MscSubscriptionDetailsType,
} from "../../../../gql/generated-ingestor";
import { ActivatedRoute } from "@angular/router";
import { ApolloQueryResult } from "@apollo/client";

@Component({
  selector: "msc-subscription",
  templateUrl: "msc-subscription.html",
})
export class MscSubscriptionComponent extends GenericSubscriptionComponent<
  MscSubscriptionDetailsGQL,
  MscSubscriptionDetailsQuery,
  MscSubscriptionDetailsType
> {
  // possibly move these to a superclass for these components?
  public impactSetting = "Never";
  public linkPortSubscription = true; // customer can view the linked port subscription?
  public portSubscriptionName = "Port subscription name";

  constructor(
    public auth: AuthService,
    protected api: ApiHelper,
    protected route: ActivatedRoute,
    protected query: MscSubscriptionDetailsGQL,
  ) {
    super(api, route);
  }

  protected onQuerySuccess(result: ApolloQueryResult<MscSubscriptionDetailsQuery>): MscSubscriptionDetailsType {
    return result.data.mscDetails as MscSubscriptionDetailsType;
  }

  // ngOnInit() {
  //   this.messageBus.subscribe((message: string) => {
  //     if (message === "refresh") {
  //       this.querySubscription();
  //     }
  //   });
  //   this.querySubscription();
  // }

  // async querySubscription() {
  //   // query subscription details
  //   // @todo linkMemberSubscriptions need owner/location/portID/vlanID/customdescriptions
  //   this.portQuery
  //     .watch({
  //       subscriptionId: this.subscriptionId,
  //     })
  //     .valueChanges.subscribe((result) => {
  //       const subscriptionBase = result.data.mscDetails;
  //       this.subscription = subscriptionBase as MscSubscriptionDetailsType;
  //       // @todo request description/customerdescription from portSubscription as well
  //       this.portSubscriptionName =
  //         subscriptionBase.portSubscription?.customerDescription ?? subscriptionBase.portSubscription?.description;
  //       this.pageTitle = this.subscription.customerDescription ?? this.subscription.description;

  //       // check if the customer can view the linked port subscription
  //       this.linkPortSubscription = subscriptionBase.portSubscription?.customerId === StorageHelper.currentUser;
  //     });
  // }
}
