import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { ApiHelper } from "../../../helpers/apihelper";
import { SubscriptionService } from "../../../services/subscriptionservice";
import { LinkMemberDetailsType, ServiceType } from "../../../gql/generated-ingestor";
import { AddressType, Scalars } from "../../../gql/generated-ingestor";
import { StorageHelper } from "../../../helpers/storage/storagehelper";

export interface PortTileSap {
  organisation?: string;
  vlanrange: string;
  tag: string;
  port?: {
    tags: string[];
    subscriptionId: Scalars["ID"];
    portMode: string;
    customerDescription: string;
    description: string;
    portSpeed: number;
    services: ServiceType[];
    amountReserved?: number;
    address?: Partial<AddressType>;
  };
}

@Component({
  selector: "port-tile-replicated",
  templateUrl: "port-tile.html",
  styleUrls: ["../port-visualiser/port-visualiser.scss"],
})
export class PortTileReplication implements OnChanges {
  @Input() service: LinkMemberDetailsType;
  @Input() sap: PortTileSap; // based on ServiceAttachPointType
  @Input() serviceId: string;
  @Input() demoShowVisual = false;
  @Input() healthState = "";
  public showVisual = true;
  public portMode = "Link Member"; // @todo: logic for when this is used with a regular port (in connection.ts)
  public portOwner = "-";

  constructor(
    private api: ApiHelper,
    private subscriptionService: SubscriptionService,
  ) {}

  get serviceTag(): string {
    return this.portMode === "Link Member" ? (this.service as any).serviceTag : (this.sap as any).serviceTag;
  }

  get customerDescription(): string {
    return this.portMode === "Link Member" ?
        (this.service as any).customerDescription
      : this.sap.port.customerDescription;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes["service"]) {
      this.portMode = "Link Member";
      this.portOwner = this.service.organisation;
      this.showVisual = this.service.customerId === StorageHelper.currentUser;
    } else if (changes["sap"]) {
      this.portMode = this.sap.port.portMode;
      this.portOwner = this.sap.organisation;
      this.showVisual = true;
      if (this.sap.port.address === null) {
        this.sap.port.address = {
          street: "street",
          city: "city",
        };
      }
    }
  }
}
