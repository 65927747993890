/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { StrictHttpResponse } from "../../strict-http-response";
import { RequestBuilder } from "../../request-builder";

import { ContactRead } from "../../models/contact-read";
import { ContactType } from "../../models/contact-type";

export interface ReadContacts$Params {
  organisation_id: string;

  /**
   * The sort will accept parameters like `col` or `col:desc` and will split on the `:`. If it does not find a `:` it will sort ascending on that column. Sortable columns: id, organisation, type, name_organisation, name_first, name_prefix, name_last, address_street, address_number, address_suffix, address_city, address_postalcode, address_country, phone, email, is_active, full_name
   */
  sort?: Array<string> | null;
  contact_type?: ContactType | null;
  name_organisation?: string | null;
  name_first?: string | null;
  name_prefix?: string | null;
  name_last?: string | null;
  full_name?: string | null;
  email?: string | null;
  token?: string | null;
  offset?: number;
  limit?: number;
}

export function readContacts(
  http: HttpClient,
  rootUrl: string,
  params: ReadContacts$Params,
  context?: HttpContext,
): Observable<StrictHttpResponse<Array<ContactRead>>> {
  const rb = new RequestBuilder(rootUrl, readContacts.PATH, "get");
  if (params) {
    rb.path("organisation_id", params.organisation_id, {});
    rb.query("sort", params.sort, {});
    rb.query("contact_type", params.contact_type, {});
    rb.query("name_organisation", params.name_organisation, {});
    rb.query("name_first", params.name_first, {});
    rb.query("name_prefix", params.name_prefix, {});
    rb.query("name_last", params.name_last, {});
    rb.query("full_name", params.full_name, {});
    rb.query("email", params.email, {});
    rb.query("token", params.token, {});
    rb.query("offset", params.offset, {});
    rb.query("limit", params.limit, {});
  }

  // add this header so we are able to examine the failure response
  // otherwise it's intercepted by the http interceptor
  rb.header("x-ignore-interceptor", "1");

  return http.request(rb.build({ responseType: "json", accept: "application/json", context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ContactRead>>;
    }),
  );
}

readContacts.PATH = "/surfdomeinen/organisations/{organisation_id}/contacts/";
